import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Stack,
  Text,
  Center,
  Textarea,
  Progress,
} from '@chakra-ui/react';
import { getAuth } from 'firebase/auth';
import { updateDoc, doc } from 'firebase/firestore';
import { BsHouse } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';

import { db } from '../constants';
import DataContext from '../context/DataContext';
import AppBody from '../components/AppBody';
import Header from '../components/Header';
import sendEmailToAdmin from '../services/sendEmailToAdmin';
import checkMark from '../assets/animat-checkmark.gif';

function VerifyFinal() {
  const { t } = useTranslation('global');
  const dataContext = useContext(DataContext);
  const auth = getAuth();
  const navigate = useNavigate();

  const userRef = doc(db, 'users', auth.currentUser.uid);

  const {
    firstName,
    lastName,
    stageOneVerified,
    // stageTwoVerified,
    stageTwoWarnings,
    stageTwoWarningsComments,
  } = dataContext.userData;

  const [formData, setFormData] = useState({
    stageTwoWarningsComments: stageTwoWarningsComments || '',
  });

  const onChange = e => {
    setFormData(prevState => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const sendErrorComments = e => {
    // pull id from button
    const comments = formData[e.target.id];
    updateDoc(userRef, {
      [e.target.id]: comments,
    });

    // send notice to admin
    sendEmailToAdmin(
      'verificationError',
      `${firstName} ${lastName} - ${dataContext.userData?.uid}`
    );
  };

  // waiting on this page for server to update stageOneVerified or stageTwoWarnings
  return (
    <>
      <Header />
      <AppBody>
        <Heading size="md" mb={3}>
          {t('finalVerifications')}
        </Heading>

        <Flex justify={'center'}>
          <Stack p={2} spacing={2} w="100%">
            {!stageOneVerified && !stageTwoWarnings && (
              <div>
                <Heading size="md">
                  <Center mt={20} textAlign="center">
                    {t('yourInformationIsBeingProcessed')}
                  </Center>
                </Heading>
                <Center m={10}>
                  <Text>{t('pleaseWait')}</Text>
                </Center>
                <Progress size="md" isIndeterminate colorScheme="primary" />
                <Center mt={'40%'}>
                  <Text fontSize="sm">{t('ifLoadingMoreThanOneMinute')}</Text>
                </Center>

                <Button
                  w="full"
                  variant="link"
                  colorScheme="blue"
                  onClick={() => navigate(-1)}
                >
                  <Text fontSize="sm">{t('clickHereToGoBackAndResubmit')}</Text>
                </Button>
              </div>
            )}

            {stageOneVerified && (
              <>
                <Box>
                  <Center>
                    <Image
                      src={checkMark}
                      alt="Check"
                      boxSize="25%"
                      maxWidth="200px"
                      objectFit="cover"
                    />
                  </Center>
                </Box>
                <Alert
                  status="success"
                  bgColor="white"
                  borderWidth="1"
                  pt={0}
                  borderColor="primary.500"
                  variant="subtle"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  height="300px"
                >
                  <AlertTitle mb={1} fontSize="lg">
                    {t('congratulations')}
                  </AlertTitle>
                  <AlertDescription maxWidth="sm">
                    <Text>
                    {t('youvePassedThePreliminaryEEZEChecks')}
                    </Text>
                    <Text my={5}>
                    {t('EEZEWillPerformAFinalVerification')}
                    </Text>
                    <Text>
                    {t('inTheMeantimeContinueToUseEEZE')}
                    </Text>
                  </AlertDescription>
                </Alert>
                <Button
                  onClick={() => navigate('/')}
                  bgColor="primary.500"
                  color="white"
                  size="lg"
                  m="5"
                  leftIcon={<BsHouse />}
                >
                  {t('dashboard')}
                </Button>
              </>
            )}
            {stageTwoWarnings && (
              <>
                <Alert
                  status="error"
                  variant="subtle"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  height="200px"
                >
                  <AlertIcon boxSize="40px" />
                  <AlertTitle mt={4} mb={1} fontSize="lg">
                    {t('verificationErrors')}
                  </AlertTitle>
                  <AlertDescription maxWidth="sm">
                    {stageTwoWarnings?.map((warning, i) => (
                      <Text key={i}>{warning}</Text>
                    ))}
                  </AlertDescription>
                </Alert>
                <FormControl mt="2%">
                  <FormLabel m={0}>
                    {t('sendUsYourCommentsAndWellContactYouToResolve')}
                  </FormLabel>
                  <Textarea
                    placeholder={t('addComments')}
                    rows={3}
                    id="stageTwoWarningsComments"
                    value={formData.stageTwoWarningsComments}
                    onChange={onChange}
                    disabled={stageTwoWarningsComments}
                  />
                </FormControl>
                {!stageTwoWarningsComments ? (
                  <Button
                    bgColor="black"
                    color="white"
                    id="stageTwoWarningsComments"
                    onClick={sendErrorComments}
                  >
                    {t('sendComments')}
                  </Button>
                ) : (
                  <Center>
                    <Text as="i" color="red">
                      {t('thankYouCommentsReceivedWeAreInvestigating')}
                    </Text>
                  </Center>
                )}
              </>
            )}
          </Stack>
        </Flex>
      </AppBody>
    </>
  );
}

export default VerifyFinal;
