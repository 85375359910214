import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import {
  AlertIcon,
  Alert,
  Avatar,
  Button,
  Box,
  Center,
  Divider,
  InputGroup,
  InputLeftElement,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import { doc, updateDoc } from 'firebase/firestore';

// import currencyFormat from '../services/currencyFormat';
import { db, LISTING } from '../constants';
import ContinueButton from '../components/ContinueButton';

function TransactionAccepted({
  buyerUserData,
  sellerUserData,
  userData,
  listing,
  isBuyer,
  isSeller,
}) {
  const navigate = useNavigate();

  // const [payable, setPayable] = useState(0);
  const [finalPrice, setFinalPrice] = useState(
    listing.finalPrice ? listing.finalPrice : listing.askingPrice
  );
  const [odometer, setOdometer] = useState(listing.odometer);
  const thisListingRef = doc(db, 'listings/', listing.uid);

  // const onClickNextStatus = async () => {
  //   await updateDoc(thisListingRef, {
  //     status: LISTING.STATUS.SELECT_OPTIONS,
  //   });
  // };
  // const onClickCancel = async () => {
  //   if (!window.confirm('Cancel this transaction, are you sure?')) {
  //     return;
  //   }
  //   await updateDoc(thisListingRef, {
  //     status: LISTING.STATUS.CANCELLED,
  //   });
  // };

  const onChangeFinalPrice = e => {
    setFinalPrice(e.target.value);
    updateDoc(thisListingRef, {
      finalPrice: e.target.value,
    });
  };
  const onBlurFinalPrice = () => {
    // because both parties need to reconfirm
    updateDoc(thisListingRef, {
      finalPrice,
      buyerAccepted: false,
      buyerAcceptedAt: '',
      buyerName: '',
      sellerAccepted: false,
      sellerAcceptedAt: '',
    });
  };

  const onChangeOdometer = e => {
    setOdometer(e.target.value);
    updateDoc(thisListingRef, {
      odometer: e.target.value,
    });
  };

  const onBlurOdometer = () => {
    // setFinalPrice(e.target.value);
    updateDoc(thisListingRef, {
      odometer,
      buyerAccepted: false,
      buyerAcceptedAt: '',
      buyerName: '',
      sellerAccepted: false,
      sellerAcceptedAt: '',
    });
  };

  const onClickNextStatus = () => {
    // buyer decides on selectedOptions
    updateDoc(thisListingRef, {
      status: LISTING.STATUS.SELECT_OPTIONS,
    });
  };

  const onClickBuyerAccept = () => {
    updateDoc(thisListingRef, {
      buyerAccepted: true,
      buyerAcceptedAt: new Date().toString(),
      buyerName: buyerUserData.firstName + ' ' + buyerUserData.lastName,
    });
  };
  const onClickBuyerCancelTransaction = () => {
    if (!window.confirm('Cancel this transaction, are you sure?')) {
      return;
    }

    // add message to chat that buyer has cancelled the transaction
    let messagesArray = listing.potentialBuyers[listing.buyerUid].messages;
    messagesArray.push({
      fromBuyer: true,
      date: new Date().toString(),
      message: 'BUYER HAS CANCELLED THE TRANSACTION',
      offer: null,
    });

    updateDoc(thisListingRef, {
      buyerAccepted: false,
      buyerAcceptedAt: '',
      sellerAccepted: false,
      sellerAcceptedAt: '',
      buyerUid: '',
      status: LISTING.STATUS.POSTED,
      potentialBuyers: {
        ...listing.potentialBuyers,
        [listing.buyerUid]: {
          ...listing.potentialBuyers[listing.buyerUid],
          isFinalBuyer: false,
          lastPrice: '',
          messages: messagesArray,
        },
      },
    });
    // back to homepage
    navigate('/');
  };
  const onClickSellerCancelTransaction = () => {
    if (
      !window.confirm(
        'Cancel this transaction with this potential buyer, are you sure?'
      )
    ) {
      return;
    }

    // add message to chat that buyer has cancelled the transaction
    let messagesArray = listing.potentialBuyers[listing.buyerUid].messages;
    messagesArray.push({
      fromBuyer: false,
      date: new Date().toString(),
      message: 'SELLER HAS CANCELLED THE TRANSACTION',
      offer: null,
    });

    updateDoc(thisListingRef, {
      buyerAccepted: false,
      buyerAcceptedAt: '',
      sellerAccepted: false,
      sellerAcceptedAt: '',
      buyerUid: '',
      status: LISTING.STATUS.POSTED,
      potentialBuyers: {
        ...listing.potentialBuyers,
        [listing.buyerUid]: {
          ...listing.potentialBuyers[listing.buyerUid],
          isFinalBuyer: false,
          lastPrice: '',
          messages: messagesArray,
        },
      },
    });
    // back to homepage
    navigate('/');
  };

  const onClickSellerAccept = () => {
    updateDoc(thisListingRef, {
      sellerAccepted: true,
      sellerAcceptedAt: new Date().toString(),
    });
  };

  const renderBuyerAcceptButtons = () => {
    let sellerPart;

    if (!listing.sellerAccepted) {
      // if seller has not Accepted, show waiting for seller

      sellerPart = (
        <>
          <Center textAlign="center">
            <Text fontSize="xl">Waiting on Seller to Confirm</Text>
          </Center>
          {/* <Progress size="xs" isIndeterminate /> */}
        </>
      );
    } else {
      // seller has accepted
      sellerPart = (
        <Alert
          status="success"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          bg="white"
        >
          <AlertIcon boxSize="6" color="primary.800" />
          <Center>
            <Text as="i" color="primary.800" textAlign="center">
              Seller has Confirmed and Accepted:{' '}
            </Text>
          </Center>

          <Center>
            <Text as="i" textAlign="center" color="primary.800">
              {moment(
                listing.sellerAcceptedAt,
                'ddd MMM DD YYYY kk:mm:ss Z ZZ'
              ).format('MMM D, YYYY - h:mma')}
            </Text>
          </Center>
        </Alert>
      );
    }

    let buyerPart;
    if (!listing.buyerAccepted) {
      // if buyer (you) have not accepted, show button
      // to update buyerAccepted and buyerAcceptedAt
      buyerPart = (
        <>
          <Center textAlign="center" px={2}>
            <Text>
              Do you, {buyerUserData.firstName} {buyerUserData.lastName},
              confirm the above information is correct?
            </Text>
          </Center>
          <Button w='full' my={5} size="lg" colorScheme="primary" onClick={onClickBuyerAccept}>
            Yes, Confirm and Continue
          </Button>
        </>
      );
    } else {
      // once buyer (you) accepts, show buyerAcceptedAt date
      buyerPart = (
        <>
          <Alert
            status="success"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            bg="white"
          >
            <AlertIcon boxSize="6" color="primary.500" />
            <Center>
              <Text as="i" color="primary.500" textAlign="center">
                You've (Buyer) Confirmed the Information:{' '}
              </Text>
            </Center>

            <Center>
              <Text as="i" textAlign="center" color="primary.500">
                {moment(
                  listing.buyerAcceptedAt,
                  'ddd MMM DD YYYY kk:mm:ss Z ZZ'
                ).format('MMM D, YYYY - h:mma')}
              </Text>
            </Center>
          </Alert>
        </>
      );
    }

    return (
      <>
        {buyerPart}
        <br />
        {sellerPart}
        <br />
        {listing.sellerAcceptedAt && listing.buyerAcceptedAt && (
          <ContinueButton onClick={onClickNextStatus}>CONTINUE</ContinueButton>
        )}
        <br />
        <br />
        <br />
        <Button
          variant="link"
          size="sm"
          w='full'
          mb={5}
          // colorScheme="red"
          onClick={onClickBuyerCancelTransaction}
        >
          Cancel Transaction
        </Button>
        <br/>
        <br/>
      </>
    );
  };

  const renderSellerAcceptButtons = () => {
    let buyerPart, sellerPart;

    if (!listing.buyerAccepted) {
      // if buyer has not Accepted, show waiting for buyer

      buyerPart = (
        <>
          <Center textAlign="center">
            <Text fontSize="xl">Waiting on Buyer to Confirm</Text>
          </Center>
          {/* <Progress size="xs" isIndeterminate /> */}
        </>
      );
    } else {
      // buyer has accepted
      buyerPart = (
        <Alert
          status="success"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          bg="white"
        >
          <AlertIcon boxSize="6" color="primary.800" />
          <Center>
            <Text as="i" color="primary.800" textAlign="center">
              Buyer has Confirmed and Accepted:{' '}
            </Text>
          </Center>

          <Center>
            <Text as="i" textAlign="center" color="primary.800">
              {moment(
                listing.buyerAcceptedAt,
                'ddd MMM DD YYYY kk:mm:ss Z ZZ'
              ).format('MMM D, YYYY - h:mma')}
            </Text>
          </Center>
        </Alert>
      );
    }

    if (!listing.sellerAccepted) {
      // if seller (you) have not accepted, show button
      // to update sellerAccepted and sellerAcceptedAt
      sellerPart = (
        <>
          <Center textAlign="center" px={2}>
            <Text>
              Do you, {sellerUserData.firstName} {sellerUserData.lastName},
              confirm the above information is correct?
            </Text>
          </Center>
          <Button
            disabled={!odometer || !finalPrice}
            size="lg"
            w='full' my={5} 
            colorScheme="primary"
            onClick={onClickSellerAccept}
          >
            Yes, Confirm and Continue
          </Button>
        </>
      );
    } else {
      // once seller (you) accepts, show sellerAcceptedAt date
      sellerPart = (
        <>
          <Alert
            status="success"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            bg="white"
          >
            <AlertIcon boxSize="6" color="primary.500" />
            <Center>
              <Text
                as="i"
                // color="blue"
                color="primary.500"
                textAlign="center"
              >
                You've (Seller) Confirmed the Information:{' '}
              </Text>
            </Center>

            <Center>
              <Text as="i" textAlign="center" color="primary.500">
                {moment(
                  listing.sellerAcceptedAt,
                  'ddd MMM DD YYYY kk:mm:ss Z ZZ'
                ).format('MMM D, YYYY - h:mma')}
              </Text>
            </Center>
          </Alert>
        </>
      );
    }

    return (
      <>
        {sellerPart}
        <br />
        {buyerPart}
        {/* <br /> */}
        {listing.sellerAcceptedAt && listing.buyerAcceptedAt && (
          <ContinueButton onClick={onClickNextStatus}>CONTINUE</ContinueButton>
        )}
        <br />
        <br />
        <br />
        <Button
          variant="link"
          size="sm"
          w='full'
          mb={5}
          // colorScheme="red"
          onClick={onClickSellerCancelTransaction}
        >
          Cancel Transaction
        </Button>
        <br/>
        <br/>
      </>
    );
  };

  return (
    <Box>
      {/* <Divider borderColor="primary.500" borderWidth={1} /> */}
      <Center my={3}>
        <Text color={'primary.800'} fontWeight={'800'}>
          TRANSACTION SUMMARY
        </Text>
      </Center>

      <Center>
        <Text as="b" fontWeight={'bold'} fontSize="lg">
          {listing.title}
        </Text>
      </Center>
      <Center my={2}>
        <Text>VIN: {listing.vin}</Text>
      </Center>


      <Stack
        // mt={'10'}
        direction="row"
        justify="space-between"
        align='center'
      >
        <Text
        >
          Odometer ({listing.odometerType}):
        </Text>

        <InputGroup mx={0} align="center" w="50%">
        <Input
            borderWidth={1}
            borderColor="primary.500"
            id="odometer"
            type="number"
            textAlign="right"
            value={listing.odometer}
            // onChange={e => setOdometer(e.target.value)}
            onChange={onChangeOdometer}
            onBlur={onBlurOdometer}
          />
          {/* <InputRightElement
            pointerEvents="none"
            children={listing.odometerType}
          /> */}
        </InputGroup>
      </Stack>

      <Divider borderColor="primary.500" borderWidth={1} my={4}/>

      <Stack direction="row" justify="space-between">
        <Box flex={1}>
          <Center>
            <Text color={'primary.800'} fontWeight={'500'}>
              SELLER{isSeller && ' (you)'}
            </Text>
          </Center>

          <Center>
            <Avatar src={sellerUserData.photoURL} size="lg" />
          </Center>
          <Center>
            <Text>
              {sellerUserData.firstName} {sellerUserData.lastName}
            </Text>
          </Center>
          <Center>
            <Text color={'primary.800'} fontWeight={'500'}>
              WILL RECEIVE
            </Text>
          </Center>
        </Box>

        <Box flex={1}>
          <Center>
            <Text color={'primary.800'} fontWeight={'500'}>
              BUYER{isBuyer && ' (you)'}
            </Text>
          </Center>
          <Center>
            <Avatar src={buyerUserData.photoURL} size="lg" />
          </Center>
          <Center>
            <Text>
              {buyerUserData.firstName} {buyerUserData.lastName}
            </Text>
          </Center>
          <Center>
            <Text color={'primary.800'} fontWeight={'500'}>
              WILL PAY
            </Text>
          </Center>
        </Box>
      </Stack>

      <Stack
        my={'10'}
        direction="row"
        justify="space-between"
        align='center'
      >
        <Text
          fontWeight={'500'}
        >
          FINAL PRICE* ({listing.currency}):
        </Text>

        <InputGroup mx={0} align="center" w="50%">
          <InputLeftElement pointerEvents="none" children={'$'} />
          <Input
            borderWidth={1}
            borderColor="primary.500"
            id="finalPrice"
            type="number"
            textAlign="right"
            value={listing.finalPrice}
            onChange={onChangeFinalPrice}
            onBlur={onBlurFinalPrice}
          />
        </InputGroup>
      </Stack>

      <Center>
      <Text>* Buyer must select and pay for the EEZE plan in the next step</Text>
      </Center>

      <Divider borderColor="primary.500" borderWidth={1} my={5}/>
      
      {!odometer && (
        <Center>
          <span style={{ color: 'red' }}>Odometer Required</span>
        </Center>
      )}
      {!finalPrice && (
        <Center>
          <span style={{ color: 'red' }}>Final Price Required</span>
        </Center>
      )}
      {isSeller && renderSellerAcceptButtons()}
      {isBuyer && renderBuyerAcceptButtons()}
      {1 === 2 && (
        <>
          {/* DEV not visible */}
          <br />
          <br />
          <br />
          <br />
          <Box p={3}
           color='lightgray'>
            *dev
            <Button
              mx={4}
              // colorScheme="blue"
              variant="link"
              color="lightgray"
              onClick={onClickNextStatus}
            >
              Next status
            </Button>
            {/* <Button variant="ghost" onClick={onClickCancel}>
              Cancel
            </Button> */}
          </Box>
        </>
      )}
    </Box>
  );
}

export default TransactionAccepted;
