import React from 'react';
import { Box, IconButton, useBreakpointValue } from '@chakra-ui/react';
// Here we have used react-icons package for the icons
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi';
import { ExternalLinkIcon } from '@chakra-ui/icons';
// And react-slick as our Carousel Lib
import Slider from 'react-slick';

// Settings for the slider
const settings = {
  dots: true,
  arrows: false,
  fade: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export default function ListingPageCarousel({ photos }) {
  // As we have used custom buttons, we need a reference variable to
  // change the state
  const [slider, setSlider] = React.useState(null);

  // These are the breakpoints which changes the position of the
  // buttons as the screen size changes
  const top = useBreakpointValue({ base: '90%', md: '50%' });
  const side = useBreakpointValue({ base: '30%', md: '10px' });

  return (
    <Box
      position={'relative'}
      // height={'600px'}
      // bg='black'
      height={'30vh'}
      width={'full'}
      overflow={'hidden'}
    >
      {/* CSS files for react-slick */}
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      {/* Left Icon */}
      {photos.length > 1 && (
        <IconButton
          aria-label="left-arrow"
          bgColor="primary.500"
          color="white"
          borderRadius="full"
          position="absolute"
          left={side}
          top={top} 
          transform={'translate(0%, -50%)'}
          zIndex={2}
          onClick={() => slider?.slickPrev()}
        >
          <BiLeftArrowAlt />
        </IconButton>
      )}
      {/* Right Icon */}
      {photos.length > 1 && (
        <IconButton
          aria-label="right-arrow"
          bgColor="primary.500"
          color="white"
          borderRadius="full"
          position="absolute"
          right={side}
          top={top}
          transform={'translate(0%, -50%)'}
          zIndex={2}
          onClick={() => slider?.slickNext()}
        >
          <BiRightArrowAlt />
        </IconButton>
      )}
      {/* Slider */}
      <Slider {...settings} ref={slider => setSlider(slider)}>
        {photos.map((url, index) => (
          <div key={index}>
            <IconButton
              position="absolute"
              right={0}
              top={5}
              zIndex={2}
              variant="outline"
              onClick={() => window.open(url, '_blank')}
            >
              <ExternalLinkIcon color='lightgrey'/>
            </IconButton>
            <Box
              key={index}
              height={'30vh'}
              position="relative"
              backgroundPosition="center"
              backgroundRepeat="no-repeat"
              backgroundSize="contain"
              backgroundImage={`url(${url})`}
            />
          </div>
        ))}
      </Slider>
    </Box>
  );
}
