import {
    getAuth,
} from 'firebase/auth';
import {
    SEND_EMAIL_PASSCODE,
} from '../constants';

const sendEmailPasscode = async (uid) => {
    const auth = getAuth();
    try {
        const token = await auth.currentUser.getIdToken()
        // also pass uid to add as ClientReferenceNumber for webhook
        const response = await fetch(
            SEND_EMAIL_PASSCODE+'?uid='+uid, {
            method: 'GET',
            withCredentials: true,
            credentials: 'include',

            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }
        );

        const data = await response.json();
        // console.log('data from sendEmailPasscode', data);
        return data
    }

    catch (error) {
        console.log(error)
        return error;
    }
}

export default sendEmailPasscode