import { Box, Container } from '@chakra-ui/react';

// header 60px
// footer 60px

export default function AppBody(props) {
  return (
    <Container p={2}>
      <Box
      minH={'calc(100vh - 120px)'}
      maxW='2xl'
      >

      {props.children}
      </Box>
    </Container>
  );
}
