import {
  Avatar,
  Button,
  Box,
  Heading,
  Image,
  Center,
  Divider,
  ListItem,
  UnorderedList,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { LINK } from '../constants';
import googleImage from '../assets/google_icon.png';
import currencyFormat from '../services/currencyFormat';
import checkMark from '../assets/animat-checkmark.gif';

function TransactionCompleted({
  buyerUserData,
  sellerUserData,
  userData,
  listing,
  isBuyer,
  isSeller,
}) {
  const navigate = useNavigate();

  const viewContract = () => {
    const url =
      listing.contractSignedPdfUrl ||
      listing.contractSignaturePageUrlSeller ||
      listing.contractSignaturePageUrlBuyer;
    window.open(url, '_blank');
  };

  let blogLink = LINK.buyCarOntarioUrl;
  if (listing?.province === 'qc') {
    blogLink = LINK.buyCarQuebecUrl;
  }

  const buyerMessage = () => {
    return (
      <>
        <Heading as="h1" fontSize="lg" my={2}>
          Next steps for your new vehicle:
        </Heading>
        <UnorderedList spacing={2} textAlign="start" px={2}>
          <ListItem>
            To officially transfer registration, there are{' '}
            <b>important administrative steps</b> required by both parties.{' '}
            {!(
              listing?.province === 'qc' || listing?.province === 'on'
            ) ? (
              <>
                Please consult local or provicial regulations
              </>
            ) : (
              <Text
              as='a'
              colorScheme="primary"
              textDecoration='underline'
              onClick={() => window.open(blogLink, '_blank')}
            >
              Click here to view a blog post on the subject
            </Text>
            )}
          </ListItem>
          {listing?.eezePlanIndex !== '0' && (
            <ListItem>
              Within 2 business days, we will activate your EEZE warranty plan.
              Within that time, you may use your Upgrade Credit towards a
              different plan
              <Button
                w="full"
                my={2}
                colorScheme="primary"
                onClick={() =>
                  navigate(
                    '/' + listing.type.toLowerCase() + '-addons/' + listing.uid
                  )
                }
              >
                View upgrade options
              </Button>
            </ListItem>
          )}
          {buyerUserData.walletBalance === 0 && (
            <ListItem>
              If you have a wallet balance,{' '}
              <a
                href={LINK.contactUrl}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: 'underline' }}
              >
                contact us
              </a>{' '}
              to withdraw to your source bank account for free anytime
            </ListItem>
          )}
          <ListItem>
            See your email for your{' '}
            <Text
              as='a'
              colorScheme="primary"
              textDecoration='underline'
              onClick={viewContract}
            >
              Sale Contract
            </Text>
            {listing?.eezePlanIndex !== '0'
              ? ' and information on the features of your EEZE plan.'
              : '.'}
          </ListItem>
        </UnorderedList>
        <Center mt={5}>
          <Text
            // pt={2}
            fontSize="lg"
            // lineHeight={1.2}
            fontWeight="bold"
            bgGradient="linear(to-l, primary.800,primary.300)"
            bgClip="text"
          >
            Thank you for transacting with EEZE!
          </Text>
        </Center>
        <Center mt={10}>
          <Button
            onClick={() => window.open(LINK.googleReview, '_blank')}
            variant="outline"
          >
            <Text>Please leave us a review</Text>
            <Image
              src={googleImage}
              alt="Logo"
              boxSize="40px"
              objectFit="cover"
            />
          </Button>
        </Center>
      </>
    );
  };
  const sellerMessage = () => {
    return (
      <>
        <Heading as="h1" fontSize="lg" my={2}>
          Next steps now that the vehicle is sold:
        </Heading>

        <UnorderedList spacing={2} textAlign="start" px={2}>
          {listing.eezePlanIndex !== '0' ? (
            <ListItem>
              <b>Check your email</b>, you should deposit the funds sent to you
              by INTERAC e-Transfer BEFORE you handover documents to the buyer
            </ListItem>
          ) : (
            <ListItem>
              You should <b>collect payment</b> from the buyer BEFORE you
              handover ownership documents
            </ListItem>
          )}
          <ListItem>
            To officially transfer registration, there are{' '}
            <b>important administrative steps</b> required by both parties.{' '}
            {!(
              listing?.province === 'qc' || listing?.province === 'on'
            ) ? (
              <>
                Please consult local or provincial regulations
              </>
            ) : (
              <Text
              as='a'
              colorScheme="primary"
              textDecoration='underline'
              onClick={() => window.open(blogLink, '_blank')}
            >
              Click here to view a blog post on the subject
            </Text>
            )}
          </ListItem>
          <ListItem>
            Your{' '}
            <Text
               as='a'
               colorScheme="primary"
               textDecoration='underline'
              onClick={viewContract}
            >
              Sale Contract
            </Text>{' '}
            has been emailed to you
          </ListItem>
        </UnorderedList>
        <Center mt={5}>
          <Text
            // pt={2}
            fontSize="lg"
            // lineHeight={1.2}
            fontWeight="bold"
            bgGradient="linear(to-l, primary.800,primary.300)"
            bgClip="text"
          >
            Thank you for transacting with EEZE!
          </Text>
        </Center>
        <Center mt={10}>
          <Button
            onClick={() => window.open(LINK.googleReview, '_blank')}
            variant="outline"
          >
            <Text>Please leave us a review</Text>
            <Image
              src={googleImage}
              alt="Logo"
              boxSize="40px"
              objectFit="cover"
            />
          </Button>
        </Center>
      </>
    );
  };

  return (
    <div>
      <Center>
        <Heading as="h1" fontSize="2xl" color="primary.500">
          Congratulations
        </Heading>
      </Center>
      <Center>
        <Heading as="h1" fontSize="2xl" color="primary.500" align="center">
          You have transacted with EEZE!
        </Heading>
      </Center>
      <Center mt={2}>
        <Image
          src={checkMark}
          alt="Check"
          p={0}
          boxSize="15%"
          maxWidth="200px"
          objectFit="cover"
        />
        <Heading as="h1" fontSize="lg">
          Vehicle sold on{' '}
          {moment(listing?.completedAt, 'ddd MMM DD YYYY kk:mm:ss Z ZZ').format(
            'MMM D, YYYY'
          )}
        </Heading>
      </Center>
      <Divider borderColor="primary.500" borderWidth={1} mb={4} />

      <Stack direction="row" justify="space-between">
        <Box flex={1}>
          <Center>
            <Text color={'primary.800'} fontWeight={'500'}>
              SELLER
            </Text>
          </Center>
          <Center>
            <Avatar src={sellerUserData.photoURL} size="lg" />
          </Center>
          <Center>
            <Text>
              {sellerUserData.firstName} {sellerUserData.lastName}
            </Text>
          </Center>
          <Center>
            <Text>{isSeller && '(you)'}</Text>
          </Center>
        </Box>

        <Box flex={1}>
          <Center>
            <Text color={'primary.800'} fontWeight={'500'}>
              BUYER
            </Text>
          </Center>
          <Center>
            <Avatar src={buyerUserData.photoURL} size="lg" />
          </Center>
          <Center>
            <Text>
              {buyerUserData.firstName} {buyerUserData.lastName}
            </Text>
          </Center>
          <Center>
            <Text>{isBuyer && '(you)'}</Text>
          </Center>
        </Box>
      </Stack>

      <Center>
        <Text as="b" fontWeight={'bold'} fontSize="lg">
          {listing.title}
        </Text>
      </Center>
      <Center>
        <Text>VIN: {listing.vin}</Text>
      </Center>
      {/* <Center>
        <Text mr={5}>
          Odometer: {odometerFormat(listing.odometer)} {listing.odometerType}
        </Text>
      </Center> */}

      <Center>
        <Text fontWeight={'500'}>
          Final Price: {listing.currency}{' '}
          {currencyFormat(Number(listing.finalPrice))}
        </Text>
      </Center>

      {isBuyer ? buyerMessage() : sellerMessage()}

      <Button
        w="full"
        bg="black"
        color="white"
        size="lg"
        mb={4}
        mt={'15%'}
        onClick={() => navigate('/')}
      >
        Back to Dashboard
      </Button>
    </div>
  );
}

export default TransactionCompleted;
