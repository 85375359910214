import { Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export default function OrDivider(props) {
  const { t } = useTranslation('global');
  return (
    <Flex
      align={'center'}
      py={2}
      my={props.my}
      _before={{
        content: '""',
        borderBottom: '1px solid',
        borderColor: 'secondary.100',
        flexGrow: 2,
        // mr: 8, 
        // pr: 4
      }}
      _after={{
        content: '""',
        borderBottom: '1px solid',
        borderColor: 'secondary.100',
        flexGrow: 2,
        // ml: 8,
        // pl: 4
      }}
    >
      <Text 
      // fontSize='md'
      textAlign='center'
      flex={1}
      >
        {props.text || t('or')}
        </Text>
    </Flex>
  );
}
