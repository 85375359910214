import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import moment from 'moment';
import {
  Box,
  Card,
  Center,
  Text,
  IconButton,
  Button,
  Image,
  Badge,
  Divider,
  ButtonGroup,
  Stack,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from '@chakra-ui/react';
import { ChatIcon, DeleteIcon } from '@chakra-ui/icons';
import { BsChevronRight, BsEye } from 'react-icons/bs';
import { AiOutlineDollarCircle } from 'react-icons/ai';
import { FaEllipsisV, FaRegPlusSquare } from 'react-icons/fa';
import { updateDoc, doc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

import currencyFormat from '../services/currencyFormat';
import { db, LISTING } from '../constants';
import DataContext from '../context/DataContext';
import ListingCardLoading from '../components/ListingCardLoading';
import { ReactComponent as Main } from '../assets/svg/main.svg';

function ListingCardBuyer({ listing, onEdit, onDelete }) {
  const { userData } = useContext(DataContext);
  const auth = getAuth();
  const navigate = useNavigate();

  const onClickRemoveListing = () => {
    if (!window.confirm('Remove from favorites, are you sure?')) {
      return;
    }
    removeFromFavorites();
  };

  const removeFromFavorites = () => {
    const current = userData.favoriteListings;

    const index = current.indexOf(listing.uid);
    if (index > -1) {
      // only splice array when item is found
      current.splice(index, 1); // 2nd parameter means remove one item only
    }

    // save the array to the db
    const userRef = doc(db, 'users', auth.currentUser.uid);

    updateDoc(userRef, {
      favoriteListings: current,
    });
  };

  const contactSeller = () => {
    if (!userData.stageOneVerified) {
      window.alert('You must update your Profile before contacting the Seller');
      return;
    }
    navigate('/connect/' + listing.uid + '/' + userData.uid);
  };
  const onClickCancelTransaction = () => {
    // only available if this buyer has been selected
    // warning are you sure
    if (!window.confirm('Cancel this transaction, are you sure?')) {
      return;
    }

    // remove uid from listing.buyerUid

    // listing status back to POSTED

    // remove options selected from listing

    // remove from potentialBuyers

    // remove from favorites
    removeFromFavorites();

    // notify seller
  };

  const toTransaction = () => {
    navigate('/transaction/' + listing.uid);
  };
  if (!listing) {
    return <ListingCardLoading />;
  }
  return (
    <>
      <Card
        p={0}
        my={2}
        mx={1}
        mt={0}
        bgColor={
          listing.status === LISTING.STATUS.COMPLETED ? '#E2E8F0' : 'white'
        }
        shadow="xl"
        borderWidth={1}
        borderColor="darkgrey"
      >
        <Flex justify="space-between" m={0} align="center">
          <Text
            pl={3}
            fontSize="lg"
            fontWeight="800"
            // textDecoration={listing.status !== LISTING.STATUS.COMPLETED ? 'underline' : ''}
            color={
              listing.status !== LISTING.STATUS.COMPLETED
                ? 'secondary.600'
                : 'black'
            }
            onClick={() => {
              if (listing.status !== LISTING.STATUS.COMPLETED) {
                navigate('/listing/' + listing.uid);
              }
            }}
          >
            {listing.title || ' UNTITLED'}
          </Text>

          <Box>
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<FaEllipsisV />}
                variant="ghost"
                _hover={{ bgColor: 'inherit' }}
                _active={{ bgColor: 'inherit' }}
              />
              <MenuList>
                {listing.eezeID && (
                  <Center>
                    <Text fontSize="sm">
                      EEZE ID: {listing.eezeID.toUpperCase()}
                    </Text>
                  </Center>
                )}
                <Center>
                  <Text as="i" color="gray" fontSize="sm">
                    {listing?.potentialBuyers && (
                      <>
                        {listing?.potentialBuyers[userData.uid]?.messages ? (
                          <>
                            Contacted:{' '}
                            {moment(
                              listing?.potentialBuyers[userData.uid]
                                ?.messages[0]?.date,
                              'ddd MMM DD YYYY kk:mm:ss Z ZZ'
                            ).format('MMM D, YYYY')}
                          </>
                        ) : (
                          <>
                            Posted:{' '}
                            {moment(
                              listing.postedAt,
                              'ddd MMM DD YYYY kk:mm:ss Z ZZ'
                            ).format('MMM D, YYYY')}
                          </>
                        )}
                      </>
                    )}
                  </Text>
                </Center>

                <MenuDivider />

                <MenuItem
                  icon={<FaRegPlusSquare />}
                  onClick={() =>
                    navigate(
                      '/' +
                        listing.type.toLowerCase() +
                        '-addons/' +
                        listing.uid
                    )
                  }
                >
                  View Add-ons
                </MenuItem>
                {/* )} */}
                <MenuItem
                  icon={<BsEye />}
                  onClick={() => navigate('/listing/' + listing.uid)}
                >
                  View Listing Page
                </MenuItem>
                <MenuItem icon={<ChatIcon />} onClick={contactSeller}>
                  Contact Seller
                </MenuItem>
                {listing.buyerUid === userData.uid ? (
                  <>
                    <MenuItem
                      onClick={toTransaction}
                      icon={<AiOutlineDollarCircle />}
                    >
                      To Transaction
                    </MenuItem>
                    {listing.status !== LISTING.STATUS.COMPLETED && (
                      <>
                        <MenuDivider />
                        <MenuItem
                          icon={<DeleteIcon />}
                          onClick={onClickCancelTransaction}
                        >
                          Cancel
                        </MenuItem>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <MenuDivider />
                    <MenuItem
                      icon={<DeleteIcon />}
                      onClick={onClickRemoveListing}
                    >
                      Remove From Favorites
                    </MenuItem>
                  </>
                )}
              </MenuList>
            </Menu>
          </Box>
        </Flex>

        <Stack direction="row">
          <Stack w="50%" pl={2} spacing={0} justify="center" align="center">
            <Box>
              <Badge
                variant="outline"
                colorScheme={
                  listing.status !== LISTING.STATUS.COMPLETED ? 'gray' : 'blue'
                }
              >
                {listing.status}
              </Badge>
            </Box>

            <Text fontSize="lg">
              {listing.finalPrice && listing.buyerUid === userData.uid
                ? currencyFormat(listing.finalPrice, 0)
                : listing.askingPrice
                ? currencyFormat(listing.askingPrice, 0)
                : ''}
            </Text>

            <Text fontSize="sm">Seller: {listing.sellerName}</Text>
          </Stack>

          <Box w="50%" align="center">
          {listing.listingProfilePhoto &&
            typeof listing.listingProfilePhoto === 'string' ? (
              <Image
                maxH={'90px'}
                objectFit="contain"
                alt="listingProfilePhoto"
                src={listing.listingProfilePhoto}
              />
            ) : (
              <Main width="80px" height="100%"/>
            )}
            {/* <Image
              maxH={'90px'}
              objectFit="contain"
              alt="listingProfilePhoto"
              src={
                listing.listingProfilePhoto &&
                typeof listing.listingProfilePhoto === 'string'
                  ? listing.listingProfilePhoto
                  : 'https://placehold.co/600x400?text=EEZE'
                // : 'https://via.placeholder.com/150?text=EEZE'
              }
            /> */}
          </Box>
        </Stack>

        {listing.status !== LISTING.STATUS.COMPLETED ? (
          <>
            <Divider mt={2} />
            <ButtonGroup w="100%">
              {/* EITHER BUYER HAS BEEN SELECTED OR NOT */}
              <Button
                w={listing.buyerUid === userData.uid ? '50%' : 'full'}
                m={2}
                leftIcon={<ChatIcon />}
                variant="ghost"
                color="secondary.700"
                onClick={contactSeller}
              >
                Contact Seller
              </Button>
              {listing.buyerUid === userData.uid && (
                <Button
                  // color="blue"
                  // borderColor="blue"
                  // variant="outline"
                  color="white"
                  bgColor="black"
                  // colorScheme="primary"
                  w="50%"
                  m={2}
                  onClick={toTransaction}
                  rightIcon={<BsChevronRight />}
                >
                  TRANSACT
                </Button>
              )}
            </ButtonGroup>
          </>
        ) : (
          <ButtonGroup w="100%">
            <Center mx={2} my={2} flex={1}>
              <Text fontSize="xs" textAlign='center'>
                Bought:<br/>
                {moment(
                  listing.completedAt,
                  'ddd MMM DD YYYY kk:mm:ss Z ZZ'
                ).format('MMM D, YYYY')}
              </Text>
            </Center>
            {listing.type === 'VEHICLE' && (
              <Button
              w="50%"
              my={2}
              mx={2}
              // variant="link"
              colorScheme="primary"
              onClick={() =>
                navigate(
                  '/' +
                  listing.type.toLowerCase() +
                  '-addons/' +
                  listing.uid
                  )
                }
                >
              Get Warranty
            </Button>
              )}
          </ButtonGroup>
        )}
      </Card>
    </>
  );
}

export default ListingCardBuyer;
