import {
  Text,
  Center,
  Divider,
  Avatar,
  Progress,
  Heading,
} from '@chakra-ui/react';
import SpinnerPage from './SpinnerPage';

function TransactionBuyerSigned({
  buyerUserData,
  sellerUserData,
  userData,
  listing,
  isBuyer,
  isSeller,
}) {

  if (isBuyer) {
    return (
      <div>
        <Center>
          <Heading as="h1" fontSize="xl">
            {listing.title}
          </Heading>
        </Center>
        <Divider borderColor="primary.500" borderWidth={1} my={2} />
        <Center my={5}>
          <Heading as="h1" fontSize="xl">
            You've signed the contract
          </Heading>
        </Center>

        <Center align="center" my={5}>
          <Text>
            The seller has received a notice that it's their turn to sign the
            contract either in their email or in the app
          </Text>
        </Center>
        <Center align="center">
          <Text>
            Please wait for them to sign the sale contract. Once signed, funds
            will immediately be transferred to the seller from your wallet
          </Text>
        </Center>

        <Center mt={8}>
          <Text color={'primary.800'} fontWeight={'500'}>
            SELLER
          </Text>
        </Center>
        <Center>
          <Avatar src={sellerUserData.photoURL} size="xl" />
        </Center>
        <Center>
          <Text fontSize="lg">
            {sellerUserData.firstName} {sellerUserData.lastName}
          </Text>
        </Center>

        <Progress size="xs" isIndeterminate colorScheme="primary" my={8} />
      </div>
    );
  }

  // is seller
  return (
    <div style={{ height: '90vh' }}>
      {!listing?.contractSignaturePageUrlSeller ? (
        <SpinnerPage />
      ) : (
        <iframe
          src={`${listing.contractSignaturePageUrlSeller}#view=fitH`}
          title="Contract"
          height="95%"
          width="100%"
        ></iframe>
      )}
    </div>
  );
}

export default TransactionBuyerSigned;
