// from https://www.auto.dev/docs Feb 14, 2023

const CARS = {
  MAKES: [
    'Acura',
    'Alfa Romeo',
    'AM General',
    'Aston Martin',
    'Audi',
    'Bentley',
    'BMW',
    'Bugatti',
    'Buick',
    'Cadillac',
    'Chevrolet',
    'Chrysler',
    'Daewoo',
    'Datsun',
    'Dodge',
    'Eagle',
    'Ferrari',
    'FIAT',
    'Fisker',
    'Ford',
    'Genesis',
    'Geo',
    'GMC',
    'Honda',
    'HUMMER',
    'Hyundai',
    'Infiniti',
    'Isuzu',
    'Jaguar',
    'Jeep',
    'Kia',
    'Lamborghini',
    'Land Rover',
    'Lexus',
    'Lincoln',
    'Lotus',
    'Maserati',
    'Maybach',
    'Mazda',
    'McLaren',
    'Mercedes-Benz',
    'Mercury',
    'MINI',
    'Mitsubishi',
    'Nissan',
    'Oldsmobile',
    'Panoz',
    'Plymouth',
    'Pontiac',
    'Porsche',
    'Ram',
    'Rolls-Royce',
    'Saab',
    'Saturn',
    'Scion',
    'Smart',
    'Sterling',
    'Subaru',
    'Suzuki',
    'Tesla',
    'Toyota',
    'Volkswagen',
    'Volvo',
  ],
  YEARS: [
    2023,
    2022,
    2021,
    2020,
    2019,
    2018,
    2017,
    2016,
    2015,
    2014,
    2013,
    2012,
    2011,
    2010,
    2009,
    2008,
    2007,
    2006,
    2005,
    2004,
    2003,
    2002,
    2001,
    2000,
    1999,
    1998,
  ],
  RANDOM_VINS: [
    '1HGCV1F32LA805102',
    '1C4HJXEG6MW692122',
    '3CZRU6H28LM107670',
    '3C6RR7KT1LG180108',
    '3VWE57BU9KM222811',
    '55SWF4KB8GU160216',
    '2C3CDXBG8KH517828',
    '3VWE57BU5KM037980',
    '2C3CDZAG0KH641122',
    '4T1B61HK9KU230419',
    '3N1AB7AP2JY302497',
    '2C3CDXBG7KH517867',
    '19UUB3F7XFA800150',
    'KMHD84LF3LU109630',
    'WDDZF6GB5HA188543',
    '1N4AA6AP3HC434543',
    '2HGFB2F42EH033094',
    'SHHFK7H46HU309892',
    '1C4BJWEG7HL610941',
    '5TBBV54128S524164',
    '1C4HJXEG7JW200905',
    'KNAE55LC7J6026889',
    '1G1FG1R72J0107461',
    '2C3CDXHG1JH306248',
    '55SWF4KB6GU149585',
    '55SWF4KB1GU135299',
    '1V2BP2CA7JC546670',
    '5J8TC2H69KL801375',
    '2C3CDXBG4KH658587',
    'KMHD84LF0LU900956',
    '2T1BURHE1HC789360',
    '2C3CDXHG0HH652365',
    '1C4HJXEN9LW255194',
    '2C3CDXBG7KH628385',
    'JTEBU5JR8J5513763',
    '1HGCR3F96HA800738',
    '1FA6P8CF1K5179939',
    '3VWG57BUXKM010928',
    'WAUFFCFL3GN016151',
    '5TFUM5F12JX077657',
    '5XYZUDLBXEG224359',
    'WVWLF7AUXGW155216',
    '2HGFC2F55GH009347',
    'SALSK2D4XDA808991',
    '1FA6P8TH6K5102954',
    '2C3CCAGGXKH640569',
    '3VWEB7BU5LM028674',
    '3VWC57BU2KM019458',
    '1FA6P8CF2J5160475',
    '2HGFC1F92KH103899',
    '5NPEG4JA3MH072738',
    '1C4RDJDG8KC764656',
    '2C3CDZAG3JH304703',
    '2C3CDXHG8JH282725',
    '2C3CDXBG7KH537519',
    '2C3CDZAG4KH605188',
    '1C4HJXDN3LW150703',
    '2C3CDXJG1KH630943',
    'KMHD84LB5JU515121',
    'WUAC6BFR4DA901128',
    'WDDSJ4GB2KN752492',
    '2C3CDXJG7JH197904',
    '1C4HJXEN3LW147668',
    'WBA8E3G54GNT75813',
    '1C6RR7YT7HS663156',
    '2C3CCAGG2KH637083',
    'JTHHP5BCGG5006525',
    '5NPE34AF9HH536578',
    '1HGCV1F37KA800119',
    '5NPEJ4J24NH144638',
    '1G1FB1RS0J0191586',
    '3N1CK3CP8FL214802',
    '2C3CDXJG4JH292825',
    '5NPD74LF8HH078495',
    'KMHD84LF6LU901027',
    '3VWE57BU4KM222148',
    '5FNYF6H32GB502880',
    '1C4BJWEG5HL518789',
    '3C6TR5HTXGG117872',
    '3GCUKREC8EG567353',
    '5NPDH4AE7GH659363',
    '1FATP8EM2H5308469',
    'SHHFK7H47LU302426',
    '1C6SRFFT8MN662573',
    '4T1BZ1HK4JU016122',
    '5UXKU2C57J0X50818',
    '1FA6P8THXJ5143957',
    '1C4HJXEN7MW754612',
    '3VWE57BU2KM013796',
    '55SWF4KB4HU179203',
    '2T2BZMCA8HC086360',
    '2HGFC1F93KH106861',
    'WAUB8HFF0G1116600',
    '1C4SDJET3GC331007',
    'WBAVL1C57FVY24824',
    '5XYPHDA35KG580222',
    'JA4JZ4AX1HZ601913',
    'SAJBJ4BV8HCY35247',
    '2C3CCAGG7JH293622',
    '1VWAS7A30GC056798',
    '2C3CDZAG1GH264131',
    'KMHLN4AG6MU104780',
    '1C4RJFAG2LC359520',
    '1C6RR7PM0FS695096',
    'WUAC6BFR5DA900117',
    '5TDGRKEC7MS066969',
    '1FATP8EM8G5324836',
    'WAUB8GFF1G1103564',
    'JF1VA2P65J9804127',
    'WBA4W9C50JAB95133',
    'JF1GV7F68CG019787',
    '5XYPGDA38GG067069',
    'JN1BV7AR9FM418741',
    '2HKRM4H30DH113909',
    '2HGFC2F54HH008837',
    '5J8TB4H72HL803095',
    '3VWC57BU6KM169783',
    'WBA3B3G56ENR85129',
    '2C3CDXJG6HH615783',
    '1C4PJMCSXHD223165',
    'WDDSJ4GB5FN178430',
    '3PCAJ5M32KF142163',
    '5FNYF6H9XKB500792',
    '1FTEW1EG1HKE28367',
    '1C4HJXEN7MW817739',
    '1GTV2LEC3HZ156402',
    '1G1125S33JU104083',
    'WDDGF8AB2DA785901',
    'JM1BPAMMXL1165365',
    '2C4RDGBG2GR390551',
    '2C3CDXJG3JH265941',
    'KMHD84LF2LU970216',
    '3KPF54AD6ME278385',
    'KMHD84LFXLU923290',
    '5N1AT2MV0HC822994',
    '1FA6P8TH4G5232948',
    '1G1PC5SB0E7279006',
    '1C4HJXEG7LW134701',
    'KNAGN4A73F5657385',
    '1C6RR7LT3KS748574',
    'JM3TCBCY4K0329019',
    '1C4BJWEG4JL802775',
    '1HGCR2F5XHA811023',
    '1C4HJXEGXNW148949',
  ]
}
export default CARS;