import { useState } from 'react';
import {
  Button,
  Text,
  Center,
  Divider,
  Heading,
  useToast,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Alert,
  Spinner,
} from '@chakra-ui/react';

import generateFinalContract from '../services/generateFinalContract';
import currencyFormat from '../services/currencyFormat';

function TransactionBuyerSigning({
  buyerUserData,
  sellerUserData,
  userData,
  listing,
  isBuyer,
  isSeller,
}) {
  const [fetching, setFetching] = useState(false);
  const toast = useToast();

  const generateContract = async () => {
    setFetching(true);
    const resp = await generateFinalContract(listing.uid);
    // console.log('resp', resp)
    if (!resp.success) {
      console.log('error', resp?.error);
      toast({
        title: 'Contract generation error: please try again later',
        status: 'error',
        isClosable: true,
      });
    }
    setFetching(false);
  };

  if (isSeller) {
    const freePlanWarning = (
      <Alert
        status="warning"
        variant="left-accent"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <AlertIcon mr={0} />
        <AlertTitle mt={1} mb={1} fontSize="lg">
          Free Plan Selected
        </AlertTitle>
        <AlertDescription maxWidth="lg">
          The buyer has selected the Free EEZE plan, you must collect payment of{' '}
          {currencyFormat(Number(listing.finalPrice))} directly from the seller
        </AlertDescription>
      </Alert>
    );
    return (
      <div style={{ height: '90vh' }}>
        {!listing?.contractSignaturePageUrlSeller ? (
          <>
            <Center>
              <Heading as="h1" fontSize="xl">
                {listing.title}
              </Heading>
            </Center>
            <Divider borderColor="primary.500" borderWidth={1} my={2} />
            {listing.eezePlanIndex === '0' && freePlanWarning}
            <Center my={'15%'} align="center">
              <Text fontSize="lg">Generating contract for you to sign</Text>
            </Center>
            <Center>
              <Spinner />
            </Center>
          </>
        ) : (
          <>
            {listing.eezePlanIndex === '0' && freePlanWarning}
            <iframe
              src={`${listing.contractSignaturePageUrlSeller}#view=fitH`}
              title="Contract"
              height="95%"
              width="100%"
            ></iframe>
          </>
        )}
      </div>
    );
  }

  // if buyer
  return (
    <div style={{ height: '90vh' }}>
      {fetching ? (
        <>
          {/* <SpinnerPage /> */}
          <Center>
            <Heading as="h1" fontSize="xl">
              {listing.title}
            </Heading>
          </Center>
          <Divider borderColor="primary.500" borderWidth={1} my={2} />
          <Center my={'15%'} align="center">
            <Text fontSize="lg">Generating contract for you to sign</Text>
          </Center>
          <Center>
            <Spinner />
          </Center>
        </>
      ) : (
        <>
          {!listing?.contractSignaturePageUrlBuyer ? (
            <>
              <Center>
                <Heading as="h1" fontSize="xl">
                  {listing.title}
                </Heading>
              </Center>
              <Divider borderColor="primary.500" borderWidth={1} my={2} />
              <Center my={'15%'} align="center">
                <Text fontSize="lg">
                  The Seller must countersign which will complete the
                  transaction
                  {listing.eezePlanIndex !== '0'
                    ? ' and automatically transfer funds to the Seller from your wallet'
                    : '. You must pay the seller directly'}
                </Text>
              </Center>
              <Button
                size="lg"
                colorScheme={'primary'}
                w="full"
                mb={10}
                onClick={generateContract}
              >
                Click here to view sale contract
              </Button>
            </>
          ) : (
            <iframe
              src={`${listing.contractSignaturePageUrlBuyer}#view=fitH`}
              // src={listing.contractSignaturePageUrlBuyer}
              title="Contract"
              height="95%"
              width="100%"
            ></iframe>
          )}
        </>
      )}
      {/* <Button w="full" variant="outline" my={4} onClick={onClickPreviousStatus}>
        Back to Previous Step
      </Button> */}
    </div>
  );
}

export default TransactionBuyerSigning;
