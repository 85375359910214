import { useNavigate } from 'react-router-dom';
import {
  Card,
  Flex,
  Avatar,
  Box,
  Text,
  Badge,
  Button,
  HStack,
} from '@chakra-ui/react';
import { BsCheckCircle } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';

import currencyFormat from '../services/currencyFormat';

const IDCard = ({
  listing,
  listingSellerFull,
  listingBuyerFull,
  userData,
  flat,
  showWallet,
}) => {
  const { t } = useTranslation('global');
  const navigate = useNavigate();
  let photo, name, location, cell, email;
  if (listing) {
    photo = listing.sellerPhotoURL;
    name = listing.sellerName;
    location = listing.sellerLocation;
  }

  if (listingSellerFull) {
    photo = listingSellerFull.sellerPhotoURL;
    name = listingSellerFull.sellerFullName
      ? listingSellerFull.sellerFullName
      : listingSellerFull.sellerName;
    location = listingSellerFull.sellerLocation;
    cell = listingSellerFull.sellerCell;
    email = listingSellerFull.sellerEmail;
  }

  if (listingBuyerFull) {
    photo = listingBuyerFull.photo;
    name = listingBuyerFull.name;
    location = listingBuyerFull.location;
    cell = listingBuyerFull.cell;
    email = listingBuyerFull.email;
  }

  if (userData) {
    if (userData?.address) {
      photo = userData.photoURL;
      name = userData?.firstName + ' ' + userData?.lastName.substring(0, 1) + '.';
      location =
      userData.address?.city + ', ' + userData?.address?.province.toUpperCase();
      // console.log(userData)
    } else {
      name = userData?.firstName + ' ' + userData?.lastName.substring(0, 1) + '.';
    }
  }
  return (
    <Card
      p={2}
      variant="filled"
      bg="grey.50"
      shadow={flat ? '' : 'md'}
      flex={1}
    >
      <Flex my={2}>
        <Avatar src={photo} borderWidth="2px" borderColor={'#5e65a5'} />
        {userData?.stageTwoVerified && (
          <BsCheckCircle fontSize={'30px'} color={'#5e65a5'} />
        )}
        <Box ml="2" w="full">
          <HStack justify='space-between'>
            <Text size="md" fontWeight="bold">
              {name}
            </Text>
            {userData && (
              <>
                {userData?.stageTwoVerified ? (
                  <Badge ml={6} variant="outline" colorScheme="blue" bg="white">
                    VERIFIED
                  </Badge>
                ) : (
                  <>
                    {userData?.stageOneVerified ? (
                      <Badge
                        ml={6}
                        variant="outline"
                        colorScheme="secondary"
                        bg="white"
                      >
                        {t('profileUnderReview')}
                      </Badge>
                    ) : (
                      <Badge
                        ml={6}
                        variant="outline"
                        colorScheme="secondary"
                        bg="white"
                      >
                        {t('profileNotVerified')}
                      </Badge>
                    )}
                  </>
                )}
              </>
            )}
          </HStack>

          <Text size="md">{location}</Text>
          {cell && <Text size="md">{cell}</Text>}
          {email && <Text size="md">{email}</Text>}
        </Box>
        {showWallet && (
          <Box flex={1} align="right" ml={2}>
            <Button
              variant="link"
              colorScheme="primary"
              onClick={() => navigate('/wallet')}
            >
              Wallet
            </Button>
            <Text>{currencyFormat(userData.walletBalance || 0)}</Text>
          </Box>
        )}
      </Flex>
    </Card>
  );
};

export default IDCard;
