import { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { doc, onSnapshot } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';

import { db, LISTING } from '../constants';
import SpinnerPage from '../components/SpinnerPage';
import EditListingVehicle from './EditListingVehicle';
import EditListingWarranty from './EditListingWarranty';
// import EditListingProduct from './EditListingProduct';

function EditListing() {
  const [listing, setListing] = useState(false);

  const auth = getAuth();
  const toast = useToast();
  const navigate = useNavigate();
  const params = useParams();

  // Redirect if listing is not user's or if not vehicle
  useEffect(() => {
    // only seller can edit
    if (listing && listing.sellerUid !== auth.currentUser.uid) {
      toast({
        title: 'Cannot edit that listing',
        status: 'error',
        isClosable: true,
      });
      navigate('/');
    }
  }, [auth, listing, navigate, toast]);

  useEffect(() => {
    // get and listen to this document
    const unsubscribe = onSnapshot(
      doc(db, 'listings', params.listingUid),
      doc => {
        if (!doc.exists()) {
          navigate('/');
          // toast({
          //   title: 'Listing does not exist',
          //   status: 'error',
          //   isClosable: true,
          // });
          return;
        }
        // set to state
        setListing({...doc.data(), uid: params.listingUid});
      }
    );
    return unsubscribe;
  }, [navigate, toast, params]);

  if (!listing) {
    return <SpinnerPage />;
  };

  // eezeID is autogenerated when listing created
  // if (!listing.eezeID) {
  //   return <SpinnerPage />;
  // };

  if (listing.type === LISTING.TYPE.VEHICLE) {
    // if STATUS private, its for warranty
    if (listing.status === LISTING.STATUS.PRIVATE) {
      return <EditListingWarranty listing={listing}/>
    } else {
      // otherwise for transaction
      return <EditListingVehicle listing={listing}/>
    }
  };

  if (listing.type === LISTING.TYPE.PRODUCT) {
    return <EditListingVehicle listing={listing}/>
  };

}

export default EditListing;
