import { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import {
  Button,
  ButtonGroup,
  Box,
  Container,
  Center,
  Divider,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
  useClipboard,
  Modal,
  Textarea,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  Skeleton,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { BsChevronLeft, BsHouse } from 'react-icons/bs';
import { EditIcon } from '@chakra-ui/icons';
// import { IoIosCloseCircleOutline } from 'react-icons/io';
import { FaRegShareSquare } from 'react-icons/fa';
import { getDoc, doc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

// import ListingPageCarousel from '../components/ListingPageCarousel';
import { db, LISTING } from '../constants';
// import logoImage from '../assets/shield256.png';
import layerImage from '../assets/promobg.png';
import SpinnerPage from '../components/SpinnerPage';
import Header from '../components/Header';
import AppBody from '../components/AppBody';
import ShareModal from '../components/ShareModal';
// import ListingWarrantyOptions from '../components/ListingWarrantyOptions';
// import IDCard from '../components/IDCard';
import currencyFormat from '../services/currencyFormat';
import DataContext from '../context/DataContext';
import odometerFormat from '../services/odometerFormat';

function Toolkit() {
  const toast = useToast();
  const printRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [listing, setListing] = useState(null);
  const [loading, setLoading] = useState(true);
  const { onCopy, value, setValue, hasCopied } = useClipboard('');
  const {
    onCopy: onCopy2,
    value: value2,
    setValue: setValue2,
    hasCopied: hasCopied2,
  } = useClipboard('');
  const {
    onCopy: onCopy3,
    value: value3,
    setValue: setValue3,
    hasCopied: hasCopied3,
  } = useClipboard('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const params = useParams();
  const auth = getAuth();
  const { userData } = useContext(DataContext);

  const sender = userData?.firstName + ' ' + userData?.lastName;
  const url = listing?.shortUrl;
  const linkUrl = 'https://' + url;
  // const qrUrl = 'https://' + url + '.qr';
  const qrUrl =
    listing?.eezeID &&
    `https://qr.rebrandly.com/v1/qrcode?shortUrl=https%3A%2F%2Feeze.co%2F${listing?.eezeID}&source=com.rebrandly.app&size=512`;

  const text1 = `Hello, to move forward on my ${listing?.title}, please follow this link ${linkUrl} and create a profile on EEZE, a free platform that verifies people and the vehicle, as well as secures any money transfers.\n\nRegards, ${sender}`;
  const text2 = `Hello, my listing's EEZE ID is ${
    listing?.eezeID && listing.eezeID.toUpperCase()
  } and the listing page is ${linkUrl}`;
  // const { isOpen, onOpen, onClose } = useDisclosure();
  // const {
  //   isOpen: isOpenFavs,
  //   onOpen: onOpenFavs,
  //   onClose: onCloseFavs,
  // } = useDisclosure();

  useEffect(() => {
    const fetchListing = async () => {
      const docRef = doc(db, 'listings', params.listingUid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setListing(docSnap.data());
        setValue(text1);
        setValue2(text2);
        setValue3(linkUrl);
        setLoading(false);
      }
    };
    fetchListing();
  }, [
    navigate,
    setValue,
    params.listingUid,
    text1,
    setValue2,
    text2,
    setValue3,
    linkUrl,
  ]);

  // console.log('listing', listing);
  if (loading) {
    return <SpinnerPage />;
  }

  const contactSeller = () => {
    if (!userData.stageOneVerified) {
      window.alert('You must update your Profile before contacting the Seller');
      return;
    }
    navigate('/connect/' + listing.uid + '/' + userData.uid);
  };

  const handleDownloadImage = async () => {
    setIsLoading(true);
    const element = printRef.current;
    const canvas = await html2canvas(element, {
      proxy:
        'https://us-central1-html2canvas-proxy-da317.cloudfunctions.net/app',
    });

    const data = canvas.toDataURL('image/png');
    const link = document.createElement('a');

    if (typeof link.download === 'string') {
      link.href = data;
      link.download = 'EEZE-listing.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast({
        title: 'Image Downloading',
        description: 'See browser downloads',
        status: 'success',
        isClosable: true,
      });
    } else {
      window.open(data);
    }
    setIsLoading(false);
  };

  let listingIsActive = true;

  if (listing.status === LISTING.STATUS.DRAFT) {
    listingIsActive = false;
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ShareModal url={listing.shortUrl} />
          <ModalFooter>
            <Button
              w="full"
              variant="outline"
              colorScheme="primary"
              onClick={onClose}
            >
              CLOSE
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Header />
      <AppBody>
        <SimpleGrid
          // columns={{ base: 1, lg: 2 }}
          // spacing={{ base: 8, md: 10 }}
          py={{ base: 2, md: 4 }}
        >
          {listing.status === LISTING.STATUS.COMPLETED && (
            <>
              <Divider borderColor="primary.500" borderWidth={1} />
              <Center align="center">
                <Heading color={'primary.500'}>
                  This listing has been sold
                </Heading>
              </Center>
              <Divider borderColor="primary.500" borderWidth={1} />
            </>
          )}

          <Text fontSize={'xl'} fontWeight={'300'}>
            {listing.title} {listing.style}
          </Text>
          <HStack 
          // justify="space-between"
          >
            <Text flex={1}>{currencyFormat(listing.askingPrice, 0)}</Text>
            <Text flex={2} textAlign='right'>
              {odometerFormat(listing.odometer) + ' ' + listing.odometerType}
            </Text>
          </HStack>
          <Divider borderColor="primary.500" borderWidth={1} />
          {/* <Box>
            <Text as="b">ID: {listing.eezeID.toUpperCase()}</Text>
            <Text fontSize="sm">{listing.shortUrl}</Text>
          </Box> */}

          {listing?.listingProfilePhoto && (
            <>
              <Center mt={5} mb={2} mx={2} textAlign="center">
                <Text>
                  Vehicle profile with EEZE features and links to listing page.
                  Add this image to your marketplace listing
                </Text>
              </Center>

              <Box ref={printRef} pb={5}>
                <Box
                  position="relative"
                  w="100%"
                  minH="200px"
                  overflow="hidden"
                >
                  <Image
                    w="100%"
                    h="100%"
                    position="absolute"
                    objectFit="contain"
                    src={layerImage}
                    alt="EEZELayer"
                    // maxH="540px"
                    // maxW="960px"
                  />
                  <Image
                    alt="Profile"
                    w="100%"
                    h="100%"
                    objectFit="contain"
                    src={listing?.listingProfilePhoto || ''}
                  />
                  <Image
                    w="18%"
                    position="absolute"
                    objectFit="contain"
                    src={qrUrl}
                    alt="qrCode"
                    right={0}
                    mb={2}
                    mr={'10%'}
                    bottom={0}
                  />
                </Box>
                <Text
                  textAlign="right"
                  pr={'5%'}
                  color="primary.800"
                  fontSize={{ base: '12px', md: '16px', lg: '16px' }}
                >
                  Get started at {url}
                </Text>
              </Box>
              <Center mb={5}>
                <Button
                  // textAlign='right'
                  colorScheme="primary"
                  // mx={10}
                  w="full"
                  maxW="619px"
                  isLoading={isLoading}
                  mx={2}
                  onClick={handleDownloadImage}
                >
                  Download Image
                </Button>
              </Center>
            </>
          )}

          <Divider borderColor="primary.500" borderWidth={1} />

          <Center m={2} textAlign="center">
            <Text>QR code to EEZE listing page of {linkUrl}</Text>
          </Center>
          <Flex mb={5} alignItems={'center'}>
            <Center flex={1}>
              <Image
                // flex={1}
                // boxSize="35%"
                w="30%"
                // fallback={<Spinner />}
                fallback={
                  <Skeleton w="30%">
                    <Text>1</Text>
                    <Text>2</Text>
                    <Text>3</Text>
                  </Skeleton>
                }
                alt="qrCode"
                src={qrUrl}
              />
            </Center>
          </Flex>
          <Button
            mb={5}
            colorScheme="primary"
            onClick={() => saveAs(qrUrl, 'EEZE-QR.jpg')}
          >
            Download QR Code
          </Button>
          <Divider borderColor="primary.500" borderWidth={1} />

          <Button
            colorScheme="primary"
            onClick={onOpen}
            rightIcon={<FaRegShareSquare />}
            my={5}
          >
            Share EEZE Listing
          </Button>

          <Divider borderColor="primary.500" borderWidth={1} />

          <Center mt={5} mb={2} mx={2} textAlign="center">
            <Text>Copy/paste these sample messages to send to a buyer</Text>
          </Center>

          <Flex mb={5} alignItems={'center'}>
            <Textarea
              // isReadOnly
              // onChange={()=>{}}
              mr={2}
              size="sm"
              rows={6}
              value={value}
              onChange={e => setValue(e.target.value)}
              bg="gray.100"
            />
            <Button colorScheme="primary" onClick={onCopy}>
              {hasCopied ? 'Copied!' : 'Copy'}
            </Button>
          </Flex>

          <Flex mb={5} alignItems={'center'}>
            <Textarea
              // isReadOnly
              mr={2}
              size="sm"
              rows={3}
              value={value2}
              onChange={e => setValue2(e.target.value)}
              bg="gray.100"
            />
            <Button colorScheme="primary" onClick={onCopy2}>
              {hasCopied2 ? 'Copied!' : 'Copy'}
            </Button>
          </Flex>

          <Flex mb={5} alignItems={'center'}>
            <Textarea
              isReadOnly
              // onChange={e => setValue3(e.target.value)}
              mr={2}
              size="sm"
              rows={1}
              value={value3}
              bg="gray.100"
            />
            <Button colorScheme="primary" onClick={onCopy3}>
              {hasCopied3 ? 'Copied!' : 'Copy'}
            </Button>
          </Flex>

          {/* <Divider borderColor="primary.500" borderWidth={1} /> */}
        </SimpleGrid>
      </AppBody>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <Container
        position="fixed"
        bottom="0"
        // width="full"
        bgColor="white"
        py="5"
        borderWidth="1px"
        maxW="full"
        centerContent
      >
        {!auth.currentUser ? (
          <Box px={5}>
            <Center mb={2}>
              <Text color={'primary.800'}>
                Login / Signup to contact the Seller
              </Text>
            </Center>
            <Stack direction="row" w="full">
              <Button
                flex="1"
                onClick={() => navigate('/login?uid=' + listing.uid)}
                colorScheme="primary"
                variant="outline"
              >
                Login
              </Button>
              <Button
                flex="1"
                onClick={() => navigate('/signup?uid=' + listing.uid)}
                colorScheme="primary"
              >
                Sign Up
              </Button>
            </Stack>
          </Box>
        ) : (
          <Box>
            {auth.currentUser.uid !== listing.sellerUid ? (
              <Box px={5}>
                <HStack>
                  <Button
                    size={'lg'}
                    colorScheme="primary"
                    onClick={contactSeller}
                    disabled={!listingIsActive}
                  >
                    BUY / OFFER
                  </Button>
                  <Box flex={1}>
                    <Text align="center">Accepting credit card payments!</Text>
                  </Box>
                </HStack>
              </Box>
            ) : (
              <ButtonGroup w={'full'} mt={4}>
                {/* <Box px={1}> */}
                <Button
                  // mt={2}
                  colorScheme="primary"
                  // w='full'
                  variant="outline"
                  // flex={1}
                  leftIcon={<BsChevronLeft />}
                  onClick={() => navigate(`/`)}
                >
                  <BsHouse />
                </Button>
                <Button
                  colorScheme="primary"
                  variant="outline"
                  // flex={1}
                  onClick={() => navigate(`/listing/${listing.uid}`)}
                >
                  Listing Page
                </Button>
                <Button
                  // flex={1}
                  colorScheme="primary"
                  rightIcon={<EditIcon />}
                  onClick={() => navigate(`/edit/${listing.uid}`)}
                >
                  Edit
                </Button>
                {/* </Box> */}
              </ButtonGroup>
            )}
          </Box>
        )}
      </Container>
    </>
  );
}

export default Toolkit;
