import {
  Button,
  Text,
  Center,
  Divider,
  Image,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  useDisclosure,
} from '@chakra-ui/react';
import { doc, updateDoc } from 'firebase/firestore';

import { db, LISTING, isDev } from '../constants';
import ListingPageCarousel from '../components/ListingPageCarousel';
import odometerFormat from '../services/odometerFormat';
import ContinueButton from '../components/ContinueButton';
import logoImage from '../assets/carfaxcanada.png';
import TransactionSellerPendingScreen from '../components/TransactionSellerPendingScreen';

function TransactionDisplayOptions({
  buyerUserData,
  sellerUserData,
  userData,
  listing,
  isBuyer,
  isSeller,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const onClickNextStatus = async () => {
    const thisListingRef = doc(db, 'listings/', listing.uid);
    await updateDoc(thisListingRef, {
      status: LISTING.STATUS.BUYER_FUNDING,
    });
  };
  const onClickPreviousStatus = async () => {
    const thisListingRef = doc(db, 'listings/', listing.uid);
    await updateDoc(thisListingRef, {
      status: LISTING.STATUS.SELECT_OPTIONS,
    });
  };

  const carouselPhotos = [];
  if (listing.listingProfilePhoto) {
    carouselPhotos.push(listing.listingProfilePhoto);
  }
  if (listing.vehicleOdometerPhoto) {
    carouselPhotos.push(listing.vehicleOdometerPhoto);
  }
  if (listing.vehicleLicensePhoto) {
    carouselPhotos.push(listing.vehicleLicensePhoto);
  }
  if (listing.vehicleVINPhoto) {
    carouselPhotos.push(listing.vehicleVINPhoto);
  }
  if (listing.vehicleRegistrationPhoto) {
    carouselPhotos.push(listing.vehicleRegistrationPhoto);
  }
  if (listing.vehicleInsurancePhoto) {
    carouselPhotos.push(listing.vehicleInsurancePhoto);
  }
  if (listing.vehicleDigitalConditionReport) {
    carouselPhotos.push(listing.vehicleDigitalConditionReport);
  }
  if (listing.vehicleConditionReport) {
    carouselPhotos.push(listing.vehicleConditionReport);
  }
  if (listing.vehicleSafetyTest) {
    carouselPhotos.push(listing.vehicleSafetyTest);
  }
  if (listing.listingOtherPhotos) {
    listing.listingOtherPhotos.map(photo => carouselPhotos.push(photo));
  }

  if (isSeller) {
    return (
      <TransactionSellerPendingScreen
        buyerUserData={buyerUserData}
        listing={listing}
        freePlanSelected={listing.eezePlanIndex === '0'}
      />
    );
  }

  // console.log(listing)
  // if buyer
  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ListingPageCarousel photos={carouselPhotos} />
          <ModalFooter>
            <Button
              w="full"
              variant="ghost"
              colorScheme="primary"
              onClick={onClose}
            >
              CLOSE
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Center>
        <Heading as="h1" fontSize="xl">
          {listing.title}
        </Heading>
      </Center>
      <Center>
        <Text>VIN: {listing.vin}</Text>
      </Center>
      <Center>
        <Text>
          Odometer: {odometerFormat(listing.odometer)} {listing.odometerType}
        </Text>
      </Center>
      <Divider borderColor="primary.500" borderWidth={1} my={2} />
      <Center align="center" mt={5} mb={3}>
        <Text fontSize="lg">To review all vehicle photos</Text>
      </Center>
      <Center>
        <Button mb={5} colorScheme="primary" w="85%" onClick={onOpen}>
          Click here
        </Button>
      </Center>

      {!listing?.vehicleDigitalConditionReport &&
        listing?.vehicleDigitalConditionReportPending && (
          <>
            <Divider mb={5} borderWidth={1} borderColor="primary.500" />

            <Text textAlign="center" color="blue.500" mb={5}>
              The seller has received notification to complete a full digital
              inspection of their vehicle. Once complete, EEZE will attach the
              report to this vehicle listing and email you the result
            </Text>
            <Center mb={5}>
              <Button colorScheme="primary" w="85%" disabled>
                Click here
              </Button>
            </Center>
          </>
        )}

      {listing?.vehicleDigitalConditionReport && (
        <>
          <Divider mb={5} borderWidth={1} borderColor="primary.500" />

          <Center align="center" mb={3}>
            <Text fontSize="lg">View digital condition report</Text>
          </Center>
          <Center mb={5}>
            <Button
              colorScheme="primary"
              w="85%"
              onClick={() =>
                window.open(listing.vehicleDigitalConditionReport, '_blank')
              }
            >
              Click here
            </Button>
          </Center>
        </>
      )}

      {!listing?.vehicleCarfaxVHR && listing?.vehicleCarfaxVHRPending && (
        <>
          <Divider mb={5} borderWidth={1} borderColor="primary.500" />
          <Center mb={3}>
            <Image
              src={logoImage}
              alt="Logo"
              width="50%"
              maxW="200"
              objectFit="cover"
            />
          </Center>
          <Text textAlign="center" color="blue.500" mb={5}>
            Vehicle History Report is being generated, please standby. EEZE will
            attach the report to this vehicle listing and email you the result
          </Text>
          <Center mb={5}>
            <Button colorScheme="primary" w="85%" disabled>
              Click here
            </Button>
          </Center>
        </>
      )}

      {listing?.vehicleCarfaxVHR && (
        <>
          <Divider mb={5} borderWidth={1} borderColor="primary.500" />
          <Center mb={3}>
            <Image
              src={logoImage}
              alt="Logo"
              width="50%"
              maxW="200"
              objectFit="cover"
            />
          </Center>
          <Center align="center" mb={3}>
            <Text fontSize="lg">View CARFAX Report</Text>
          </Center>
          <Center mb={5}>
            <Button
              colorScheme="primary"
              w="85%"
              onClick={() => window.open(listing.vehicleCarfaxVHR, '_blank')}
            >
              Click here
            </Button>
          </Center>
        </>
      )}

      {listing?.vehicleCarfaxVHRManual && (
        <>
          <Divider mb={5} borderWidth={1} borderColor="primary.500" />
          <Center align="center" mt={6}>
            <Text fontSize="lg" mb={3}>
              View Seller Provided CARFAX
            </Text>
          </Center>
          <Center mb={5}>
            <Button
              mb={10}
              colorScheme="primary"
              w="85%"
              onClick={() =>
                window.open(listing.vehicleCarfaxVHRManual, '_blank')
              }
            >
              Click here
            </Button>
          </Center>
        </>
      )}

      <ContinueButton onClick={onClickNextStatus}>CONTINUE</ContinueButton>
      <br />
      {!listing?.vehicleDigitalConditionReport && !listing.vehicleDigitalConditionReportPending && (
        <Button
          w="full"
          variant="outline"
          my={4}
          onClick={onClickPreviousStatus}
        >
          Back to Select Plan
        </Button>
      )}
      <br />
      <br />
      {isDev && (
        <>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <Button
            variant="ghost"
            size="sm"
            color="lightgrey"
            onClick={onClickPreviousStatus}
          >
            DEV Previous Status
          </Button>
          {/* <Button variant="ghost" onClick={onClickCancel}>
            Cancel
          </Button> */}
        </>
      )}
    </div>
  );
}

export default TransactionDisplayOptions;
