import React from 'react';
import { useState, useRef } from 'react';
import { Link, useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { signOut, getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { updateDoc, doc, getDoc } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Flex,
  Button,
  Divider,
  IconButton,
  Image,
  FormControl,
  Heading,
  InputGroup,
  InputRightElement,
  Center,
  Input,
  Text,
  useToast,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import ReCAPTCHA from 'react-google-recaptcha';

import SwitchLanguage from '../components/SwitchLanguage';
// import OAuth from '../components/OAuth';
import { db, recaptchaKey, deployed } from '../constants';
import logoImage from '../assets/shield256.png';
import signInImage from '../assets/signInImage.png';

function Login() {
  const { t } = useTranslation('global');
  const captchaRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [queryParameters] = useSearchParams();
  const toast = useToast();
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [captchaIsDone, setcaptchaIsDone] = useState(false);
  const [formData, setFormData] = useState({
    email: queryParameters.get('email') || '',
    password: '',
  });
  const { email, password } = formData;


  // keep params if any, for redirect
  // let ref;
  // if (queryParameters.get('ref')) {
  //   ref = queryParameters.get('ref');
  //   // ex /?ref=garage1
  // }
  // let aref;
  // if (queryParameters.get('aref')) {
  //   aref = queryParameters.get('aref');
  //   // ex /?aref=affiliate1
  // }
  // if (queryParameters.get('a')) {
  //   aref = queryParameters.get('a');
  //   // ex /?a=affiliate1
  // }
  // let iref;
  // if (queryParameters.get('iref')) {
  //   iref = queryParameters.get('iref');
  //   // ex /?iref=influencer1
  // }
  // if (queryParameters.get('i')) {
  //   iref = queryParameters.get('i');
  //   // ex /?i=influencer1
  // }
  // let uid;
  // if (queryParameters.get('uid')) {
  //   uid = queryParameters.get('uid');
  // }
  // // const uidRef = uid ? '&uid=' + uid : '';
  // // const refRef = ref ? '&ref=' + ref : '';
  // // const arefRef = aref ? '&aref=' + aref : '';
  // // const irefRef = iref ? '&iref=' + iref : '';
  // // const existingParams = '?' + uidRef + refRef + arefRef + irefRef;

  const existingParams = location?.search || '';

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const onChangeCaptcha = async value => {
    setcaptchaIsDone(true);
  };

  const onChange = e => {
    setFormData(prevState => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const onSubmit = async e => {
    e.preventDefault();

    try {
      const auth = getAuth();

      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      ).catch(e => {
        if (e.code === 'auth/wrong-password') {
          toast({
            title: t('pleaseCheckThePassword'),
            status: 'error',
            isClosable: true,
            });
            }
            if (e.code === 'auth/user-not-found') {
              toast({
            title: t('pleaseCheckTheEmail'),
            status: 'error',
            isClosable: true,
          });
        }
        return;
      });

      if (userCredential.user) {
        // login credentials are good
        // if no users entry, logout and display error
        const docRef = doc(db, 'users', userCredential.user.uid);
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) {
          // console.log('there is no garage with this login, log out');
          return signOut(auth).then(() => {
            toast({
              title: t('userCredentialError'),
              description: 'Wrong portal',
              status: 'error',
              isClosable: true,
            });
            navigate('/login'+existingParams);
          });
        }

        // console.log('usercredential user', userCredential.user)
        updateDoc(doc(db, 'users', userCredential.user.uid), {
          lastLogin: new Date().toString(),
          // email: userCredential.user.email,
          // emailIsVerified: userCredential.user.emailVerified,
          // emailIsVerifiedAt: new Date().toString()
        });

        toast({
          title: t('loginSuccess'),
          description: t('welcomeBack'),
          // status: 'success',
          duration: 9000,
          isClosable: true,
        });

        navigate('/'+existingParams);
      }
    } catch (error) {
      setIsSubmitting(false);
      toast({
        title: t('userCredentialError'),
        status: 'error',
        isClosable: true,
      });
    }
  };

  return (
    <Flex position="relative" mb="40px">
      <Flex
        h={{ sm: 'initial', md: '75vh', lg: '85vh' }}
        w="100%"
        maxW="1044px"
        mx="auto"
        justifyContent="space-between"
        mb="30px"
        pt={{ sm: '50px', md: '0px' }}
      >
        <Flex
          alignItems="center"
          justifyContent="start"
          style={{ userSelect: 'none' }}
          w={{ base: '100%', md: '50%', lg: '42%' }}
        >
          <Flex
            direction="column"
            w="100%"
            background="transparent"
            p="28px"
            mt={{ md: '150px', lg: '80px' }}
          >
            <SwitchLanguage />
            <Center>
              <Image
                src={logoImage}
                alt="Logo"
                boxSize="60px"
                objectFit="cover"
              />
            </Center>
            <Center>
              <Heading mb="10px" textAlign="center">
                {t('loginToEEZE')}
              </Heading>
            </Center>
            <Divider borderColor="primary.800" my={5} />
            {/* <OAuth /> */}

            <Center>
              <Text mb="16px" mt="10px">
                {t('enterYourEmailAndPassword')}
              </Text>
            </Center>
            <form onSubmit={onSubmit}>
              <FormControl>
                <Input
                  borderRadius="10px"
                  mb="14px"
                  fontSize="sm"
                  type="email"
                  placeholder={t('emailAddress')}
                  size="lg"
                  id="email"
                  value={email}
                  onChange={onChange}
                />
                <InputGroup>
                  <Input
                    borderRadius="10px"
                    mb="14px"
                    fontSize="sm"
                    type={showPassword ? 'text' : 'password'}
                    placeholder={t('password')}
                    size="lg"
                    id="password"
                    value={password}
                    onChange={onChange}
                  />
                  <InputRightElement h={'full'}>
                    <IconButton
                      variant="ghost"
                      size={'lg'}
                      mb="14px"
                      icon={
                        showPassword ? (
                          <ViewOffIcon color={'secondary.500'} boxSize={6} />
                        ) : (
                          <ViewIcon color={'secondary.500'} boxSize={6} />
                        )
                      }
                      aria-label={'Toggle'}
                      onClick={togglePasswordVisibility}
                    />
                  </InputRightElement>
                </InputGroup>

                <Center>
                  <ReCAPTCHA
                    sitekey={recaptchaKey}
                    onChange={onChangeCaptcha}
                    ref={captchaRef}
                  />
                </Center>
                {captchaIsDone && email && password ? (
                  <Button
                    type="submit"
                    colorScheme="primary"
                    w="100%"
                    // h="45"
                    mb="20px"
                    mt="14px"
                    isLoading={isSubmitting ? true : false}
                    disabled={isSubmitting ? true : false}
                  >
                    {t('login')}
                  </Button>
                ) : (
                  <Button
                  // type="submit"
                  colorScheme="primary"
                  w="100%"
                  // h="45"
                  mb="20px"
                  mt="14px"
                  disabled
                  >
                    {t('login')}
                  </Button>
                )}
              </FormControl>
            </form>
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              maxW="100%"
              mt="0px"
            >
              <Text fontWeight="medium">
                {t('dontHaveAnAccount')}
                <Link as="span" ms="5px" to={"/signup"+existingParams}>
                {t('signup')}
                </Link>
              </Text>
            </Flex>
            <Center>
              <Link to="/forgot-password">
                <Text mt="30px" mb="30px" fontSize="14px">
                {t('forgotPassword')}
                </Text>
              </Link>
            </Center>
          </Flex>
        </Flex>

        <Box
          display={{ base: 'none', md: 'block' }}
          overflowX="hidden"
          h="100%"
          w="50vw"
          position="absolute"
          right="0px"
        >
          <Box
            bgImage={signInImage}
            w="100%"
            h="100%"
            // bgSize="cover"
            bgSize="contain"
            bgPosition="50%"
            position="absolute"
            borderBottomLeftRadius="20px"
          ></Box>
        </Box>
      </Flex>
      <Text
        position="absolute"
        bottom={0}
        right={0}
        color="grey"
        fontSize={'xs'}
      >
        {deployed}
      </Text>
    </Flex>
  );
}

export default Login;
