// import {
//     getAuth,
// } from 'firebase/auth';
import {
    SEND_SMS_SIGNUP_LINK,
} from '../constants';

const sendSMSLink = async (to, pathSearch) => {

    const url = `${SEND_SMS_SIGNUP_LINK}to=${to}${pathSearch?'&pathSearch='+pathSearch:""}`;

    try {
        const response = await fetch(
            url, {
            method: 'GET',
            // withCredentials: true,
            // credentials: 'include',

            // headers: {
            //     'Authorization': 'Bearer ' + token,
            // }
        }
        );

        const data = await response.json();
        // console.log('data from sendSMSLink server', data);
        return data
    }

    catch (error) {
        console.log(error)
        return error;
    }
}

export default sendSMSLink