import { Navigate, Outlet } from 'react-router-dom'
import { useAuthStatus } from '../hooks/useAuthStatus'
import SpinnerPage from './SpinnerPage'

const PrivateRoute = () => {
  const { loggedIn, checkingStatus } = useAuthStatus()

  if (checkingStatus) {
    return <SpinnerPage />
  }

  return loggedIn ? <Outlet /> : <Navigate to='/start' />
}

export default PrivateRoute
