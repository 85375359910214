import { useNavigate } from 'react-router-dom';
import { Button, Center } from '@chakra-ui/react';
import HelpCarousel from '../components/HelpCarousel';

export default function Welcome() {
  const navigate = useNavigate();
  return (
    <div>
      <HelpCarousel />
      <br />
      <Center>
        <Button
          onClick={() => navigate('/')}
          colorScheme="primary"
          variant="outline"
        >
          Go to Homepage
        </Button>
      </Center>
      <br />
      <br />
    </div>
  );
}
