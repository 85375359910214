import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Alert,
  AlertTitle,
  AlertDescription,
  AlertIcon,
  Text,
  Flex,
  Checkbox,
  ListIcon,
  List,
  ListItem,
  RadioGroup,
  Radio,
  VStack,
  Heading,
  Stack,
  Spinner,
  HStack,
  Box,
} from '@chakra-ui/react';
import { doc, updateDoc } from 'firebase/firestore';
import { FaArrowCircleRight } from 'react-icons/fa';
import { BsChevronRight } from 'react-icons/bs';
import {
  db,
  LISTING,
  isDev,
  PLANS,
  DEFAULT_PLAN_INDEX,
  FEATURES,
  PRICING_OPTIONS,
  eligibleWarrantyProvinces,
} from '../constants';
import carfaxRequired from '../services/carfaxRequired';
import inspectionRequired from '../services/inspectionRequired';
import currencyFormat from '../services/currencyFormat';
import ContinueButton from '../components/ContinueButton';
import TransactionSellerPendingScreen from '../components/TransactionSellerPendingScreen';

function TransactionSelectOptions({
  buyerUserData,
  sellerUserData,
  userData,
  listing,
  isBuyer,
  isSeller,
}) {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [eligibleProvince, setEligibleProvince] = useState(true);
  const [cancelFeeAcknowledge, setCancelFeeAcknowledge] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!listing) return;

    let allowAllPlans = true;
    if (!eligibleWarrantyProvinces.includes(listing?.province.toLowerCase())) {
      allowAllPlans = false;
      setEligibleProvince(false);
    }
    if (!buyerUserData.bankAccountAdded) {
      allowAllPlans = false;
    }

    if (allowAllPlans) {
      setSelectedIndex(listing.eezePlanIndex || DEFAULT_PLAN_INDEX);
    } else {
      setSelectedIndex('0');
    }
  }, [setEligibleProvince, listing, buyerUserData]);

  const province = listing?.province;

  const onClickNextStatus = async () => {
    const thisListingRef = doc(db, 'listings/', listing.uid);
    const updateObj = {
      status: PLANS[selectedIndex].skipToBuyerSigning
        ? LISTING.STATUS.BUYER_SIGNING
        : LISTING.STATUS.DISPLAY_OPTIONS,
      // buyerRequestInsuranceQuote: consentForInsurance ? true : false,
      allInPrice:
        Number(listing.finalPrice) +
        Number(PLANS[selectedIndex].price[province]?.priceIncludingTax || 0),
      eezePlanIndex: selectedIndex,
      eezePlanPrice:
        PLANS[selectedIndex].price[province]?.priceIncludingTax || 0,
      eezePlan: PLANS[selectedIndex],
      vehicleDigitalConditionReportPending: PLANS[selectedIndex]
        ?.digitalInspectionIncluded
        ? true
        : false,
      vehicleCarfaxVHRPending: PLANS[selectedIndex]?.carfaxVHRIncluded
        ? true
        : false,
      warrantyName: PLANS[selectedIndex]?.includedWarrantyCode
        ? PRICING_OPTIONS.addons[PLANS[selectedIndex]?.includedWarrantyCode]
            .warrantyName
        : null,
        warrantyAddonCode: PLANS[selectedIndex]?.includedWarrantyCode
        ? PRICING_OPTIONS.addons[PLANS[selectedIndex]?.includedWarrantyCode]
            .code
        : null,
      warrantyActive: PLANS[selectedIndex]?.includedWarrantyCode ? false : null,
      buyerEmail: buyerUserData.email,
    };
    // console.log(updateObj)

    await updateDoc(thisListingRef, updateObj);

    if (PLANS[selectedIndex]?.carfaxVHRIncluded) {
      carfaxRequired();
    }
    if (PLANS[selectedIndex]?.digitalInspectionIncluded) {
      inspectionRequired(listing.uid);
    }
  };
  const onClickPreviousStatus = async () => {
    const thisListingRef = doc(db, 'listings/', listing.uid);
    await updateDoc(thisListingRef, {
      status: LISTING.STATUS.ACCEPTED,
    });
  };

  if (isSeller) {
    return (
      <TransactionSellerPendingScreen
        buyerUserData={buyerUserData}
        listing={listing}
        freePlanSelected={!eligibleProvince}
      />
    );
  }

  const renderPlanOptions = () => {
    if (!selectedIndex) {
      return <Spinner />;
    }
    const sortedPlans = PLANS.sort((a, b) => a.planIndex - b.planIndex);
    // remove active: false plans
    const activeSortedPlans = sortedPlans.filter(obj => obj.active === true);

    let allowAllPlans = true;
    if (!eligibleProvince) {
      allowAllPlans = false;
    }
    if (!buyerUserData.bankAccountAdded) {
      allowAllPlans = false;
    }

    return (
      <>
        {!eligibleProvince && (
          <Alert
            mb={2}
            status="warning"
            variant="left-accent"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <AlertIcon mr={0} />
            <AlertTitle mt={1} mb={1} fontSize="lg">
              Please Note
            </AlertTitle>
            <AlertDescription maxWidth="lg">
              For now, premium EEZE features are only available in Ontario and
              Quebec
              <br />
              <br />
              This{' '}
              {listing.province.charAt(0).toUpperCase() +
                listing.province.slice(1)}{' '}
              listing is only eligible for the free EEZE plan which does not
              include realtime payments or extended warranties
            </AlertDescription>
          </Alert>
        )}
        {!buyerUserData.bankAccountAdded && (
          <Alert
            mb={2}
            status="warning"
            variant="left-accent"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <AlertIcon mr={0} />
            <AlertTitle mt={1} mb={1} fontSize="lg">
              No EEZE Wallet
            </AlertTitle>
            <AlertDescription maxWidth="lg">
              You must connect your bank account to create your EEZE Wallet
              which will unlock all EEZE plans
              <br />
              Go to your Wallet to complete this step
              <br />
              <br />
              <Button
                // colorScheme="primary"
                bg="black"
                color="white"
                onClick={() => navigate('/wallet')}
                rightIcon={<BsChevronRight />}
              >
                To Wallet
              </Button>
            </AlertDescription>
          </Alert>
        )}
        <Box
          shadow={'base'}
          borderWidth="1px"
          textAlign="center"
          minWidth="330px"
          py={5}
          px={2}
          mb={5}
          borderColor={'primary.500'}
          borderRadius={'lg'}
        >
          <RadioGroup
            // defaultValue={selectedIndex}
            value={selectedIndex}
          >
            <Stack direction="row" justify="space-between">
              <Radio
                value={activeSortedPlans[0].planIndex}
                onChange={e => {
                  setCancelFeeAcknowledge(false);
                  setSelectedIndex('0');
                }}
              >
                <Text fontSize="lg">
                  {activeSortedPlans[0].name.toUpperCase()}
                </Text>
              </Radio>
              <Radio
                value={activeSortedPlans[1].planIndex}
                isDisabled={!allowAllPlans}
                onChange={e => {
                  setCancelFeeAcknowledge(false);
                  setSelectedIndex('1');
                }}
              >
                <Text fontSize="lg">
                  {activeSortedPlans[1].name.toUpperCase()}
                </Text>
              </Radio>
              <Radio
                value={activeSortedPlans[2].planIndex}
                isDisabled={!allowAllPlans}
                onChange={e => {
                  setCancelFeeAcknowledge(false);
                  setSelectedIndex('2');
                }}
              >
                <Text fontSize="lg">
                  {activeSortedPlans[2].name.toUpperCase()}
                </Text>
              </Radio>
            </Stack>
          </RadioGroup>
        </Box>

        <HStack align={'center'} justify={'center'}>
          <Text fontSize={'3xl'}>$</Text>
          <Text fontSize={'5xl'} fontWeight={600}>
            {PLANS[selectedIndex].price[province]?.priceBeforeTax || 0}
          </Text>
        </HStack>

        <Text align="center" fontSize="lg">
          +{' '}
          {currencyFormat(
            PLANS[selectedIndex].price[province]?.taxes?.gst || 0
          )}
          {PLANS[selectedIndex].price[province]?.taxes?.qst === 0 ? (
            ' HST'
          ) : (
            <>
              {' GST + ' +
                currencyFormat(
                  PLANS[selectedIndex].price[province]?.taxes?.qst || 0
                ) +
                ' QST'}
            </>
          )}
        </Text>
        <HStack align={'center'} justify={'center'}>
          <Text fontSize={'2xl'}>$</Text>
          <Text align="center" fontSize="3xl">
            {PLANS[selectedIndex].price[province]?.priceIncludingTax || 0}
          </Text>
        </HStack>
        <Text textAlign="center">Including tax</Text>
        <VStack bg={'gray.50'} py={5} mt={5} borderBottomRadius={'xl'}>
          <Text as="i">Plan Features:</Text>
          <List spacing={2} textAlign="start" px={2}>
            {PLANS[selectedIndex].features.map(featureId => (
              <ListItem key={featureId}>
                <ListIcon
                  verticalAlign="center"
                  as={FaArrowCircleRight}
                  color="secondary.600"
                />
                {FEATURES[featureId].name}
              </ListItem>
            ))}
          </List>
        </VStack>
      </>
    );
  };
  // if buyer
  return (
    <div>
      <VStack mb={4} textAlign="center">
        <Heading as="h1" fontSize="xl">
          Confirm the EEZE plan
        </Heading>
      </VStack>

      {renderPlanOptions()}

      <Flex justify="space-between" alignItems="center" mt={2}>
        <Checkbox
          value="insuranceConsent"
          p={5}
          isChecked={cancelFeeAcknowledge}
          onChange={e => {
            // e.preventDefault();
            setCancelFeeAcknowledge(e.target.checked);
          }}
        >
          <Text fontSize="sm" justify="center" ml={1} my={2}>
            Click to acknowledge a fee of
            <span style={{ fontWeight: 'bold' }}>
              {' '}
              $
              {PLANS[selectedIndex]?.cancellation[province]?.priceBeforeTax ||
                0}{' '}
              plus taxes{' '}
            </span>
            if the transaction is cancelled
          </Text>
        </Checkbox>
      </Flex>

      <ContinueButton
        disabled={!cancelFeeAcknowledge}
        onClick={onClickNextStatus}
      >
        CONTINUE
      </ContinueButton>
      {isDev && (
        <>
          <br />
          <br />
          <br />
          <Button
            size="sm"
            variant="ghost"
            color="lightgrey"
            onClick={onClickPreviousStatus}
          >
            DEV Previous Status
          </Button>
          {/* <Button variant="ghost" onClick={onClickCancel}>
            Cancel
          </Button> */}
        </>
      )}
    </div>
  );
}

export default TransactionSelectOptions;
