import { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useToast,
  Text,
  Heading,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  // Alert,
  Alert,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { db, eligibleWarrantyProvinces } from '../constants';
import AppBody from '../components/AppBody';
import Header from '../components/Header';
import VehicleAddonsTabs from '../components/VehicleAddonsTabs';

function VehicleAddons() {
  const { t } = useTranslation('global');
  const [listing, setListing] = useState(false);
  const auth = getAuth();
  const toast = useToast();
  const navigate = useNavigate();
  const params = useParams();
  // Redirect if listing is not user's or if not vehicle
  useEffect(() => {
    // only seller or buyer can access
    if (listing && listing.sellerUid !== auth.currentUser.uid) {
      if (listing?.buyerUid !== auth.currentUser.uid) {
        toast({
          title: t('cannotEditListing'),
          status: 'error',
          isClosable: true,
        });
        navigate('/');
      }
    }
  }, [auth, listing, navigate, toast, t]);

  // Fetch listing to edit
  useEffect(() => {
    const fetchListing = async () => {
      const docRef = doc(db, 'listings', params.listingUid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const thisListing = docSnap.data();

        setListing({ ...thisListing, uid: params.listingUid });
      } else {
        navigate('/');
        toast({
          title: t('listingDoesNotExist'),
          status: 'error',
          isClosable: true,
        });
      }
    };

    fetchListing();
  }, [params, navigate, toast, t]);

  if (!listing) return <Text></Text>;
  let notAvailableWarning;
  if (
    !eligibleWarrantyProvinces.includes(listing?.province.toLowerCase())
  ) {
    // neither eligible province
    notAvailableWarning = (
      <Alert
        status="warning"
        variant="left-accent"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <AlertIcon mr={0} />
        <AlertTitle mt={1} mb={1} fontSize="lg">
          {t('pleaseNote')}
        </AlertTitle>
        <AlertDescription maxWidth="lg">
          {t('forNowPremiumEEZEFeaturesAreOnlyAvailableInONandQC')}
          <br />
          <br />
          {t('thisProvinceListingIsOnlyEligibleForTheFreePlan', { province: listing.province.charAt(0).toUpperCase() + listing.province.slice(1)})}
        </AlertDescription>
      </Alert>
    );
  }

  return (
    <>
      <Header back />
      <AppBody>
        <Heading size="md" mb={0} pl={1}>
          {t('vehicleAddons')}
        </Heading>
        <Heading>{listing?.title}</Heading>
        <Text>{t('vin')}: {listing?.vin}</Text>
        {notAvailableWarning ? notAvailableWarning : <VehicleAddonsTabs />}
      </AppBody>
    </>
  );
}

export default VehicleAddons;
