import {
    getAuth,
} from 'firebase/auth';
import {
    SEND_EMAIL_TO_ADMIN,
} from '../constants';

const sendEmailToAdmin = async (type,customer) => {

    const url = `${SEND_EMAIL_TO_ADMIN}?type=${type}&customer=${customer}`;

    const auth = getAuth();

    try {
        const token = await auth.currentUser.getIdToken()
        const response = await fetch(
            url, {
            method: 'GET',
            withCredentials: true,
            credentials: 'include',
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }
        );

        const data = await response.json();
        return data
    }

    catch (error) {
        // console.log(error)
        return error;
    }
}

export default sendEmailToAdmin