import {
    doc,
    deleteDoc,
    getDoc,
    setDoc,
} from 'firebase/firestore';
import { db } from '../constants';
import deleteShortUrl from '../services/deleteShortUrl';

const deleteListing = async listing => {

    try {
        const answer = window.prompt(
            'Are you sure you want to delete this listing? Type delete to confirm'
        );

        if (answer !== 'delete') return ({
            success: false
        });;
        // user typed in 'delete', continue
        // console.log('ok delete this listing', listing)
        await deleteDoc(doc(db, 'listings', listing.uid));

        // remove uid reference to EEZE ID in db.match
        const docRef = doc(db, 'eezeid', 'match');
        const docSnap = await getDoc(docRef);
        const matchObject = docSnap.data();
        delete matchObject[listing.eezeID];
        await setDoc(docRef, matchObject);

        // also send a server request to delete from rebrandly
        if (listing.shortUrlID) {
            await deleteShortUrl(listing.shortUrlID);
            // const deleteResponse = await deleteShortUrl(listing.shortUrlID);
            // console.log('response from delete', deleteResponse)
        }

        return ({
            success: true,
            deletedListing: listing
        })
    } catch (error) {
        console.log(error);
        return ({
            success: false
        });
    }
};

export default deleteListing;