import {
    getAuth,
} from 'firebase/auth';
import {
    INSPECTION_REQUIRED,
} from '../constants';

const inspectionRequired = async (uid) => {
    const auth = getAuth();
    try {
        const token = await auth.currentUser.getIdToken()
        const response = await fetch(
            INSPECTION_REQUIRED + '?listinguid='+uid, {
            method: 'GET',
            withCredentials: true,
            credentials: 'include',
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }
        );

        const data = await response.json();
        // console.log('carfaxRequired', data)
        return data
    }

    catch (error) {
        console.log(error)
        return error;
    }
}

export default inspectionRequired;
