
function currencyFormatFr(num, decimals = 2) {
    // Convert the number to a string with the specified number of decimal places
    let numStr = num.toFixed(decimals).toString();
    
    // Replace the decimal point with a comma
    numStr = numStr.replace('.', ',');
    
    // Use a regular expression to add spaces every three digits from the end
    let regex = /(\d+)(\d{3})/;
    
    while (regex.test(numStr.split(',')[0])) {
        numStr = numStr.replace(regex, '$1 $2');
    }
    
    // Append space and dollar sign
    numStr += ' $';
    
    return numStr;
 }

 export default currencyFormatFr;