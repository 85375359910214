import { useEffect, useState, useRef } from 'react'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import {
  doc,
  getDoc,
  onSnapshot,
} from 'firebase/firestore'
import { db } from '../constants';

export const useAuthStatus = () => {
  const [userData, setUserData] = useState({})
  const [loggedIn, setLoggedIn] = useState(false)
  const [checkingStatus, setCheckingStatus] = useState(true)
  const isMounted = useRef(true)

  useEffect(() => {
    if (isMounted) {

      const auth = getAuth();

      onAuthStateChanged(auth, async (user) => {
        if (user) {
          // console.log('onAuthStateChanged')
          setLoggedIn(true)
          const docRef = doc(db, "users", auth.currentUser.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            // console.log('user found in db', docSnap.data());
            // console.log('user found user', auth.currentUser);

            setUserData(docSnap.data())
            setLoggedIn(true)
          } else {
            // console.log("first login")
            setLoggedIn(true)
          }

        } else {
          // no user logged in
          setUserData(null)
          setLoggedIn(false)

        }
        setCheckingStatus(false)
      });

      if (loggedIn) {

        onSnapshot(
          doc(db, 'users', auth?.currentUser?.uid), (doc) => {
            // console.log('doc from  onSnapshot', doc.data())
            // setLoading(false)
            setUserData(doc.data())
            // setUser(doc)
          },
          err => {
            // setError(err)
          })

      }

    }

    return () => {
      isMounted.current = false
    }
  }, [isMounted, loggedIn])

  return { loggedIn, checkingStatus, userData }
}

// Protected routes in v6
// https://stackoverflow.com/questions/65505665/protected-route-with-firebase

// Fix memory leak warning using useRef
// https://stackoverflow.com/questions/59780268/cleanup-memory-leaks-on-an-unmounted-component-in-react-hooks
