import {
    getAuth,
} from 'firebase/auth';
import {
    SEND_EMAIL_TO_SELLER,
} from '../constants';

const sendEmailLink = async ({
    buyerName,
    buyerEmail,
    buyerCell,
    buyerLocation,
    listingName,
    shortUrl,
    sellerEmail,
    sellerCell,
    sellerName}) => {

    const url = `${SEND_EMAIL_TO_SELLER}?buyerName=${buyerName}&buyerEmail=${buyerEmail}&buyerCell=${buyerCell}&buyerLocation=${buyerLocation}&listingName=${listingName}&sellerEmail=${sellerEmail}&sellerName=${sellerName}&sellerCell=${sellerCell}&shortUrl=${shortUrl}`;

    const auth = getAuth();

    try {
        const token = await auth.currentUser.getIdToken()
        const response = await fetch(
            url, {
            method: 'GET',
            withCredentials: true,
            credentials: 'include',

            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }
        );

        const data = await response.json();
        // console.log('data from sendEmailLink', data);
        return data
    }

    catch (error) {
        // console.log(error)
        return error;
    }
}

export default sendEmailLink