import {
    getAuth,
} from 'firebase/auth';
import {
    PURCHASE_ADDON,
} from '../constants';

const purchaseAddon = async (code, province, listingUid) => {
    const auth = getAuth();
    try {
        const token = await auth.currentUser.getIdToken()
        const response = await fetch(
            PURCHASE_ADDON + '?code=' + code + '&province=' + province + '&listingUid=' + listingUid, {
            method: 'GET',
            withCredentials: true,
            credentials: 'include',
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }
        );

        const data = await response.json();
        // console.log('data from purchaseAddon', data);
        return data
    }

    catch (error) {
        console.log(error)
        return error;
    }
}

export default purchaseAddon;
