import { Link, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import moment from 'moment';
import {
  Box,
  Card,
  CardBody,
  Center,
  Text,
  Button,
  Image,
  ButtonGroup,
  Stack,
  Heading,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  useDisclosure,
} from '@chakra-ui/react';
import {
  BsChatSquare,
} from 'react-icons/bs';
import { getAuth } from 'firebase/auth';
import { updateDoc, doc } from 'firebase/firestore';

import currencyFormat from '../services/currencyFormat';
import DataContext from '../context/DataContext';
import IDCard from '../components/IDCard';
import { db } from '../constants';

function ListingCard({ listing, id, user, onEdit, onDelete, noTitleLink }) {
  const { userData } = useContext(DataContext);
  const auth = getAuth();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const addToFavorites = () => {
    // get array of favorites from user
    let favoriteListings = {};
    if (userData.favoriteListings) {
      favoriteListings = userData.favoriteListings;
    }

    // check if this listing is already in favorites
    const alreadyAdded = favoriteListings.indexOf(listing.uid) > -1;
    // console.log('arraycontains', arraycontains)

    if (alreadyAdded) {
      onOpen();
    } else {
      // add this listing to that array
      favoriteListings.push(listing.uid);

      // save the array to the db
      const userRef = doc(db, 'users', auth.currentUser.uid);

      updateDoc(userRef, {
        favoriteListings,
      });
      onOpen();
    }
  };

  const contactSeller = () => {
    // if (!isPosted) return;
    navigate('/connect/' + listing.uid + '/' + userData.uid);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Heading mt={5}>Added to Favorites</Heading>
            <Text mt={10} mb={5}>
              Go to Dashboard to View
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              w="full"
              colorScheme="blue"
              onClick={onClose}
            >
              CLOSE
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Card
        direction={{ base: 'column', sm: 'row' }}
        overflow="hidden"
        maxWidth={'500px'}
        borderWidth={1}
        borderColor="darkgrey"
        my={2}
      >
        <Box pos="relative">
          <Image
            src={listing.listingProfilePhoto}
            alt={listing?.uid}
            boxSize="full"
            maxW={{ base: '100%', sm: '200px' }}
            maxH={{ base: '300px', sm: '200px' }}
            objectFit="contain"
          />
          <Text
            pos="absolute"
            bottom="0%"
            left="100%"
            color="primary.800"
            transform="translate(-100%,-0%)"
          >
            {listing.eezeID}
          </Text>
        </Box>

        <CardBody p={3}>
          {noTitleLink ? (
            <Text fontSize="xl" fontWeight={800}>
            {listing?.title}
            </Text>

          ) : (

            <Link to={`/listing/${listing.uid}`}>
            <Text color="blue.500" as="u" fontSize="xl" fontWeight={800}>
            {listing?.title}
            </Text>
            </Link>
            )}
          <Text color={'gray.500'}>{listing.subtitle}</Text>
          <Stack direction="row" justify="space-between">
            <Text fontSize="md" fontWeight={600}>
            {listing.askingPrice && currencyFormat(listing.askingPrice)}
            </Text>
            <Text fontSize="sm" color="gray.800" as="i">
              Posted:{' '}
              {moment(listing.postedAt, 'ddd MMM DD YYYY kk:mm:ss Z ZZ').format(
                'MMM D, YYYY'
              )}
            </Text>
          </Stack>

          <IDCard listing={listing}/>

          {!auth.currentUser ? (
            <>
              <Center>
                <Text as="i" color="primary.800">
                  Login to EEZE to contact the Seller
                </Text>
              </Center>
              <Stack direction="row" w="full">
                <Button
                  flex="1"
                  onClick={() => navigate('/login?uid=' + listing.uid)}
                  colorScheme="primary"
                  variant="outline"
                  size="sm"
                >
                  Login
                </Button>
                <Button
                  flex="1"
                  size="sm"
                  onClick={() => navigate('/signup?uid=' + listing.uid)}
                  colorScheme="primary"
                >
                  Sign Up
                </Button>
              </Stack>
            </>
          ) : (
            <>
              {auth.currentUser.uid !== listing.sellerUid ? (
                <ButtonGroup mt={2} w="100%">
                  <Button
                    // variant="outline"
                    colorScheme="primary"
                    w={'100%'}
                    leftIcon={<BsChatSquare />}
                    onClick={contactSeller}
                  >
                    CONTACT
                  </Button>
                  <Button
                    // variant="ghost"
                    w={'100%'}
                    colorScheme="primary"
                    variant="outline"
                    //  rightIcon={<BsChevronDoubleRight />}
                    onClick={addToFavorites}
                  >
                    Add to Favorites
                  </Button>
                </ButtonGroup>
              ) : (
                <>
                  <br />
                  <Button
                    colorScheme="primary"
                    variant="outline"
                    w={'100%'}
                    onClick={() => navigate(`/edit/${listing.uid}`)}
                  >
                    Edit my Listing
                  </Button>
                  {/* <Button
                    colorScheme="blue"
                    w={'100%'}
                    onClick={() => navigate('/')}
                  >
                    Back to Homepage
                  </Button> */}
                </>
              )}
            </>
          )}
        </CardBody>
      </Card>
    </>
  );
}

export default ListingCard;
