import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Divider,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  Text,
  HStack,
  Center,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  TableContainer,
  Spinner,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  FormControl,
  FormLabel,
  Textarea,
  // useToast,
  useDisclosure,
} from '@chakra-ui/react';
import moment from 'moment';
import { updateDoc, doc } from 'firebase/firestore';
import Iframe from 'react-iframe';

import { BsHouse } from 'react-icons/bs';
import { isDev, LINK, db, BANKING_IFRAME } from '../constants';
import DataContext from '../context/DataContext';
import AppBody from '../components/AppBody';
import Header from '../components/Header';
import FundWalletModal from '../components/FundWalletModal';
import FundWalletCreditModal from '../components/FundWalletCreditModal';
import currencyFormat from '../services/currencyFormat';
import sendEmailToAdmin from '../services/sendEmailToAdmin';
// import simulateEftCompleted from '../services/simulateEftCompleted';
import OrDivider from '../components/OrDivider';
import CreditLogos from '../components/CreditLogos';

function Wallet() {
  const dataContext = useContext(DataContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [fetchingBankingIframe, setFetchingBankingIframe] = useState(false);
  const [errorComments, setErrorComments] = useState('');
  const {
    isOpen: isOpenCredit,
    onOpen: onOpenCredit,
    onClose: onCloseCredit,
  } = useDisclosure();
  const navigate = useNavigate();
  // const toast = useToast();

  const { userData } = dataContext;
  const {
    bankAccountIframe,
    bankAccountError,
    bankAccountErrorComments,
    bankAccountAdded,
    walletBalance,
    walletTransactions,
    walletIncoming,
    stageTwoVerified,
    uid,
  } = userData;

  const userRef = doc(db, 'users', uid);
  useEffect(() => {
    const listener = e => {
      // zumrails listener
      if (e?.data?.step) {
        if (e?.data?.step === 'CONNECTIONSUCCESSFULLYCOMPLETED') {
          // save e.data.data.userid
            updateDoc(userRef, {
              bankAccountIframe: '',
              bankAccountAdded: true,
              bankSkipped: false,
              bankAccountAddedAt: new Date().toString(),
              bank: {
                cardid: e.data?.data?.cardid,
                requestid: e.data?.data?.requestid,
                userid: e.data?.data?.userid || 'pending',
              },
            });
        }
      }
    };

    if (window.addEventListener) {
      window.addEventListener('message', listener, false);
    } else {
      window.attachEvent('onmessage', listener);
    }
    return () => {
      window.removeEventListener('message', listener);
    };
  }, [userRef]);

  const generateBankingIframe = async () => {
    setFetchingBankingIframe(true);

    // update database
    await updateDoc(userRef, {
      // bankAccountIframe: response.data.iframeurl,
      bankAccountIframe: BANKING_IFRAME + '&clientuserid=' + uid,
    });
    setFetchingBankingIframe(false);
  };

  const sendErrorComments = e => {
    // pull id from button
    updateDoc(userRef, {
      [e.target.id]: errorComments,
    });

    // send notice to admin
    sendEmailToAdmin(
      'verificationError',
      `${userData.firstName} ${userData.lastName} - ${userData?.uid}`
    );
  };

  const renderTransaction = transaction => {
    return (
      <Tr key={transaction.key}>
        <Td px={0} py={2}>
          {moment(
            transaction.createdAt,
            'ddd MMM DD YYYY kk:mm:ss Z ZZ'
          ).format('MMM D, YYYY')}
        </Td>
        <Td px={0} py={2} textAlign="right">
          {currencyFormat(transaction.amount)}
        </Td>
        {!transaction.toName ? (
          <Td px={0} py={2}>
            {transaction.completedAt ? (
              <Badge ml={2} colorScheme="blue">
                COMPLETED
              </Badge>
            ) : (
              <>
                {transaction.failedAt ? (
                  <Badge ml={2} colorScheme="red">
                    FAILED
                  </Badge>
                ) : (
                  <Badge ml={2}>PROCESSING</Badge>
                )}
              </>
            )}
            {transaction.amountBeforeFees && (
              <Text as="i" fontSize="xs" ml={1}>
                {currencyFormat(transaction.amountBeforeFees)} on credit
              </Text>
            )}
          </Td>
        ) : (
          <Td pl={2} py={2}>
            To: {transaction.toName}
          </Td>
        )}
      </Tr>
    );
  };
  const renderTransactions = () => {
    if (!walletTransactions) return null;

    if (walletTransactions === null || walletTransactions === undefined) {
      return null;
    } 

    if (
      typeof walletTransactions === 'object' &&
      Object.keys(walletTransactions).length === 0
    ) {
      return null;
    }

    const inputObject = {
      ...walletTransactions,
    };

    const resultArray = [];

    for (const key in inputObject) {
      if (inputObject.hasOwnProperty(key)) {
        const newObj = { key: key, ...inputObject[key] };
        resultArray.push(newObj);
      }
    }

    // Sort the array by the key field, which is timestamp
    resultArray.sort((a, b) => a.key - b.key);
    // console.log('resultArray', resultArray);

    return (
      <>
        <Heading size="md" my={2}>
          EEZE Wallet Transactions
        </Heading>

        <TableContainer>
          <Table size="md">
            <Thead>
              <Tr>
                <Th py={2} px={1}>
                  Date
                </Th>
                <Th py={2} px={1} textAlign="right">
                  Amount
                </Th>
              </Tr>
            </Thead>

            <Tbody>{resultArray.map(renderTransaction)}</Tbody>
          </Table>
        </TableContainer>
      </>
    );
  };

  const renderAddBanking = () => {
    return (
      <>
        <Divider borderColor="primary.500" borderWidth={1} my={5} />

        {!bankAccountIframe && (
          <>
            <HStack justify="space-between">
              <Heading as="h3" size="lg" mb={5}>
                Connect your bank
              </Heading>
            </HStack>
            <Text color="primary.500" mb={5}>
              Log into your online bank to set up your EEZE digital wallet
            </Text>
            <Text mb={5}>
              A balance in your EEZE wallet allows for fast and secure payments
              to and from other users
            </Text>
            <Text>
              Completing this verification step unlocks the full suite of EEZE
              products and plans
            </Text>
          </>
        )}

        <>
          {!bankAccountIframe ? (
            <>
              {!fetchingBankingIframe ? (
                <>
                  <br />
                  <Button
                    onClick={generateBankingIframe}
                    colorScheme="primary"
                    w="full"
                    // variant="outline"
                  >
                    CONNECT
                  </Button>
                </>
              ) : (
                <>
                  <br />
                  <Center>
                    <br />
                    <Spinner />
                  </Center>
                </>
              )}
            </>
          ) : (
            <>
              {!bankAccountError ? (
                <>
                  <Iframe url={bankAccountIframe} width="100%" height="800px" />
                </>
              ) : (
                <>
                  <Alert
                    status="error"
                    variant="subtle"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                    height="200px"
                  >
                    <AlertIcon boxSize="40px" />
                    <AlertTitle mt={4} mb={1} fontSize="lg">
                      Could not process
                    </AlertTitle>
                    <AlertDescription maxWidth="sm">
                      {bankAccountError}
                    </AlertDescription>
                  </Alert>
                  <FormControl mt="2%">
                    <FormLabel m={0}>
                      Send us your comments and we will contact you to reset and
                      try again
                    </FormLabel>
                    <Textarea
                      placeholder="Add comments..."
                      rows={3}
                      id="bankAccountErrorComments"
                      value={errorComments}
                      onChange={setErrorComments}
                      // disabled={bankAccountErrorComments}
                    />
                  </FormControl>
                  {!bankAccountErrorComments ? (
                    <Button
                      bgColor="black"
                      color="white"
                      id="bankAccountErrorComments"
                      onClick={sendErrorComments}
                    >
                      Send Comments
                    </Button>
                  ) : (
                    <Center>
                      <Text as="i" color="red">
                        Thank you, we will investigate and reach out
                      </Text>
                    </Center>
                  )}
                </>
              )}
            </>
          )}
        </>
      </>
    );
  };

  if (!stageTwoVerified) {
    return (
      <>
        <Header back />
        <AppBody>
          <Text as="b" fontSize="xl">
            Wallet
          </Text>
          <br />
          <br />
          <Alert
            status="warning"
            variant="left-accent"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <AlertIcon mr={0} />
            <AlertTitle mt={1} mb={1} fontSize="lg">
              Please wait
            </AlertTitle>
            <AlertDescription maxWidth="lg">
              The EEZE Wallet feature will be available once you are fully verified
            </AlertDescription>
          </Alert>

          <Button
            w="full"
            // m="2"
            // variant='outline'
            mt={5}
            colorScheme="primary"
            onClick={() => navigate('/')}
            leftIcon={<BsHouse />}
          >
            Dashboard
          </Button>
        </AppBody>
      </>
    );
  }
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <FundWalletModal />
          <ModalFooter>
            <Button
              w="full"
              variant="ghost"
              colorScheme="primary"
              onClick={onClose}
            >
              CLOSE
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpenCredit} onClose={onCloseCredit}>
        <ModalOverlay />
        <ModalContent>
          <FundWalletCreditModal />
          <ModalFooter>
            <Button
              w="full"
              variant="ghost"
              colorScheme="primary"
              onClick={onCloseCredit}
            >
              CLOSE
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Header back />
      <AppBody>
        <HStack
          // justify="space-between"
          mt={5}
        >
          <Text as="b" fontSize="xl" flex={1}>
            Wallet Balance
          </Text>
          <Text as="b" fontSize="xl" flex={1} textAlign="right">
            {currencyFormat(Number(walletBalance || 0))}
          </Text>
        </HStack>
        {!bankAccountAdded ? (
          renderAddBanking()
        ) : (
          <>
            {Number(walletIncoming || 0) > 0 && (
              <HStack
                // justify="space-between"
                my={2}
              >
                <Text
                  flex={1}
                  // as="i"
                  // fontSize='sm'
                >
                  Incoming Amount
                </Text>
                <Text
                  flex={1}
                  // fontSize='sm'
                  textAlign="right"
                  // as="i"
                >
                  {currencyFormat(walletIncoming || 0)}
                </Text>
              </HStack>
            )}
            {/* <Center mt={2}> */}
            <Text fontSize="lg" textAlign="center" mt={2}>
              Fund Wallet
            </Text>
            {/* </Center> */}
            <Button
              variant="solid"
              w="full"
              mt={2}
              // mb={2}
              onClick={onOpen}
              colorScheme={'primary'}
            >
              From Bank Account
            </Button>
            <OrDivider my={2} />
            <Button
              variant="solid"
              w="full"
              // mt={2}
              mb={2}
              onClick={onOpenCredit}
              colorScheme={'primary'}
            >
              From Credit Card or Installment Plan
            </Button>
            <CreditLogos />
            <Divider borderColor="primary.500" borderWidth={1} my={5} />
            <Center my={2} align="center">
              <Text as="i">
                A balance in your EEZE wallet allows for fast and secure
                payments to sellers.{' '}
                <a
                  href={LINK.contactUrl}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: 'underline' }}
                >
                  Contact us
                </a>{' '}
                to transfer back to your source bank anytime
              </Text>
            </Center>

            <Divider borderColor="primary.500" borderWidth={1} my={5} />

            {renderTransactions()}
          </>
        )}
        {isDev && (
          <>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </>
        )}
      </AppBody>
    </>
  );
}

export default Wallet;
