import { useState, useEffect, useContext } from 'react';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  // Divider,
  useToast,
  Heading,
  Text,
  Stack,
  Center,
} from '@chakra-ui/react';
import { BsChevronLeft } from 'react-icons/bs';

import { db, LISTING } from '../constants';
import SpinnerPage from '../components/SpinnerPage';
import DataContext from '../context/DataContext';
import AppBody from '../components/AppBody';
import Header from '../components/Header';
import TransactionAccepted from '../components/TransactionAccepted';
import TransactionBuyerFunding from '../components/TransactionBuyerFunding';
import TransactionBuyerSigning from '../components/TransactionBuyerSigning';
import TransactionBuyerSigned from '../components/TransactionBuyerSigned';
import TransactionCancelled from '../components/TransactionCancelled';
import TransactionCompleted from '../components/TransactionCompleted';
import TransactionDisplayOptions from '../components/TransactionDisplayOptions';
import TransactionError from '../components/TransactionError';
import TransactionSelectOptions from '../components/TransactionSelectOptions';

function Transaction() {
  const [listing, setListing] = useState(null);
  const [isBuyer, setIsBuyer] = useState(null);
  const [isSeller, setIsSeller] = useState(null);
  const [sellerUserData, setSellerUserData] = useState([]);
  const [buyerUserData, setBuyerUserData] = useState([]);

  const toast = useToast();
  const navigate = useNavigate();
  const params = useParams();
  const { userData } = useContext(DataContext);

  useEffect(() => {
    // get and listen to this document
    const unsubscribe = onSnapshot(
      doc(db, 'listings', params.listingUid),
      doc => {
        if (!doc.exists()) {
          navigate('/');
          toast({
            title: 'Listing does not exist',
            status: 'error',
            isClosable: true,
          });
          return;
        }
        // set to state
        setListing(doc.data());
      }
    );
    return unsubscribe;
  }, [navigate, toast, params]);

  useEffect(() => {
    if (!listing) return;
    if (!listing.buyerUid) return;
    // get and listen to the buyer object
    // for updates to banking of address or ...
    const unsubscribe = onSnapshot(doc(db, 'users', listing.buyerUid), doc => {
      // set to state
      setBuyerUserData(doc.data());
    });
    return unsubscribe;
  }, [listing]);

  useEffect(() => {
    if (!listing) return;
    // get and listen to the seller object
    // for updates to banking of address or ...
    const unsubscribe = onSnapshot(doc(db, 'users', listing.sellerUid), doc => {
      // set to state
      setSellerUserData(doc.data());
    });
    return unsubscribe;
  }, [listing]);

  useEffect(() => {
    // if (!listing.buyerUid) return;
    // get buyer and seller userData
    const getBuyerData = async () => {
      if (!listing?.buyerUid) return navigate('/');
      const docRef = doc(db, 'users', listing?.buyerUid);
      const docSnap = await getDoc(docRef);
      setBuyerUserData(docSnap.data());
    };
    const getSellerData = async () => {
      const docRef = doc(db, 'users', listing.sellerUid);
      const docSnap = await getDoc(docRef);
      setSellerUserData(docSnap.data());
    };
    if (!userData) return; //navigate('/');
    if (!listing) return; //navigate('/');
    if (userData.uid === listing.sellerUid) {
      // current user is the seller
      setSellerUserData(userData);
      setIsSeller(true);
      setIsBuyer(false);
      getBuyerData();
    }
    if (userData.uid === listing.buyerUid) {
      // current user is the buyer
      setBuyerUserData(userData);
      setIsSeller(false);
      setIsBuyer(true);
      getSellerData();
    }
  }, [listing, userData, navigate]);

  const renderTransactionComponent = () => {
    let transactionComponent;

    switch (listing.status) {
      case LISTING.STATUS.DRAFT:
        transactionComponent = (
          <div>Status draft, not eligible for transaction</div>
        );
        break;
      case LISTING.STATUS.POSTED:
        transactionComponent = (
          <div>Status posted, not eligible for transaction</div>
        );
        break;
      case LISTING.STATUS.ACCEPTED:
        transactionComponent = (
          <TransactionAccepted
            listing={listing}
            isBuyer={isBuyer}
            isSeller={isSeller}
            buyerUserData={buyerUserData}
            sellerUserData={sellerUserData}
            userData={userData}
          />
        );
        break;
      case LISTING.STATUS.ERROR:
        transactionComponent = (
          <TransactionError
            listing={listing}
            isBuyer={isBuyer}
            isSeller={isSeller}
            buyerUserData={buyerUserData}
            sellerUserData={sellerUserData}
            userData={userData}
          />
        );
        break;
      case LISTING.STATUS.SELECT_OPTIONS:
        transactionComponent = (
          <TransactionSelectOptions
            listing={listing}
            isBuyer={isBuyer}
            isSeller={isSeller}
            buyerUserData={buyerUserData}
            sellerUserData={sellerUserData}
            userData={userData}
          />
        );
        break;
      case LISTING.STATUS.DISPLAY_OPTIONS:
        transactionComponent = (
          <TransactionDisplayOptions
            listing={listing}
            isBuyer={isBuyer}
            isSeller={isSeller}
            buyerUserData={buyerUserData}
            sellerUserData={sellerUserData}
            userData={userData}
          />
        );
        break;
      case LISTING.STATUS.BUYER_FUNDING:
        transactionComponent = (
          <TransactionBuyerFunding
            listing={listing}
            isBuyer={isBuyer}
            isSeller={isSeller}
            buyerUserData={buyerUserData}
            sellerUserData={sellerUserData}
            userData={userData}
          />
        );
        break;
      case LISTING.STATUS.BUYER_SIGNING:
        transactionComponent = (
          <TransactionBuyerSigning
            listing={listing}
            isBuyer={isBuyer}
            isSeller={isSeller}
            buyerUserData={buyerUserData}
            sellerUserData={sellerUserData}
            userData={userData}
          />
        );
        break;
      case LISTING.STATUS.BUYER_SIGNED:
        transactionComponent = (
          <TransactionBuyerSigned
            listing={listing}
            isBuyer={isBuyer}
            isSeller={isSeller}
            buyerUserData={buyerUserData}
            sellerUserData={sellerUserData}
            userData={userData}
          />
        );
        break;
      case LISTING.STATUS.COMPLETED:
        transactionComponent = (
          <TransactionCompleted
            listing={listing}
            isBuyer={isBuyer}
            isSeller={isSeller}
            buyerUserData={buyerUserData}
            sellerUserData={sellerUserData}
            userData={userData}
          />
        );
        break;
      case LISTING.STATUS.CANCELLED:
        transactionComponent = (
          <TransactionCancelled
            listing={listing}
            isBuyer={isBuyer}
            isSeller={isSeller}
            buyerUserData={buyerUserData}
            sellerUserData={sellerUserData}
            userData={userData}
          />
        );
        break;
      default:
        // transactionComponent = null;
        transactionComponent = <p>{listing.status}</p>;
        break;
    }

    return transactionComponent;
  };

  if (!listing) {
    return <SpinnerPage />;
  }
  if (!sellerUserData) {
    return <SpinnerPage />;
  }
  if (!buyerUserData) {
    return <SpinnerPage />;
  }
  // console.log('sellerUserData', sellerUserData);
  // console.log('buyerUserData', buyerUserData);

  if (userData?.uid === listing.sellerUid || userData?.uid === listing.buyerUid) {
    return (
      <>
        <AppBody>
          <Stack direction="column">
            <Button
              onClick={() => navigate('/')}
              w={'25%'}
              justifyContent="flex-start"
              pl={0}
              variant="ghost"
              // variant="solid"
              // colorScheme="blue"
              leftIcon={<BsChevronLeft />}
            >
              <Text m={0} borderBottom='2px' borderBottomColor='primary.500'>
                EEZE
              </Text>
            </Button>
            {listing.buyerUid ? (
              <>{renderTransactionComponent()}</>
            ) : (
              <div>
                <Center textAlign="center">
                  <Heading py={15}>
                    No Final Buyer Selected For This Listing
                  </Heading>
                </Center>
                <Button
                  onClick={() => navigate('/')}
                  w={'full'}
                  my={20}
                  colorScheme="blue"
                >
                  To Dashboard
                </Button>
              </div>
            )}
          </Stack>
        </AppBody>
      </>
    );
  } else {
    return (
      <>
        <Header />
        <AppBody>
          <Stack direction="column">
            <br />
            <Heading>No Access</Heading>
            <Button
              onClick={() => navigate('/')}
              w={'full'}
              colorScheme="primary"
            >
              To Homepage
            </Button>
          </Stack>
        </AppBody>
      </>
    );
  }
}

export default Transaction;
