import {
  Card,
  Text,
  Heading,
  Stack,
  Skeleton,
} from '@chakra-ui/react';

function ListingCardLoading() {
  return (
    <>
      <Card
        p={2}
        my={2}
        mx={1}
        mt={0}
        shadow="lg"
        borderWidth={1}
        borderColor="darkgrey"
      >
        <Heading size="md" pt={'2%'}>
          <Skeleton>heading</Skeleton>
        </Heading>

        <Stack direction="row" py={1}>

          <Skeleton w="50%" >
            <Text>1</Text>
            <Text>2</Text>
            <Text>3</Text>
            <Text>3</Text>
          </Skeleton>
          <Skeleton w="50%" >
            <Text>1</Text>
            <Text>2</Text>
            <Text>3</Text>
            <Text>3</Text>
          </Skeleton>
        </Stack>
        <Stack direction="row" py={1}>

        <Skeleton w="50%" >
            <Text>1</Text>
          </Skeleton>
          <Skeleton w="50%" >
            <Text>1</Text>
          </Skeleton>
        </Stack>
      </Card>
    </>
  );
}

export default ListingCardLoading;
