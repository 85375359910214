// import { useState, useEffect, useContext } from 'react';

import {
  TabList,
  Tabs,
  Tab,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react';
import WarrantyPage from './WarrantyPage';
import CarfaxPage from './CarfaxPage';

function VehicleAddonsTabs() {

  return (
    <>
      <Tabs isFitted variant="enclosed-colored">
        <TabList mb="1em">
          <Tab>Warranty</Tab>
          <Tab>CARFAX</Tab>
        </TabList>
        <TabPanels p={0} m={0}>
          <TabPanel p={0} m={0}>
            <WarrantyPage />
          </TabPanel>
          <TabPanel p={0} m={0}>
            <CarfaxPage />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
}

export default VehicleAddonsTabs;
