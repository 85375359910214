import AppBody from './AppBody';
import { Progress, Center, Image } from '@chakra-ui/react';

import logoImage from '../assets/signInImage-sm.png';

function SpinnerEEZEPage() {
  return (
    <AppBody>
      <Center mt={'30%'} 
      >
        <Image
          src={logoImage}
          alt="Logo"
          width="55%"
          maxW={'350px'}
          objectFit="cover"
        />
      </Center>
      <br/>
      <br/>
      <Center mt={10}>Loading...</Center>
      <Progress size="md" isIndeterminate colorScheme="primary" />
    </AppBody>
  );
}

export default SpinnerEEZEPage;
