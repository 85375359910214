import { Stack, Divider, Text, Box } from '@chakra-ui/react';

export default function ListingWarrantyOptions() {
  return (
    <>
      <Divider borderColor="primary.500" borderWidth={1} />
      <Text align="center">Extended EEZE Warranty Options</Text>

      <Stack direction="row" justify="space-between" py={3} bgColor="gray.100">

        <Box flex={1} align="center" p={2} borderRight="1px" borderColor='primary.500'>
          <Text fontWeight="bold" mb={3}>
            Powertrain
          </Text>
          <Text fontSize="sm">1 Year</Text>
          <Text fontSize="sm">$0 Deductible</Text>
          <Text fontSize="sm">20,000 km/year</Text>
          <Text fontSize="sm">Differentials</Text>
          <Text fontSize="sm">Engine + Transmission</Text>
        </Box>

        <Box flex={1} align="center" p={2}>
          <Text fontWeight="bold" mb={3}>
            Comprehensive
          </Text>
          <Text fontSize="sm">1, 2 or 3 Year</Text>
          <Text fontSize="sm">Powertrain plus</Text>
          <Text fontSize="sm">Steering + Brakes</Text>
          <Text fontSize="sm">Cooling, A/C + Heating</Text>
          <Text fontSize="sm">Electronic Parts</Text>
          <Text fontSize="sm">Roadside Assistance</Text>
          <Text fontSize="sm">and more...</Text>

        </Box>
      </Stack>
    </>
  );
}
