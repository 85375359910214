import {
  Text,
  Center,
  Divider,
  Avatar,
  Progress,
  Heading,
  AlertDescription,
  AlertTitle,
  AlertIcon,
  Alert,
  Button,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';

import currencyFormat from '../services/currencyFormat';

function TransactionSellerPendingScreen({
  buyerUserData,
  listing,
  freePlanSelected,
}) {
  return (
    <div>
      <Center>
        <Heading as="h1" fontSize="xl">
          {listing.title}
        </Heading>
      </Center>
      <Divider borderColor="primary.500" borderWidth={1} my={2} />

      {!listing.vehicleDigitalConditionReport &&
        listing.vehicleDigitalConditionReportPending && (
          <>
            <Alert
              my={5}
              status="warning"
              variant="left-accent"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
            >
              <AlertIcon mr={0} />
              <AlertDescription maxWidth="lg">
                A full EEZE digital inspection has been requested on this
                vehicle. Please take a few quick pictures of your vehicle on
                your mobile device by clicking this link:
              </AlertDescription>
              <Center my={5}>
                <Button
                  color="white"
                  bg="black"
                  rightIcon={<ExternalLinkIcon />}
                  disabled={!listing.vehicleDigitalConditionReportCaptureUrl}
                  onClick={() =>
                    window.open(
                      listing.vehicleDigitalConditionReportCaptureUrl,
                      '_blank'
                    )
                  }
                >
                  Click here to perform inspection
                </Button>
              </Center>
            </Alert>
          </>
        )}

      <Center align="center">
        <Text>
          Please wait for the buyer to complete their portion which includes
          selecting EEZE upgrades, reviewing VIN Search, funding their EEZE
          wallet and signing the sale contract
        </Text>
      </Center>

      <Center mt={8}>
        <Text color={'primary.800'} fontWeight={'500'}>
          BUYER
        </Text>
      </Center>
      <Center>
        <Avatar src={buyerUserData.photoURL} size="xl" />
      </Center>
      <Center>
        <Text fontSize="lg">
          {buyerUserData.firstName} {buyerUserData.lastName}
        </Text>
      </Center>

      <Center>
        <Text fontWeight="bold">EEZE Wallet Balance</Text>
      </Center>
      <Center>
        <Text fontWeight="bold" fontSize="xl">
          {currencyFormat(Number(buyerUserData.walletBalance || 0))}
        </Text>
      </Center>

      <Progress size="xs" isIndeterminate colorScheme="primary" my={8} />
      {!freePlanSelected ? (
        <>
          <Center align="center">
            <Text>
              They must have at least{' '}
              {currencyFormat(Number(listing.finalPrice))} plus the cost of the
              EEZE plan in their wallet before its your turn to sign. Some
              funding transactions may take up to 3 business days to complete
            </Text>
          </Center>
          <Center align="center" mt={4}>
            <Text>
              The final price will be transferred to you instantly once the
              contract is signed by both parties
            </Text>
          </Center>
        </>
      ) : (
        <>
          <Alert
            status="warning"
            variant="left-accent"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <AlertIcon mr={0} />
            <AlertTitle mt={1} mb={1} fontSize="lg">
              Free Plan Selected
            </AlertTitle>
            <AlertDescription maxWidth="lg">
              The buyer has selected the Free EEZE plan, you must collect
              payment of {currencyFormat(Number(listing.finalPrice))} directly
              from the seller
            </AlertDescription>
          </Alert>
        </>
      )}
    </div>
  );
}

export default TransactionSellerPendingScreen;
