import {
    getAuth,
} from 'firebase/auth';
import {
    CARFAX_REQUIRED,
} from '../constants';

const carfaxRequired = async () => {
    const auth = getAuth();
    try {
        const token = await auth.currentUser.getIdToken()
        const response = await fetch(
            CARFAX_REQUIRED, {
            method: 'GET',
            withCredentials: true,
            credentials: 'include',
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }
        );

        const data = await response.json();
        // console.log('carfaxRequired', data)
        return data
    }

    catch (error) {
        console.log(error)
        return error;
    }
}

export default carfaxRequired;
