import { useState, useEffect, useContext } from 'react';
import { getAuth } from 'firebase/auth';
import { doc, updateDoc, onSnapshot, getDoc } from 'firebase/firestore';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Avatar,
  AlertDescription,
  AlertTitle,
  AlertIcon,
  Alert,
  Button,
  Box,
  IconButton,
  Flex,
  Input,
  InputLeftElement,
  InputGroup,
  Center,
  Textarea,
  Text,
  useToast,
  CardBody,
  Card,
  Heading,
  Stack,
} from '@chakra-ui/react';
import { BsChevronLeft } from 'react-icons/bs';
import { MdSend } from 'react-icons/md';

import { db, LISTING } from '../constants';
import SpinnerPage from '../components/SpinnerPage';
import DataContext from '../context/DataContext';
import AppBody from '../components/AppBody';
import Header from '../components/Header';
import OrDivider from '../components/OrDivider';
import IDCard from '../components/IDCard';
import sendEmailToSeller from '../services/sendEmailToSeller';
import currencyFormat from '../services/currencyFormat';

function Connect() {
  const [listing, setListing] = useState(null);
  const [buyerData, setBuyerData] = useState(null);
  const [sellerData, setSellerData] = useState(null);
  const [message, setMessage] = useState('');
  const [offer, setOffer] = useState('');
  const [posting, setPosting] = useState(false);

  const auth = getAuth();
  const toast = useToast();
  const navigate = useNavigate();
  const params = useParams();
  const { userData } = useContext(DataContext);

  useEffect(() => {
    // get and listen to this document
    const unsubscribe = onSnapshot(
      doc(db, 'listings', params.listingUid),
      doc => {
        if (!doc.exists()) {
          navigate('/');
          toast({
            title: 'Listing does not exist',
            status: 'error',
            isClosable: true,
          });
          return;
        }
        // set to state
        setListing(doc.data());
      }
    );
    return unsubscribe;
  }, [navigate, params, toast]);

  useEffect(() => {
    const getData = async () => {
      if (listing) {
        // get seller data
        const sellerRef = doc(db, 'users', listing?.sellerUid);
        const sellerSnap = await getDoc(sellerRef);
        setSellerData(sellerSnap.data());
      }
      // get buyer data
      const buyerRef = doc(db, 'users', params?.buyerUid);
      const buyerSnap = await getDoc(buyerRef);
      setBuyerData(buyerSnap.data());
    };

    getData();
  }, [listing, params]);
  // console.log('buyerObject', buyerObject)
  const isSeller = listing?.sellerUid === auth.currentUser?.uid;

  const isBuyer = params?.buyerUid === auth.currentUser?.uid;

  // let lastMessageFromBuyer = false;
  // if (listing?.potentialBuyers && listing?.potentialBuyers[params.buyerUid]) {
  //   if (listing?.potentialBuyers[params.buyerUid]?.messages) {
  //     const last =
  //         listing.potentialBuyers[params.buyerUid].messages.length - 1
  //     if (listing.potentialBuyers[params.buyerUid].messages[last].fromBuyer) {
  //       lastMessageFromBuyer = true;
  //     }
  //   }
  // }

  const postSellerMessage = async () => {
    setPosting(true);
    // get potentialBuyer object for this buyer

    let thisBuyerObj = listing?.potentialBuyers?.[params.buyerUid];
    // console.log('thisBuyerObj', thisBuyerObj)
    if (thisBuyerObj) {
      // conversation exists for this buyer
      // add to the message array
      thisBuyerObj.messages.push({
        fromBuyer: false,
        date: new Date().toString(),
        message: message ? message : null,
        offer: offer ? currencyFormat(offer) : null,
      });
    }
    const thisListingRef = doc(db, 'listings/', params.listingUid);

    // save or overwrite the last offer from buyer or seller
    if (offer) {
      thisBuyerObj.lastPrice = offer;
    }

    const newPotentialBuyersObj = {
      ...listing?.potentialBuyers,
      [params.buyerUid]: thisBuyerObj,
    };

    await updateDoc(thisListingRef, {
      potentialBuyers: newPotentialBuyersObj,
    });

    setMessage('');
    setOffer('');
    setPosting(false);
  };

  const selectAsFinalBuyer = async () => {
    // get potentialBuyer object for this buyer

    let thisBuyerObj = listing?.potentialBuyers?.[params.buyerUid];
    // console.log('thisBuyerObj', thisBuyerObj)
    // if (thisBuyerObj) {
    // conversation exists for this buyer
    // add to the message array

    const last = thisBuyerObj?.lastPrice
      ? ` The last price discussed was ${listing.currency} ${currencyFormat(
          thisBuyerObj.lastPrice
        )}.`
      : '';
    if (
      !window.confirm('Confirm as the final buyer?' + last + ' Are you sure?')
    ) {
      return;
    }
    setPosting(true);

    thisBuyerObj.messages.push({
      isFinalBuyer: true,
      fromBuyer: false,
      date: new Date().toString(),
      // message: `Congratulations, you've been selected as the final buyer. Click Go To Transaction below to view details`,
      message: `${userData.firstName} ${userData.lastName} has selected ${
        listing?.potentialBuyers[params.buyerUid].buyerName
      } as the final buyer.${last} Click Go To Transaction below to conclude details`,
    });
    // }
    thisBuyerObj.isFinalBuyer = true;
    const thisListingRef = doc(db, 'listings/', params.listingUid);

    const newPotentialBuyersObj = {
      ...listing.potentialBuyers,
      [params.buyerUid]: thisBuyerObj,
    };

    await updateDoc(thisListingRef, {
      buyerUid: params.buyerUid,
      status: LISTING.STATUS.ACCEPTED,
      finalPrice: thisBuyerObj?.lastPrice || listing.askingPrice,
      potentialBuyers: newPotentialBuyersObj,
    });

    setPosting(false);
  };

  const postBuyerMessage = async acceptOffer => {
    setPosting(true);
    // get potentialBuyer object for this buyer

    let thisBuyerObj = listing?.potentialBuyers?.[params.buyerUid];
    // console.log('postBuyerMessage thisBuyerObj', thisBuyerObj)
    if (thisBuyerObj) {
      // conversation exists for this buyer
      // add to the message array
      // if user clicked accept offer, populate the message
      let mess = message || '';

      if (acceptOffer === 'counter') {
        mess = `${
          userData.firstName + ' ' + userData.lastName
        } accepts the counter-offer ${message}`;
      }

      if (acceptOffer === 'asking') {
        mess = `${
          userData.firstName + ' ' + userData.lastName
        } has offered to buy at ${listing.currency}
        ${currencyFormat(listing.askingPrice)} ${message}`;
      }

      thisBuyerObj.messages.push({
        fromBuyer: true,
        date: new Date().toString(),
        message: mess,
        // message: message ? message : null,
        offer: offer ? currencyFormat(offer) : null,
      });
    } else {
      // first conversation
      // check if user clicked accept asking price
      let msg = message || '';
      if (acceptOffer === 'asking') {
        msg = `${
          userData.firstName + ' ' + userData.lastName
        } has offered to buy at ${listing.currency}
        ${currencyFormat(listing.askingPrice)} ${message}`;
      }

      thisBuyerObj = {
        messages: [
          // details of the chat
          {
            fromBuyer: true,
            date: new Date().toString(),
            message: msg,
            offer: offer ? currencyFormat(offer) : null,
          },
        ],
        buyerUid: userData.uid,
        isFinalBuyer: false, // if true elevate to the top
        buyerName: userData.firstName + ' ' + userData.lastName,
        buyerPhotoURL: userData.photoURL,
        buyerEmail: userData.email,
        buyerCell: userData.cell,
        buyerLocation: userData?.address?.city || '',
        sellerEmail: listing.sellerEmail || '',
        listingName: listing.title,
      };

      // notify seller that a new buyer is interested
      // tried this with database triggers in server but not working
      // some emails have + character, replace with %2b
      sendEmailToSeller({
        buyerName: userData.firstName + ' ' + userData.lastName,
        buyerEmail: userData.email.replace('+', '%2b'),
        buyerCell: userData.cell,
        buyerLocation: userData?.address?.city || '',
        listingName: listing.title,
        shortUrl: listing.shortUrl,
        sellerEmail: listing?.sellerEmail
          ? listing?.sellerEmail.replace('+', '%2b')
          : '',
        sellerName: listing.sellerName,
        sellerCell: listing.sellerCell,
      });
    }

    // save this message object to listing.potentiaBuers
    const thisListingRef = doc(db, 'listings/', params.listingUid);

    // save or overwrite the last offer from buyer or seller
    if (offer) {
      thisBuyerObj.lastPrice = offer;
    }

    // last price could be asking if buyer clicked accept offer
    if (acceptOffer === 'asking') {
      thisBuyerObj.lastPrice = listing.askingPrice;
    }
    const newPotentialBuyersObj = {
      ...listing.potentialBuyers,
      [params.buyerUid]: thisBuyerObj,
    };
    // console.log('newPotentialBuyersObj', newPotentialBuyersObj)
    await updateDoc(thisListingRef, {
      potentialBuyers: newPotentialBuyersObj,
    });

    setMessage('');
    setOffer('');
    setPosting(false);

    // make sure this listing is added to the buyer's favorites
    const { favoriteListings } = userData;

    const alreadyAdded = favoriteListings.indexOf(params.listingUid) > -1;

    if (!alreadyAdded) {
      favoriteListings.push(params.listingUid);
      const userRef = doc(db, 'users', auth.currentUser.uid);

      updateDoc(userRef, {
        favoriteListings,
      });
    }
  };

  const onClickAcceptCounterOffer = () => {
    if (
      !window.confirm(
        'Accept counter-offer, are you sure? Seller will then need to confirm'
      )
    ) {
      return;
    }
    postBuyerMessage('counter');
  };
  const onClickAcceptAskingPrice = () => {
    if (
      !window.confirm(
        'You would like to buy at asking price, are you sure? Seller will then need to confirm'
      )
    ) {
      return;
    }
    postBuyerMessage('asking');
  };
  const renderAcceptOffer = () => {
    return (
      <>
        {/* {listing?.potentialBuyers && ( */}
        <>
          {/* {!listing?.potentialBuyers[params.buyerUid]?.lastPrice && ( */}
          <>
            <OrDivider />
            <Button colorScheme="blue" onClick={onClickAcceptAskingPrice}>
              Buy Now at {listing.currency}{' '}
              {currencyFormat(listing.askingPrice)}
            </Button>
          </>
          {/* )} */}
        </>
        {/* )} */}
      </>
    );
  };

  const renderAcceptCounterOffer = () => {
    let btn;
    // if (!listing) return;
    // if (!listing.potentialBuyers) return;
    if (listing?.potentialBuyers && listing.potentialBuyers[params.buyerUid]) {
      if (listing.potentialBuyers[params.buyerUid]?.isFinalBuyer) return;

      if (listing.potentialBuyers[params.buyerUid].messages) {
        // get last message in the array
        // check if from seller
        // if it contains an offer, this is a counteroffer, render button
        const last =
          listing.potentialBuyers[params.buyerUid].messages.length - 1;
        const lastMessage =
          listing.potentialBuyers[params.buyerUid].messages[last];
        if (!lastMessage.fromBuyer) {
          if (lastMessage.offer) {
            btn = (
              <Button colorScheme="blue" onClick={onClickAcceptCounterOffer}>
                Accept {lastMessage.offer} Counter-Offer
              </Button>
            );
          }
        }
      }
    }
    if (!btn) return;

    return (
      <>
        <OrDivider />
        {btn}
      </>
    );
  };

  const renderBuyerNote = () => {
    if (!listing) return;
    if (!listing?.potentialBuyers) return;
    if (!listing?.potentialBuyers[params.buyerUid]) return;
    if (!listing?.potentialBuyers[params.buyerUid].messages) return;

    const messages = listing?.potentialBuyers?.[params.buyerUid].messages;
    // if there are any messages where fromBuyer is false, show message
    let sellerHasResponded = false;

    for (const message of messages) {
      if (!message.fromBuyer) sellerHasResponded = true;
    }

    if (sellerHasResponded) {
      return (
        <div>
          <Center>
            <Text color={'primary.800'}>CONVERSATION WITH SELLER</Text>
          </Center>
          <IDCard
            flat={true}
            listingSellerFull={listing}
            userData={sellerData}
          />
        </div>
      );
    }
  };

  const renderToTransaction = () => {
    if (!listing) return;
    if (!listing?.potentialBuyers) return;
    if (!listing?.potentialBuyers[params.buyerUid]) return;
    if (listing?.potentialBuyers[params.buyerUid].isFinalBuyer) {
      return (
        <Button
          colorScheme="blue"
          color="white"
          bgColor="black"
          size="lg"
          onClick={() => navigate('/transaction/' + listing.uid)}
        >
          Go To Transaction
        </Button>
      );
    }
  };

  const renderConversation = isBuyer => {
    if (!listing?.potentialBuyers) return null;
    if (!listing?.potentialBuyers[params.buyerUid]) return null;
    if (!listing?.potentialBuyers[params.buyerUid].messages) return null;

    const display = listing.potentialBuyers[params.buyerUid].messages.map(
      (item, i) => {
        return (
          <div key={i} style={{ marginTop: '12px' }}>
            <Stack direction="horizontal" align="center">
              {/* SELLER MESSAGE ON THE LEFT */}
              {!item.fromBuyer && (
                <Card
                  flex={1}
                  p={1}
                  variant="filled"
                  borderWidth="1px"
                  borderColor="lightgrey"
                  bgColor={isBuyer ? '#e2e8f0' : 'white'}
                  shadow="0"
                >
                  <CardBody p={1}>
                    <Text>{item.message}</Text>
                    {item.offer && (
                      <Text fontWeight="bold">Counter Offer: {item.offer}</Text>
                    )}
                  </CardBody>
                  <Stack direction="row" justify="flex-end">
                    <Text fontSize="xs" color="gray" as="i">
                      {moment(
                        item.date,
                        'ddd MMM DD YYYY kk:mm:ss Z ZZ'
                      ).format('MMM D, YYYY - h:mma')}
                    </Text>
                  </Stack>
                </Card>
              )}
              {/* AVATAR ON LEFT OR RIGHT */}
              <Avatar
                src={
                  item.fromBuyer
                    ? listing.potentialBuyers[params.buyerUid].buyerPhotoURL
                    : listing.sellerPhotoURL
                }
                size="md"
              />
              {/* BUYER MESSAGE ON THE RIGHT */}
              {item.fromBuyer && (
                <Card
                  flex={1}
                  p={1}
                  variant="filled"
                  bgColor={isBuyer ? 'white' : '#e2e8f0'}
                  borderWidth={1}
                  borderColor="lightgrey"
                  shadow={0}
                >
                  <CardBody p={1}>
                    <Text>{item.message}</Text>
                    {item.offer && (
                      <Text fontWeight="bold">Offer Price: {item.offer}</Text>
                    )}
                  </CardBody>
                  <Stack direction="row" justify="flex-end">
                    <Text fontSize="xs" color="gray" as="i">
                      {moment(
                        item.date,
                        'ddd MMM DD YYYY kk:mm:ss Z ZZ'
                      ).format('MMM D, YYYY - h:mma')}
                    </Text>
                  </Stack>
                </Card>
              )}
            </Stack>
          </div>
        );
      }
    );

    return (
      <Box borderColor="primary.500" borderWidth="1px" p="1" borderRadius="8px">
        {display}
      </Box>
    );
  };

  if (!listing) {
    return <SpinnerPage />;
  }

  let sendButtonEnabled = false;
  // either message or offer need to be present to send a message
  if (message.length > 1) sendButtonEnabled = true;
  if (offer.length > 2) sendButtonEnabled = true;

  if (isBuyer) {
    return (
      <>
        <AppBody>
          <Stack direction="column">
            <Button
              onClick={() => navigate(-1)}
              w={'full'}
              justifyContent="flex-start"
              pl={0}
              // variant="outline"
              variant="ghost"
              // colorScheme="blue"
              leftIcon={<BsChevronLeft />}
            >
              <Text
                fontSize="md"
                m={0}
                borderBottom="2px"
                borderBottomColor="primary.500"
              >
                BACK
              </Text>
            </Button>
            <div>
              <Center>
                <Text fontSize="xl">{listing.title}</Text>
              </Center>
              <Center>
                <Text fontSize="sm">
                  (Asking: {currencyFormat(listing.askingPrice)})
                </Text>
              </Center>
            </div>
            {renderBuyerNote()}

            {renderConversation(true)}

            <br />
            {renderToTransaction()}
            <br />

            {listing.status !== LISTING.STATUS.COMPLETED && (
              <>
                <Center>
                  <Text>Send Message to Seller</Text>
                </Center>

                {!userData?.stageTwoVerified && (
                  <>
                    <Alert
                      status="warning"
                      variant="left-accent"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      textAlign="center"
                    >
                      <AlertIcon mr={0} />
                      <AlertTitle mt={1} mb={1} fontSize="lg">
                        Please wait
                      </AlertTitle>
                      <AlertDescription maxWidth="lg">
                        The connect feature will be available once you are fully
                        verified
                      </AlertDescription>
                    </Alert>
                  </>
                )}

                <Flex>
                  <Textarea
                    name="message"
                    placeholder="Enter message..."
                    rows={2}
                    borderTopRightRadius={0}
                    borderBottomRightRadius={0}
                    isDisabled={
                      listing.status === LISTING.STATUS.COMPLETED ||
                      !userData?.stageTwoVerified
                    }
                    resize="none"
                    borderWidth={1}
                    borderColor="lightgrey"
                    shadow={0}
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                  />

                  <IconButton
                    h="auto"
                    w="25%"
                    size="lg"
                    borderTopLeftRadius={0}
                    borderBottomLeftRadius={0}
                    colorScheme="blue"
                    onClick={postBuyerMessage}
                    disabled={!sendButtonEnabled}
                    isLoading={posting}
                    aria-label="Send"
                    icon={<MdSend />}
                  />
                </Flex>
              </>
            )}
            {/* {listing?.potentialBuyers && ( */}
            <>
              {!listing?.potentialBuyers[params.buyerUid]?.isFinalBuyer && (
                <Flex width="80%">
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      color={offer ? 'gray.800' : 'gray'}
                      fontSize="1.1em"
                      children="$"
                    />
                    <Input
                      flex="1"
                      id="offer"
                      type="number"
                      placeholder="Counter-offer"
                      borderWidth={1}
                      borderColor="lightgrey"
                      value={offer}
                      onChange={e => setOffer(e.target.value)}
                    />
                  </InputGroup>
                </Flex>
              )}
            </>
            {/* )}  */}
            {renderAcceptOffer()}

            {renderAcceptCounterOffer()}

            <br />
            <Center textAlign="center">
              <Text fontSize="sm">
                If applicable, the EEZE Plan Fee will be added to the final
                price paid by the buyer
              </Text>
            </Center>
            <Center textAlign="center">
              <Text color="gray" as="i" fontSize="sm">
                Your contact information will be shared with the seller
              </Text>
            </Center>
            <br />
            <br />
          </Stack>
        </AppBody>
      </>
    );
  }

  if (isSeller) {
    return (
      <>
        <AppBody>
          <Stack direction="column">
            <Button
              onClick={() => navigate(-1)}
              w={'full'}
              justifyContent="flex-start"
              pl={0}
              variant="ghost"
              // colorScheme="blue"
              leftIcon={<BsChevronLeft />}
            >
              <Text
                fontSize="md"
                m={0}
                borderBottom="2px"
                borderBottomColor="primary.500"
              >
                BACK
              </Text>
            </Button>
            <div>
              <Center>
                <Text fontSize="xl">{listing.title}</Text>
              </Center>
              <Center>
                <Text fontSize="sm">
                  (Asking: {currencyFormat(listing.askingPrice)})
                </Text>
              </Center>
            </div>
            <div>
              <Center>
                <Text color={'primary.800'}>CONVERSATION WITH BUYER</Text>
              </Center>
              <IDCard
                flat={true}
                userData={buyerData}
                listingBuyerFull={{
                  photo: listing.potentialBuyers[params.buyerUid].buyerPhotoURL,
                  name: listing.potentialBuyers[params.buyerUid].buyerName,
                  email: listing.potentialBuyers[params.buyerUid].buyerEmail,
                  cell: listing.potentialBuyers[params.buyerUid].buyerCell,
                  location:
                    listing.potentialBuyers[params.buyerUid].buyerLocation,
                }}
              />
            </div>
            {renderConversation(false)}
            <br />

            {renderToTransaction()}
            <br />
            {listing.status !== LISTING.STATUS.COMPLETED && (
              <>
                <Center>
                  <Text>Send Message to Buyer</Text>
                </Center>
                <Flex>
                  <Textarea
                    name="message"
                    placeholder="Enter message..."
                    rows={2}
                    borderTopRightRadius={0}
                    borderBottomRightRadius={0}
                    resize="none"
                    borderWidth="1px"
                    borderColor="darkgrey"
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                  />

                  <IconButton
                    h="auto"
                    w="25%"
                    size="lg"
                    borderTopLeftRadius={0}
                    borderBottomLeftRadius={0}
                    colorScheme="blue"
                    // onClick={postBuyerMessage}
                    // disabled={message.length < 3 || offer.length < 2}
                    onClick={postSellerMessage}
                    disabled={!sendButtonEnabled}
                    isLoading={posting}
                    aria-label="Send"
                    icon={<MdSend />}
                  />
                </Flex>
              </>
            )}

            {!listing?.potentialBuyers[params.buyerUid]?.isFinalBuyer &&
              listing?.potentialBuyers[params.buyerUid]?.lastPrice && (
                <Flex width="80%">
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      color={offer ? 'gray.800' : 'gray'}
                      fontSize="1.1em"
                      children="$"
                    />
                    <Input
                      flex="1"
                      id="offer"
                      type="number"
                      disabled={listing.status !== LISTING.STATUS.POSTED}
                      placeholder="Counter-offer"
                      value={offer}
                      onChange={e => setOffer(e.target.value)}
                    />
                  </InputGroup>
                </Flex>
              )}

            {!listing?.potentialBuyers[params.buyerUid]?.isFinalBuyer && (
              <>
                <OrDivider />

                <Center>
                  <Text>
                    {!listing?.potentialBuyers[params.buyerUid]?.lastPrice
                      ? `TO SELECT ${
                          listing.potentialBuyers[params.buyerUid].buyerName
                        } as the final buyer`
                      : `TO CONFIRM and ACCEPT ${currencyFormat(
                          listing?.potentialBuyers[params.buyerUid]?.lastPrice
                        )} Offer`}
                  </Text>
                </Center>
                <Button
                  colorScheme="blue"
                  onClick={selectAsFinalBuyer}
                  // disabled={listing.status !== LISTING.STATUS.POSTED || !lastMessageFromBuyer}
                  disabled={listing.status !== LISTING.STATUS.POSTED}
                >
                  {/* {!listing?.potentialBuyers[params.buyerUid]?.lastPrice
                    ? `SELECT ${
                        listing.potentialBuyers[params.buyerUid].buyerName
                      } as the final buyer`
                    : `CONFIRM and ACCEPT ${currencyFormat(
                        listing?.potentialBuyers[params.buyerUid]?.lastPrice
                      )} Offer`} */}
                  Click here
                </Button>

                {listing.status !== LISTING.STATUS.POSTED && (
                  <Center>
                    <Text as="i" color={'primary.800'}>
                      Another buyer has been selected
                    </Text>
                  </Center>
                )}
              </>
            )}
          </Stack>
          <br />
          <br />
          <br />
          <br />
        </AppBody>
      </>
    );
  }

  return (
    <>
      <Header />
      <AppBody>
        <Stack direction="column">
          <br />
          <Heading>No Access</Heading>
          <Button onClick={() => navigate('/')} w={'full'} colorScheme="blue">
            To Homepage
          </Button>
        </Stack>
      </AppBody>
    </>
  );
}

export default Connect;
