import { useState, useContext } from 'react';
import {
  Center,
  HStack,
  FormControl,
  Button,
  Divider,
  Icon,
  InputGroup,
  Input,
  InputLeftElement,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Spinner,
  PinInput,
  PinInputField,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { updateDoc, doc } from 'firebase/firestore';
import { AiFillBank } from 'react-icons/ai';
import { FaWallet, FaArrowRight } from 'react-icons/fa';

import DataContext from '../context/DataContext';
import fundWalletTransaction from '../services/fundWalletTransaction';
import currencyFormat from '../services/currencyFormat';
import {
  db,
  isDev,
  TWILIO_START_VERIFY,
  TWILIO_VERIFY_OTP,
  PRICING_OPTIONS,
} from '../constants';

const FundWalletModal = ({ defaultAmount }) => {
  // console.log('defaultAmount', defaultAmount)
  const [cellIsVerified, setCellIsVerified] = useState(false);
  const [viewInput, setViewInput] = useState(false);
  const [sendSmsPINText, setSendSmsPINText] = useState('');
  const [fetchingSmsPIN, setFetchingSmsPIN] = useState(false);
  const [amount, setAmount] = useState(defaultAmount?.toFixed(2) || '');
  const [fundRequestSent, setFundRequestSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const toast = useToast();
  const { userData } = useContext(DataContext);
  const userRef = doc(db, 'users', userData.uid);
  const { cell, walletBalance, walletIncoming, walletTransactions, bank } =
    userData;

  const sendSMSVerification = async () => {
    try {
      setViewInput(true);
      setSendSmsPINText('Sending...');
      const response = await fetch(TWILIO_START_VERIFY, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          to: '+1' + cell,
          channel: 'sms', // or call
          locale: 'en',
        }),
      });

      const json = await response.json();

      if (response.status === 429) {
        setSendSmsPINText('');
        toast({
          description: `You have attempted to verify too many times. If you received a code, enter it below. Otherwise, please wait 10 minutes and try again.`,
          status: 'error',
          isClosable: true,
        });
      } else if (response.status >= 400) {
        setSendSmsPINText(json.error);
      } else {
        // modal.style.display = "block";
        if (json.success) {
          setSendSmsPINText('Verification code sent, enter below');
        } else {
          setSendSmsPINText(json.error);
        }
      }
    } catch (error) {
      // console.error(error);
      setSendSmsPINText('');
      toast({
        description: 'Something went wrong while sending code',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const onChangeSmsPIN = async e => {
    // console.log(e);
    if (e.length === 4) {
      setFetchingSmsPIN(true);
      setSendSmsPINText('Checking code...');

      // api call
      const data = {
        phoneNumber: '+1' + cell,
        otp: e,
      };

      try {
        // sends the value of both the phone number and the user-submitted OTP to the app’s backend endpoint, /verify-otp
        const response = await fetch(TWILIO_VERIFY_OTP, {
          method: 'POST',
          // mode: 'no-cors',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          body: JSON.stringify(data),
        });

        if (!response.ok) {
          setFetchingSmsPIN(false);
          setSendSmsPINText('');
          toast({
            description: 'Verification error: ' + response.statusText,
            status: 'error',
            isClosable: true,
          });
          return;
        }

        // receive a response from the POST request
        const check = await response.json();

        if (check.status === 'approved') {
          setFetchingSmsPIN(false);
          setCellIsVerified(true);
          setSendSmsPINText('');
          toast({
            description: 'SMS verification passed',
            status: 'success',
          });
        } else {
          // if fail, show reset button to remove text and fetching false
          setFetchingSmsPIN(false);
          setSendSmsPINText('Incorrect verification code');
        }
      } catch (e) {
        setFetchingSmsPIN(false);
        setSendSmsPINText('');
        toast({
          description: 'Verification error: ' + JSON.stringify(e),
          status: 'error',
          isClosable: true,
        });
        console.log(JSON.stringify(e));
      }
    }
  };

  const onClickConfirmFund = async () => {
    setLoading(true);
    if (
      !window.confirm(
        `This will immediately withdraw funds from your Source Bank Account. A ${currencyFormat(
          PRICING_OPTIONS.transaction.nsf
        )} NSF fee will apply if funds are not available. Are you sure?`
      )
    ) {
      setLoading(false);
      return;
    }

    // create key for this transaction
    const transactionKey = Date.now().toString();
    // send API call for amount
    const response = await fundWalletTransaction(amount, transactionKey);

    if (!response.success) {
      toast({
        title: 'Could not connect',
        description:
          'Please try again later. ' + response.data?.ErrorMessage ||
          response.error,
        status: 'error',
        isClosable: true,
      });
      setLoading(false);
      return;
    }

    // zum is Success
    if (response.data?.isError) {
      toast({
        title: 'Error with request',
        description:
          'Please try again later. ' + response.data?.ErrorMessage ||
          response.error,
        status: 'error',
        isClosable: true,
      });
      setLoading(false);
      return;
    }

    if (!response.data?.result) {
      toast({
        title: 'Error with response',
        description:
          'Please try again later. ' + response.data?.ErrorMessage ||
          response.error,
        status: 'error',
        isClosable: true,
      });
      setLoading(false);
      return;
    }

    // create or add to walletTransactions object
    const transactionsObject = walletTransactions || {};
    transactionsObject[transactionKey] = {
      amount: Number(amount),
      createdAt: new Date().toString(),
      id: response.data.result.Id,
    };

    // set to db
    await updateDoc(userRef, {
      walletTransactions: transactionsObject,
      walletIncoming: Number(walletIncoming || 0) + Number(amount),
    });

    setLoading(false);
    setFundRequestSent(true);
  };

  const renderCellVerify = () => {
    return (
      <>
        <Center align="center">
          <Text>
            For security reasons, click to receive a confirmation passcode to{' '}
            {cell}
          </Text>
        </Center>

        {sendSmsPINText ? (
          <Center my={5}>
            <Text color="gray">{sendSmsPINText}</Text>
          </Center>
        ) : (
          <Button
            my={5}
            colorScheme="primary"
            variant="outline"
            width="full"
            onClick={sendSMSVerification}
          >
            SEND
          </Button>
        )}
        {viewInput && (
          <FormControl>
            <Center>
              <HStack>
                {fetchingSmsPIN ? (
                  <>
                    <Spinner />
                    <Spinner />
                    <Spinner />
                    <Spinner />
                  </>
                ) : (
                  <PinInput onChange={e => onChangeSmsPIN(e)}>
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                )}
              </HStack>
            </Center>
          </FormControl>
        )}
      </>
    );
  };

  const renderFund = () => {
    return (
      <>
        <Stack direction="row" justify="space-between" mb={2} mr={4}>
          <Text
            // color={'primary.800'}
            fontWeight={'500'}
          >
            Current Wallet Balance
          </Text>

          <Text>
            {walletBalance ? currencyFormat(Number(walletBalance)) : '$0.00'}
          </Text>
        </Stack>

        {Number(walletIncoming || 0) > 0 && (
          <Stack direction="row" justify="space-between" mb={2} mr={4}>
            <Text
              // color={'primary.800'}
              as="i"
            >
              Incoming Amount
            </Text>

            <Text as="i">{currencyFormat(Number(walletIncoming))}</Text>
          </Stack>
        )}

        <Stack
          direction="row"
          justify="space-between"
          alignItems="center"
          // px={3}
          // mb={'10'}
          // fontWeight="bold"
        >
          <Stack w="50%">
            <Text
              // color={'primary.800'}
              as="b"
            >
              Amount to Fund Now
            </Text>
          </Stack>
          <Stack direction="row" justify="flex-end" align="center" w="50%">
            <InputGroup mx={0} align="center" fontWeight="bold">
              <InputLeftElement pointerEvents="none" children={'$'} />
              <Input
                borderWidth={1}
                borderColor="primary.500"
                id="amount"
                type="number"
                textAlign="right"
                value={amount}
                onChange={e => setAmount(e.target.value)}
                // onBlur={onBlurFinalPrice}
              />
            </InputGroup>
          </Stack>
        </Stack>

        <Stack direction="row" justify="space-between" my={2} mr={4}>
          <Text
            // color={'primary.800'}
            fontWeight={'500'}
            color={'gray.500'}
          >
            Expected Wallet Balance
          </Text>

          <Text color={'gray.500'}>
            {currencyFormat(
              Number(walletBalance || 0) +
                Number(amount || 0) +
                Number(walletIncoming || 0)
            )}
          </Text>
        </Stack>
        <Divider borderColor="primary.500" borderWidth={1} my={5} />

        <Center mt={8} align="center">
          {amount < PRICING_OPTIONS.transaction.fundMinimum ? (
            <Text as="i">
              The minimum fund transfer into your EEZE wallet is{' '}
              {currencyFormat(PRICING_OPTIONS.transaction.fundMinimum)}
            </Text>
          ) : (
            <>
              {amount > PRICING_OPTIONS.transaction.fundMaximum ? (
                <Text>
                  The maximum funding amount is{' '}
                  {currencyFormat(PRICING_OPTIONS.transaction.fundMaximum)} per
                  transaction
                </Text>
              ) : (
                <Text>
                  Transfer {currencyFormat(amount)} from your source bank
                  account to your EEZE wallet?
                </Text>
              )}
            </>
          )}
        </Center>
        {isDev && !bank?.userid && (
          <>
            <Text color="red">DEV warning! User has no zumrails userid</Text>
            <Button
              onClick={() => {
                updateDoc(userRef, {
                  bank: {
                    userid: '5ba265e7-9fbf-4bf7-8871-e9a9145fd361',
                  },
                });
              }}
            >
              click here to add
            </Button>
          </>
        )}
        <Button
          variant="solid"
          w="100%"
          mt={6}
          size="lg"
          mb={10}
          isLoading={loading}
          disabled={
            amount < PRICING_OPTIONS.transaction.fundMinimum ||
            amount > PRICING_OPTIONS.transaction.fundMaximum ||
            loading
          }
          onClick={onClickConfirmFund}
          colorScheme={'primary'}
        >
          Click to Confirm
        </Button>
        <Text my={2} textAlign="center">
          This transaction could take up to 3 business days to be completed
        </Text>
      </>
    );
  };
  return (
    <>
      <ModalHeader>Fund Wallet</ModalHeader>
      <ModalCloseButton />
      <ModalBody pb={6} minH="55vh">
        {!fundRequestSent ? (
          <>
            {!cellIsVerified ? <>{renderCellVerify()}</> : <>{renderFund()}</>}
          </>
        ) : (
          <div>
            <Center>
              <Icon as={AiFillBank} boxSize={'18%'} color="primary.500" />
              <Icon as={FaArrowRight} boxSize={'10%'} color="primary.500" />
              <Icon as={FaWallet} boxSize={'15%'} color="primary.500" />
            </Center>
            <Divider borderColor="primary.500" borderWidth={1} my={5} />
            <Center my={5} align="center">
              <Text>
                The Funding Request for {currencyFormat(amount)} has been sent
                to your bank. It could take up to 3 business days to reach your
                wallet
              </Text>
            </Center>
            <Center align="center">
              <Text>
                Please standby, we will email you and your EEZE wallet balance will be adjusted as soon as the transaction is complete
              </Text>
            </Center>
          </div>
        )}
      </ModalBody>
    </>
  );
};

export default FundWalletModal;
