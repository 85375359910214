import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import {
  Box,
  Button,
  FormControl,
  Flex,
  Heading,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import AppBody from '../components/AppBody';
import SwitchLanguage from '../components/SwitchLanguage';

function ForgotPassword() {
  const { t } = useTranslation('global');
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const onChange = e => setEmail(e.target.value);

  const onSubmit = async e => {
    // console.log('onsubmit');
    e.preventDefault();
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
      setEmailSent(true);
      toast({
        title: t('success'),
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      setEmailSent(false);
      toast({
        title: t('sendPasswordResetError'),
        description: t('pleaseTryAgainOrContactUs'),
        status: 'error',
        isClosable: true,
      });
    }
  };
  if (emailSent) {
    return (
      <AppBody>
        <Box>
          <Button pl={5} onClick={() => navigate('/login')} variant="ghost">
            <Text
              fontSize="xl"
              borderBottom="2px"
              borderBottomColor="primary.500"
            >
              EEZE
            </Text>
          </Button>
        </Box>
        <Box textAlign="center" py={10} px={6}>
          <CheckCircleIcon boxSize={'50px'} color={'primary.300'} />
          <Heading as="h2" size="xl" mt={6} mb={2}>
            {t('emailWasSent')}
          </Heading>
          <Text>{t('ifRegisteredYouWillReceive')}</Text>

          <Button
            onClick={() => navigate('/login')}
            colorScheme="primary"
            w="100%"
            maxWidth="400px"
            h="45"
            mt="20px"
          >
            {t('backToLogin')}
          </Button>
        </Box>
      </AppBody>
    );
  }
  return (
    <AppBody>
      <SwitchLanguage />
      <Box>
        <Button pl={5} onClick={() => navigate('/login')} variant="ghost">
          <Text
            fontSize="xl"
            borderBottom="2px"
            borderBottomColor="primary.500"
          >
            EEZE
          </Text>
        </Button>
      </Box>
      <Flex align={'center'} justify={'center'}>
        <Stack
          spacing={4}
          w={'full'}
          maxW={'md'}
          bg={'white'}
          rounded={'xl'}
          boxShadow={'lg'}
          p={6}
          my={12}
        >
          <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
            {t('forgotPassword')}
          </Heading>
          <Text fontSize={{ base: 'sm', sm: 'md' }}>
            {t('enterYourEmailAndWeWillSendYouALink')}
          </Text>
          <form onSubmit={onSubmit}>
            <FormControl id="email">
              <Input
                placeholder={t('emailAddress')}
                _placeholder={{ color: 'gray.400' }}
                type="email"
                id="email"
                value={email}
                onChange={onChange}
              />
              <Stack spacing={6}>
                <Button type="submit" colorScheme="primary" mt="20px" disabled={!email}>
                  {t('requestReset')}
                </Button>
              </Stack>
            </FormControl>
          </form>
        </Stack>
      </Flex>

      <Button
        onClick={() => navigate('/')}
        colorScheme="primary"
        w="100%"
        variant="link"
        size="sm"
        mt="20px"
      >
        {t('back')}
      </Button>
    </AppBody>
  );
}

export default ForgotPassword;
