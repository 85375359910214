import {
  Button,
  Text,
  Center,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  Heading,
  Stack,
  HStack,
  useDisclosure,
} from '@chakra-ui/react';
import { doc, updateDoc } from 'firebase/firestore';

import { db, LISTING, isDev, LINK } from '../constants';
import currencyFormat from '../services/currencyFormat';
import FundWalletModal from '../components/FundWalletModal';
import FundWalletCreditModal from '../components/FundWalletCreditModal';
import ContinueButton from '../components/ContinueButton';
import TransactionSellerPendingScreen from '../components/TransactionSellerPendingScreen';
import CreditLogos from '../components/CreditLogos';

function TransactionDisplayOptions({
  buyerUserData,
  sellerUserData,
  userData,
  listing,
  isBuyer,
  isSeller,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenCredit,
    onOpen: onOpenCredit,
    onClose: onCloseCredit,
  } = useDisclosure();

  const onClickNextStatus = async () => {
    const thisListingRef = doc(db, 'listings/', listing.uid);
    await updateDoc(thisListingRef, {
      status: LISTING.STATUS.BUYER_SIGNING,
    });
  };
  const onClickPreviousStatus = async () => {
    const thisListingRef = doc(db, 'listings/', listing.uid);
    await updateDoc(thisListingRef, {
      status: LISTING.STATUS.DISPLAY_OPTIONS,
    });
  };
  // const onClickCancel = async () => {
  //   if (!window.confirm('Cancel this transaction, are you sure?')) {
  //     return;
  //   }
  //   const thisListingRef = doc(db, 'listings/', listing.uid);
  //   await updateDoc(thisListingRef, {
  //     status: LISTING.STATUS.CANCELLED,
  //   });
  // };

  if (isSeller) {
    return (
      <TransactionSellerPendingScreen
        buyerUserData={buyerUserData}
        listing={listing}
      />
    );
  }
  // allInPrice must include eeze plan fee
  if (
    Number(listing.finalPrice) +
    Number(listing.eezePlanPrice) -
    Number(listing.allInPrice)
  ) {
    console.error('error in listing price');
  }

  const toFund = Math.max(
    0,
    Number(listing.allInPrice) -
      Number(buyerUserData.walletIncoming || 0) -
      Number(buyerUserData.walletBalance || 0)
  );

  const actualShortfall =
    Number(listing.allInPrice) - Number(buyerUserData.walletBalance || 0);

  // console.log('actualShortfall', actualShortfall);
  // if buyer
  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <FundWalletModal defaultAmount={toFund} />
          <ModalFooter>
            <Button
              w="full"
              variant="ghost"
              colorScheme="primary"
              onClick={onClose}
            >
              CLOSE
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenCredit} onClose={onCloseCredit}>
        <ModalOverlay />
        <ModalContent>
          <FundWalletCreditModal defaultAmount={toFund} />
          <ModalFooter>
            <Button
              w="full"
              variant="ghost"
              colorScheme="primary"
              onClick={onCloseCredit}
            >
              CLOSE
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Center>
        <Heading as="h1" fontSize="xl">
          {listing.title}
        </Heading>
      </Center>
      <Divider borderColor="primary.500" borderWidth={1} my={2} />
      <Stack
        direction="row"
        justify="space-between"
        alignItems="center"
        px={3}
        // mb={'10'}
        // fontWeight="bold"
      >
        <Text
          // color={'primary.800'}
          fontWeight={'500'}
        >
          FINAL PRICE ({listing.currency})
        </Text>
        <Text>{currencyFormat(Number(listing.finalPrice))}</Text>
      </Stack>

      <Stack direction="row" justify="space-between" mb={2} px={3}>
        <Text
          // color={'primary.800'}
          fontWeight={'500'}
        >
          EEZE {listing?.eezePlan?.name.toUpperCase()} PLAN FEE
        </Text>

        <Text>{currencyFormat(listing.eezePlanPrice)}</Text>
      </Stack>

      <Stack direction="row" justify="space-between" mb={2} px={3}>
        <Text
          // color={'primary.800'}
          // fontWeight={'900'}
          as="b"
        >
          BUYER (YOU) WILL PAY
        </Text>

        <Text as="b">{currencyFormat(Number(listing.allInPrice))}</Text>
      </Stack>

      <Divider borderColor="primary.500" borderWidth={1} my={2} />

      {actualShortfall > 0 ? (
        <Center align="center" my={6}>
          <Text>
            Before you can finalize the contract, you must have enough funds in
            your EEZE Wallet. Click below to enter one or many funding
            transactions
          </Text>
        </Center>
      ) : (
        <Center align="center" my={6}>
          <Text color="primary.500">
            You have sufficient funds in your EEZE Wallet to continue to the
            final step of contract signature
          </Text>
        </Center>
      )}

      <HStack justify="space-between" px={3}>
        <Text as="b" fontSize="xl">
          EEZE Wallet Balance
        </Text>
        <Text as="b" fontSize="xl">
          {currencyFormat(Number(buyerUserData.walletBalance || 0))}
        </Text>
      </HStack>

      {Number(buyerUserData.walletIncoming || 0) > 0 && (
        <HStack justify="space-between" my={2} px={3}>
          <Text as="i" color={'gray.500'}>
            Incoming Amount
          </Text>
          <Text as="i" color={'gray.500'}>
            {currencyFormat(Number(buyerUserData.walletIncoming || 0))}
          </Text>
        </HStack>
      )}

      {toFund > 0 && (
        <HStack justify="space-between" my={2} px={3}>
          <Text as="i" color={'gray.800'}>
            Balance shortfall
          </Text>
          <Text as="i" color={'gray.800'}>
            {currencyFormat(toFund)}
          </Text>
        </HStack>
      )}
      {actualShortfall > 0 ? (
        <>
          <Button
            variant="solid"
            w="100%"
            mt={6}
            mb={6}
            // size="lg"
            onClick={onOpen}
            colorScheme={'primary'}
            // color="white"
            // bgColor="black"
          >
            Fund Wallet from Bank Account
          </Button>
          {/* <OrDivider my={4}/> */}
          <Button
            variant="solid"
            w="full"
            mt={6}
            mb={2}
            // size="lg"
            onClick={onOpenCredit}
            colorScheme={'primary'}
          >
            From Credit Card or Installment Plan
          </Button>
          <CreditLogos />
         
        </>
      ) : (
        <ContinueButton mt={6} onClick={onClickNextStatus}>
          CONTINUE
        </ContinueButton>
      )}
<br/>
<br/>
<Center my={2} align="center">
          <Text as="i">
            A balance in your EEZE wallet allows for fast and secure payments to
            sellers.{' '}
            <a
              href={LINK.contactUrl}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'underline' }}
            >
              Contact us
            </a>{' '}
            to transfer back to your source bank anytime
          </Text>
        </Center>
      <Button
        w="full"
        variant="outline"
        mt={10}
        onClick={onClickPreviousStatus}
      >
        Back to Review Documents
      </Button>

      <br />
      <br />
      {isDev && (
        <>
          <br />
          <br />
          {/* <Button onClick={() => simulateEftCompleted(buyerUserData)}>
            simulate EFT completed
          </Button> */}
          {/* <Button colorScheme="blue" onClick={onClickNextStatus}>
            Next Status
          </Button> */}
          {/* <Button onClick={onClickPreviousStatus}>Previous Status</Button>
          <Button variant="ghost" onClick={onClickCancel}>
            Cancel
          </Button> */}
        </>
      )}
    </div>
  );
}

export default TransactionDisplayOptions;
