import {
    getAuth,
} from 'firebase/auth';
import {
    DELETE_SHORT_URL,
} from '../constants';

const deleteShortUrl = async (shortUrlID) => {
    const auth = getAuth();
    try {
        const token = await auth.currentUser.getIdToken()
        // also pass uid to add as ClientReferenceNumber for webhook
        const response = await fetch(
            DELETE_SHORT_URL+'?shortUrlID='+shortUrlID, {
            method: 'GET',
            withCredentials: true,
            credentials: 'include',

            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }
        );

        const data = await response.json();
        return data
    }

    catch (error) {
        console.log(error)
        return error;
    }
}

export default deleteShortUrl