import {
    getAuth,
} from 'firebase/auth';
import {
    GENERATE_LICENSE_VERIFY,
} from '../constants';

const generateLicenseSelfieCheckURL = async (cell) => {
    const auth = getAuth();
    try {
        const cellPhone = cell || '';
        const token = await auth.currentUser.getIdToken()
        // console.log('token', token)

        const response = await fetch(
            GENERATE_LICENSE_VERIFY+'?cell='+cellPhone, {
            method: 'GET',
            withCredentials: true,
            credentials: 'include',

            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }
        );

        const data = await response.json();
        // console.log('data', data);
        return data
    }

    catch (error) {
        console.log(error)
        return error;
    }
}

export default generateLicenseSelfieCheckURL