import { Button } from '@chakra-ui/react';

export default function ContinueButton(props) {
  return (
    <Button
      w="full"
      size={props?.size || 'lg'}
      color="white"
      mt={props.mt || 0}
      bgColor="black"
      onClick={props?.onClick}
      disabled={props?.disabled || false}
    >
      {props.children}
    </Button>
  );
}
