import { useState, useContext } from 'react';
import {
  Center,
  Checkbox,
  Code,
  Button,
  Divider,
  Icon,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  Text,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { AiOutlineCreditCard } from 'react-icons/ai';
import { FaWallet, FaArrowRight } from 'react-icons/fa';

import DataContext from '../context/DataContext';
import currencyFormat from '../services/currencyFormat';
import CreditLogos from '../components/CreditLogos';
import { isDev, LINK } from '../constants';

const FundWalletCreditModal = ({ defaultAmount }) => {
  const [consent, setConsent] = useState(false);
  const [checkoutUrl, setCheckoutUrl] = useState('');
  const [loading, setLoading] = useState(false);

  const { userData } = useContext(DataContext);
  const { walletBalance } = userData;

  const onClickOpenPayment = async () => {
    setLoading(true);

    // create key for this transaction
    const transactionKey = Date.now().toString();

    // generate stripe checkout url
    const url = `${LINK.walletFundCredit}?prefilled_email=${userData.email.replace(/\+/g,'%2B')}&client_reference_id=${userData.uid}EEZE${transactionKey}`;

    // open in new window
    setLoading(false);
    window.open(url, '_blank');
    setCheckoutUrl(url);
  };

  const renderFund = () => {
    return (
      <>
        <Stack direction="row" justify="space-between" mb={2} mr={4}>
          <Text
            fontWeight={'500'}
          >
            Current Wallet Balance
          </Text>

          <Text>
            {walletBalance ? currencyFormat(Number(walletBalance)) : '$0.00'}
          </Text>
        </Stack>
        {defaultAmount && (
          <Stack direction="row" justify="space-between" mb={2} mr={4}>
            <Text>Amount to Fund Now</Text>

            <Text>{currencyFormat(Number(defaultAmount.toFixed(2)))}</Text>
          </Stack>
        )}
        {defaultAmount && (
          <Stack direction="row" justify="space-between" mb={2} mr={4}>
            <Text as="b">Amount to Fund Including Charge</Text>

            <Text as="b" fontSize="lg">
              {currencyFormat(Number(defaultAmount * 1.035).toFixed(2))}
            </Text>
          </Stack>
        )}
        <Divider borderColor="primary.500" borderWidth={1} my={5} />

        <Center mt={8} align="center"></Center>
        <Text textAlign="center">
          A 3.5% merchant charge will be applied to credit card payments. For
          example, for $100 to your wallet, add 3.5% and enter a transaction of
          $103.50
        </Text>
        <Text textAlign="center" mt={3}>
          This transaction could take a few business days to reach your wallet
        </Text>
        <Stack justify="space-between" alignItems="center" mt={2}>
          <Checkbox
            value="consent"
            isChecked={consent}
            onChange={e => {
              e.preventDefault();
              setConsent(e.target.checked);
            }}
          >
            <Text
              justify="center"
              ml={1}
            >
              Click to Accept
            </Text>
          </Checkbox>
        </Stack>
        <Button
          variant="solid"
          w="100%"
          mt={6}
          isLoading={loading}
          disabled={!consent}
          onClick={onClickOpenPayment}
          colorScheme={'primary'}
          rightIcon={<ExternalLinkIcon />}
        >
          Open and Select Amount
        </Button>

<CreditLogos />
      </>
    );
  };
  return (
    <>
      <ModalHeader>Fund Wallet</ModalHeader>
      <ModalCloseButton />
      <ModalBody pb={6} minH="55vh">
        {!checkoutUrl ? (
          <>{renderFund()}</>
        ) : (
          <>
            <Center>
              <Icon
                as={AiOutlineCreditCard}
                boxSize={'18%'}
                color="primary.500"
              />
              <Icon as={FaArrowRight} boxSize={'10%'} color="primary.500" />
              <Icon as={FaWallet} boxSize={'15%'} color="primary.500" />
            </Center>
            <Divider borderColor="primary.500" borderWidth={1} my={5} />
            {defaultAmount ? (
              <>
                <Stack direction="row" justify="space-between" mb={2} mr={4}>
                  <Text>Amount to Fund Now</Text>

                  <Text>
                    {currencyFormat(Number(defaultAmount.toFixed(2)))}
                  </Text>
                </Stack>
                <Stack direction="row" justify="space-between" mb={2} mr={4}>
                  <Text as="b">Amount to Fund Including Charge</Text>

                  <Text as="b" fontSize="lg">
                    {currencyFormat(Number(defaultAmount * 1.035).toFixed(2))}
                  </Text>
                </Stack>
                <Divider borderColor="primary.500" borderWidth={1} my={5} />
              </>
            ) : (
              <></>
            )}
            <Center my={5} align="center">
              <Text>
                The Funding Request is being processed in a separate window
              </Text>
            </Center>
            <Center align="center">
              <Text>
                Please standby, we will email you and your EEZE wallet balance will be adjusted as soon as the transaction is complete
              </Text>
            </Center>
          </>
        )}
        {isDev && (
          <Stack color="red" mt={20}>
            <Text as="i">test credit card number</Text>
            <Code>5555 5555 5555 4444</Code>
            <Text as="i">any CVC, any future date</Text>
          </Stack>
        )}
      </ModalBody>
    </>
  );
};

export default FundWalletCreditModal;
