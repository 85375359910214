import { useState, useContext } from 'react';
import {
  Center,
  HStack,
  FormControl,
  Button,
  Divider,
  Icon,
  Heading,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Spinner,
  PinInput,
  PinInputField,
  Text,
  useToast,
} from '@chakra-ui/react';
import { updateDoc, doc } from 'firebase/firestore';
import { AiFillBank } from 'react-icons/ai';
import { FaWallet, FaArrowRight } from 'react-icons/fa';

import DataContext from '../context/DataContext';
// import fundWalletTransaction from '../services/fundWalletTransaction';
// import fundWalletTransaction from '../services/fundWalletTransaction';
import purchaseAddon from '../services/purchaseAddon';
import currencyFormat from '../services/currencyFormat';
import {
  db,
  isDev,
  TWILIO_START_VERIFY,
  TWILIO_VERIFY_OTP,
  PRICING_OPTIONS,
} from '../constants';

const BuyAddonModal = ({ addon, province, listingUid }) => {
  const [cellIsVerified, setCellIsVerified] = useState(false);
  const [viewInput, setViewInput] = useState(false);
  const [sendSmsPINText, setSendSmsPINText] = useState('');
  const [fetchingSmsPIN, setFetchingSmsPIN] = useState(false);
  const [fundRequestSent, setFundRequestSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const toast = useToast();
  const { userData } = useContext(DataContext);
  const userRef = doc(db, 'users', userData.uid);
  const listingRef = doc(db, 'listings', listingUid);
  const { cell, bank } = userData;

  const sendSMSVerification = async () => {
    try {
      setViewInput(true);
      setSendSmsPINText('Sending...');
      const response = await fetch(TWILIO_START_VERIFY, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          to: '+1' + cell,
          channel: 'sms', // or call
          locale: 'en',
        }),
      });

      const json = await response.json();

      if (response.status === 429) {
        setSendSmsPINText('');
        toast({
          description: `You have attempted to verify too many times. If you received a code, enter it below. Otherwise, please wait 10 minutes and try again.`,
          status: 'error',
          isClosable: true,
        });
      } else if (response.status >= 400) {
        setSendSmsPINText(json.error);
      } else {
        // modal.style.display = "block";
        if (json.success) {
          setSendSmsPINText('Verification code sent, enter below');
        } else {
          setSendSmsPINText(json.error);
        }
      }
    } catch (error) {
      // console.error(error);
      setSendSmsPINText('');
      toast({
        description: 'Something went wrong while sending code',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const onChangeSmsPIN = async e => {
    // console.log(e);
    if (e.length === 4) {
      setFetchingSmsPIN(true);
      setSendSmsPINText('Checking code...');

      // api call
      const data = {
        phoneNumber: '+1' + cell,
        otp: e,
      };

      try {
        // sends the value of both the phone number and the user-submitted OTP to the app’s backend endpoint, /verify-otp
        const response = await fetch(TWILIO_VERIFY_OTP, {
          method: 'POST',
          // mode: 'no-cors',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          body: JSON.stringify(data),
        });

        if (!response.ok) {
          setFetchingSmsPIN(false);
          setSendSmsPINText('');
          toast({
            description: 'Verification error: ' + response.statusText,
            status: 'error',
            isClosable: true,
          });
          return;
        }

        // receive a response from the POST request
        const check = await response.json();

        if (check.status === 'approved') {
          setFetchingSmsPIN(false);
          setCellIsVerified(true);
          setSendSmsPINText('');
          toast({
            description: 'SMS verification passed',
            status: 'success',
          });
        } else {
          // if fail, show reset button to remove text and fetching false
          setFetchingSmsPIN(false);
          setSendSmsPINText('Incorrect verification code');
        }
      } catch (e) {
        setFetchingSmsPIN(false);
        setSendSmsPINText('');
        toast({
          description: 'Verification error: ' + JSON.stringify(e),
          status: 'error',
          isClosable: true,
        });
        console.log(JSON.stringify(e));
      }
    }
  };

  const onClickConfirmFund = async () => {
    setLoading(true);
    if (
      !window.confirm(
        `This will immediately withdraw funds from your Source Bank Account. A ${currencyFormat(
          PRICING_OPTIONS.transaction.nsf
        )} NSF fee will apply if funds are not available. Are you sure?`
      )
    ) {
      setLoading(false);
      return;
    }

    // send API call for amount
    const response = await purchaseAddon(addon.code, province, listingUid);

    if (!response.success) {
      toast({
        title: 'Could not connect',
        description:
          'Please try again later. ' + response.data?.ErrorMessage ||
          response.error,
        status: 'error',
        isClosable: true,
      });
      setLoading(false);
      return;
    }

    // zum is error
    if (response.data?.isError) {
      toast({
        title: 'Error with request',
        description:
          'Please try again later. ' + response.data?.ErrorMessage ||
          response.error,
        status: 'error',
        isClosable: true,
      });
      setLoading(false);
      return;
    }

    if (!response.data?.result) {
      toast({
        title: 'Error with response',
        description:
          'Please try again later. ' + response.data?.ErrorMessage ||
          response.error,
        status: 'error',
        isClosable: true,
      });
      setLoading(false);
      return;
    }

    // success
    if (addon.type === 'CARFAX') {
      // set to db
      await updateDoc(listingRef, {
        vehicleCarfaxVHRPending: true,
      });
    } else if (addon.type === 'WARRANTY') {
      // set to db
      await updateDoc(listingRef, {
        // warrantyPending: true,
        warrantyName: addon?.warrantyName,
        warrantyAddonCode: addon?.code,
        warrantyActive: false,
      });
    }

    setLoading(false);
    setFundRequestSent(true);
  };

  const renderCellVerify = () => {
    return (
      <>
        <Center align="center">
          <Text>
            For security reasons, click to receive a confirmation passcode to{' '}
            {cell}
          </Text>
        </Center>

        {sendSmsPINText ? (
          <Center my={5}>
            <Text color="gray">{sendSmsPINText}</Text>
          </Center>
        ) : (
          <Button
            my={5}
            colorScheme="primary"
            variant="outline"
            width="full"
            onClick={sendSMSVerification}
          >
            SEND
          </Button>
        )}
        {viewInput && (
          <FormControl>
            <Center>
              <HStack>
                {fetchingSmsPIN ? (
                  <>
                    <Spinner />
                    <Spinner />
                    <Spinner />
                    <Spinner />
                  </>
                ) : (
                  <PinInput onChange={e => onChangeSmsPIN(e)}>
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                )}
              </HStack>
            </Center>
          </FormControl>
        )}
      </>
    );
  };

  const renderFund = () => {
    return (
      <>
        <Heading textAlign="center">{addon.name}</Heading>
        <Divider borderColor="primary.500" borderWidth={1} my={5} />
        <Text textAlign="center" fontSize='lg'>
          Click to confirm payment from your Source Bank Account{' '}
        </Text>
        <Text textAlign="center" fontSize='2xl' fontWeight='bold'>
          {currencyFormat(addon[province].priceIncludingTax)}
        </Text>

        {isDev && !bank?.userid && (
          <>
            <Text color="red">DEV warning! User has no zumrails userid</Text>
            <Button
              onClick={() => {
                updateDoc(userRef, {
                  bank: {
                    userid: '5ba265e7-9fbf-4bf7-8871-e9a9145fd361',
                  },
                });
              }}
            >
              click here to add
            </Button>
          </>
        )}
        <Button
          variant="solid"
          w="100%"
          mt={6}
          size="lg"
          mb={10}
          isLoading={loading}
          onClick={onClickConfirmFund}
          colorScheme={'primary'}
        >
          Click to Confirm
        </Button>
        <Text my={2} textAlign="center">
          This transaction could take up to 3 business days to be completed. It
          is not reflected in your wallet
        </Text>
        <Center align="center">
          <Text>
            Once cleared, EEZE will update the listing and email you the result
          </Text>
        </Center>
      </>
    );
  };
  return (
    <>
      <ModalHeader>Purchase Add-ons</ModalHeader>
      <ModalCloseButton />
      <ModalBody pb={6} minH="55vh">
        {!fundRequestSent ? (
          <>
            {!cellIsVerified ? <>{renderCellVerify()}</> : <>{renderFund()}</>}
          </>
        ) : (
          <div>
            <Center>
              <Icon as={AiFillBank} boxSize={'18%'} color="primary.500" />
              <Icon as={FaArrowRight} boxSize={'10%'} color="primary.500" />
              <Icon as={FaWallet} boxSize={'15%'} color="primary.500" />
            </Center>
            <Divider borderColor="primary.500" borderWidth={1} my={5} />
            <Center my={5} align="center">
              <Text>
                The Purchase Price of{' '}
                {currencyFormat(addon[province].priceIncludingTax)} is being
                processed by your bank. It could take up to 3 business days to
                be completed
              </Text>
            </Center>
            <Center align="center">
              <Text>
                Once cleared, EEZE will update the listing and email you the
                result
              </Text>
            </Center>
          </div>
        )}
      </ModalBody>
    </>
  );
};

export default BuyAddonModal;
