import { Box, Flex, Text, Button, Center, Image } from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SwitchLanguage from '../components/SwitchLanguage';
import WarrantySplash from '../components/WarrantySplash';
import fullLogo from '../assets/logo.png';
import signInImage from '../assets/signInImage.png';

export default function GetWarranty() {
  const { t } = useTranslation('global');
  const location = useLocation();
  // keep params if any, for redirect
  const existingParams = location?.search || '';

  return (
    <Flex position="relative" mb="40px">
      <Flex
        h={{ sm: 'initial', md: '85vh', lg: '95vh' }}
        w="100%"
        maxW="1044px"
        mx="auto"
        justifyContent="space-between"
        mb="20px"
        pt={{ sm: '50px', md: '0px' }}
      >
        <Flex
          alignItems="center"
          justifyContent="start"
          style={{ userSelect: 'none' }}
          w={{ base: '100%', md: '50%', lg: '42%' }}
        >
          <Flex
            direction="column"
            w="100%"
            background="transparent"
            p="28px"
            mt={{ md: '150px', lg: '80px' }}
          >
            <SwitchLanguage />
            <>
              <Center>
                <Image
                  src={fullLogo}
                  alt="Logo"
                  // boxSize="60px"
                  maxH="50px"
                  // objectFit="contain"
                  objectFit="cover"
                />
              </Center>

              <WarrantySplash />

              <Link as="span" to={'/warranty-signup' + existingParams}>
                <Button
                  mt={10}
                  w="full"
                  size="lg"
                  colorScheme="primary"
                  variant="solid"
                >
                  {t('getAWarranty')}
                </Button>
              </Link>
            </>

            {/* <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              maxW="100%"
              mt={5}
            >
              <Text fontWeight="medium">
                {t('alreadyHaveAnAccount')}
                <Link as="span" ms="5px" to={'/login' + existingParams}>
                  {t('login')}
                </Link>
              </Text>
            </Flex> */}
          </Flex>
        </Flex>

        <Box
          display={{ base: 'none', md: 'block' }}
          overflowX="hidden"
          h="100%"
          w="50vw"
          position="absolute"
          right="0px"
        >
          <Box
            bgImage={signInImage}
            w="100%"
            h="100%"
            // bgSize="cover"
            bgSize="contain"
            bgPosition="50%"
            position="absolute"
            borderBottomLeftRadius="20px"
          ></Box>
        </Box>
      </Flex>
      <Text
        position="absolute"
        bottom={0}
        right={0}
        color="grey"
        fontSize={'xs'}
      >
        {/* {deployed} */}
      </Text>
    </Flex>
  );
}
