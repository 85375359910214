import { useState, useContext } from 'react';
import {
  Box,
  Card,
  CardBody,
  Center,
  Divider,
  Input,
  InputLeftAddon,
  InputGroup,
  FormControl,
  Button,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Image,
  Text,
  useToast,
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';

import DataContext from '../context/DataContext';
import sendEmailLink from '../services/sendEmailLink';
import sendSMSLink from '../services/sendSMSLink';

const ShareModal = ({ url }) => {
  const [email, setEmail] = useState('');
  const [emailMessageSent, setEmailMessageSent] = useState(false);
  const [cell, setCell] = useState('');
  const [SMSMessageSent, setSMSMessageSent] = useState(false);
  const [sending, setSending] = useState(false);
  const toast = useToast();
  const { userData } = useContext(DataContext);

  const sender =
    userData?.firstName + ' ' + userData?.lastName.substring(0, 1) + '.';
  const message = `You've been invited by ${sender} to transact with EEZE on this listing https://${url}`;

  const onChangeCell = e => {
    // do not allow more than 10 cell phone digits or letters
    if (e.target.value.length > 10) return;
    if (isNaN(e.target.value)) return;

    setCell(e.target.value);
  };

  const onChangeEmail = e => {
    setEmail(e.target.value);
  };

  const smsIsEnabled = cell.length === 10;
  const emailIsEnabled = email.includes('@');

  const onClickSendEmail = async () => {
    setSending(true);

    const response = await sendEmailLink(sender, email, message, 'invitation');

    if (response.success) {
      setEmailMessageSent(true);
      setSending(false);
    } else {
      setEmailMessageSent(false);
      setSending(false);
      toast({
        title: 'Could not send email',
        description:
          'Please try again later. ' + JSON.stringify(response?.error),
        status: 'error',
        isClosable: true,
      });
    }
  };
  const onClickSendSMS = async () => {
    setSending(true);

    const response = await sendSMSLink(sender, cell, message);

    if (response.success) {
      setSMSMessageSent(true);
      setSending(false);
    } else {
      setSMSMessageSent(false);
      setSending(false);
      toast({
        title: 'Could not send SMS',
        description:
          'Please try again later. ' + JSON.stringify(response?.error),
        status: 'error',
        isClosable: true,
      });
    }
  };


  // Find the position of the last '/'
  const lastIndex = url.lastIndexOf("/");
  // get id
  const id = url.substring(lastIndex + 1);
  const qrUrl = `https://qr.rebrandly.com/v1/qrcode?shortUrl=https%3A%2F%2Feeze.co%2F${id}&source=com.rebrandly.app&size=512`;

  return (
    <>
      <ModalHeader>Share this EEZE listing</ModalHeader>
      <ModalCloseButton />
      <ModalBody pb={6}>
        <Center>
          <Image boxSize="60%" alt="qrCode" 
          // src={'https://' + url + '.qr'} 
          src={qrUrl}
          />
        </Center>
        <br />

        {userData ? (
          <>
            <Text>Send this message to a potential buyer</Text>
            <Divider borderColor="primary.500" borderWidth={1} />
            <Card>
              <CardBody>
                <Text color="primary.900">{message}</Text>
              </CardBody>
            </Card>
            <br />
            {!emailMessageSent ? (
              <FormControl>
                <Input
                  placeholder="user@email.com"
                  type="email"
                  value={email}
                  onChange={onChangeEmail}
                />
                <Button
                  w="full"
                  mt={1}
                  colorScheme="primary"
                  disabled={!emailIsEnabled}
                  isLoading={sending}
                  onClick={onClickSendEmail}
                >
                  Send by email
                </Button>
              </FormControl>
            ) : (
              <Box textAlign="center">
                <CheckCircleIcon boxSize={'35px'} color={'blue.500'} />
                <Text mt={3}>Email sent to {email}</Text>
              </Box>
            )}

            {!SMSMessageSent ? (
              <FormControl mt={4}>
                <InputGroup>
                  <InputLeftAddon children="+1" />
                  <Input
                    placeholder="555 555 1212"
                    type="tel"
                    onChange={onChangeCell}
                    value={cell}
                  />
                </InputGroup>
                <Button
                  w="full"
                  mt={1}
                  colorScheme="primary"
                  disabled={!smsIsEnabled}
                  isLoading={sending}
                  onClick={onClickSendSMS}
                >
                  Send by SMS
                </Button>
              </FormControl>
            ) : (
              <Box textAlign="center">
                <CheckCircleIcon boxSize={'35px'} color={'blue.500'} mt={5} />
                <Text mt={3}>SMS sent to {cell}</Text>
              </Box>
            )}
          </>
        ) : (
          <>
          <Text>You must login / signup to share this listing with other users</Text>
          </>
        )}
      </ModalBody>
    </>
  );
};

export default ShareModal;
