import AppBody from './AppBody';
import { Spinner, Center } from '@chakra-ui/react';

function SpinnerPage() {
  return (
    <AppBody>
      <Center m={10} p={10}>
        <Spinner
          thickness="4px"
          speed='0.65s'
          emptyColor="gray.200"
          color="primary.500"
          size="xl"
        />
      </Center>
    </AppBody>
  );
}

export default SpinnerPage;
