import React from 'react';
import { useState, useEffect } from 'react';
import {
  Link,
  useNavigate,
  useLocation,
  // useParams,
  useSearchParams,
} from 'react-router-dom';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { setDoc, doc, collection, getDocs, addDoc } from 'firebase/firestore';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import getIPDetails from '../services/getIPDetails';
import sendSMSLink from '../services/sendSMSSignup';
import checkCell from '../services/checkCell';
import checkMark from '../assets/animat-checkmark.gif';
import { db, recaptchaKey, isDev, LINK, LISTING } from '../constants';
// Chakra imports
import {
  Box,
  Flex,
  Fade,
  Button,
  Divider,
  IconButton,
  // Icon,
  Image,
  FormControl,
  Heading,
  HStack,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Center,
  Input,
  Text,
  Stack,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { BsChevronRight, BsChevronLeft } from 'react-icons/bs';
import { useToast } from '@chakra-ui/react';

// import OAuth from '../components/OAuth';
import logoImage from '../assets/shield256.png';
import fullLogo from '../assets/logo.png';
// import qrCode from '../assets/eeze-co-start.png';
import SwitchLanguage from '../components/SwitchLanguage';

// Assets
// import signUpImage from '../assets/signUpImage.png';

function SignUp(props) {
  const { i18n, t } = useTranslation('global');
  const navigate = useNavigate();
  // const params = useParams();
  const [queryParameters] = useSearchParams();

  const location = useLocation();
  const pathSearch = location?.pathname + location?.search;
  const currentUrl = `https://${isDev ? 'dev-' : ''}app.eeze.co` + pathSearch;
  const encodedCurrentUrl = encodeURIComponent(currentUrl);
  // console.log(location?.search);
  const qrUrl = `https://qr.rebrandly.com/v1/qrcode?shortUrl=${encodedCurrentUrl}&source=com.rebrandly.app&size=512`;

  const toast = useToast();
  const [continueAnyways, setContinueAnyways] = useState(
    queryParameters.get('desk') === 'y' ? true : false
  );
  // in dev, i may need to refresh the page for recaptcha to show, so need to bypass wait screen
  const [refPartner, setRefPartner] = useState();
  const [selectedPartner, setSelectedPartner] = useState();
  const [allPartners, setAllPartners] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const [sendingLink, setSendingLink] = useState(false);
  const [sendLinkSuccess, setSendLinkSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [captchaIsDone, setcaptchaIsDone] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    cell: queryParameters.get('c') || '',
    email: '',
    password: '',
  });
  const { email, password, firstName, lastName, cell } = formData;

  // keep params if any, for redirect
  // could be ref, aref, a, iref, i, uid or c
  let ref;
  if (queryParameters.get('ref')) {
    ref = queryParameters.get('ref');
    // ex /?ref=garage1
  }
  let aref;
  if (queryParameters.get('aref')) {
    aref = queryParameters.get('aref');
    // ex /?aref=affiliate1
  }
  if (queryParameters.get('a')) {
    aref = queryParameters.get('a');
    // ex /?a=affiliate1
  }
  let iref;
  if (queryParameters.get('iref')) {
    iref = queryParameters.get('iref');
    // ex /?iref=influencer1
  }
  if (queryParameters.get('i')) {
    iref = queryParameters.get('i');
    // ex /?i=influencer1
  }
  // let uid;
  // if (queryParameters.get('uid')) {
  //   uid = queryParameters.get('uid');
  // }
  // const uidRef = uid ? '&uid=' + uid : '';
  // const refRef = ref ? '&ref=' + ref : '';
  // const arefRef = aref ? '&aref=' + aref : '';
  // const irefRef = iref ? '&iref=' + iref : '';
  // const existingParams = '?' + uidRef + refRef + arefRef + irefRef;
  const existingParams = location?.search || '';

  useEffect(() => {
    const fetchAllPartners = async () => {
      let partnerSlug;

      try {
        if (ref) {
          partnerSlug = ref;
        }
        if (aref) {
          partnerSlug = aref;
        }
        if (iref) {
          partnerSlug = iref;
        }

        // get all partners
        const colRef3 = collection(db, 'partners');
        const docsSnap3 = await getDocs(colRef3);

        const allPartnersToSave = [];

        docsSnap3.forEach(partner => {
          if (!partner.data()) return;
          if (!partner.data().approved) return;
          // do not show name or logo if influencer
          if (partner.data().type === 'influencer') return;

          const partnerToSave = {
            businessName: partner.data().businessName,
            label:
              partner.data().businessName +
              ' (' +
              partner.data().address.city +
              ')',
            refSlug: partner.data().refSlug,
            value: partner.data().refSlug,
            type: partner.data().type,
            photoURL: partner.data()?.photoURL ? partner.data()?.photoURL : '',
          };
          allPartnersToSave.push(partnerToSave);

          // if refslug matches a partner, display
          if (partnerToSave.refSlug === partnerSlug) {
            setRefPartner(partnerToSave);
            setIsOpen(true); // this will fade in logo
          }
        });

        setAllPartners(allPartnersToSave);
      } catch (error) {
        console.log(error);
      }
    };

    if (props.safetyPromotionRedirect) {
      setContinueAnyways(true);
    }
    if (props.showPartners) {
      fetchAllPartners();
    }
  }, [props, aref, iref, ref]);

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const onChangeCaptcha = value => {
    // console.log('Captcha value:', value);
    setcaptchaIsDone(true);
  };

  const sendLinkToPhone = async () => {
    setSendingLink(true);

    const resp = await sendSMSLink(cell, encodeURIComponent(pathSearch));

    if (resp?.success) {
      setSendingLink(false);
      setSendLinkSuccess(true);
    } else {
      setSendingLink(false);
      toast({
        title: t('errorSendingSMSTo') + cell,
        description: resp?.error ? JSON.stringify(resp?.error) : '',
        status: 'error',
        isClosable: true,
      });
    }
  };
  const onChange = e => {
    // do not allow more than 10 cell phone digits or letters
    if (e.target.id === 'cell' && e.target.value.length > 10) return;
    // no 0 or 1 or letter as first number
    if (
      e.target.id === 'cell' &&
      e.target.value.length === 1 &&
      e.target.value === '1'
    )
      return;
    if (
      e.target.id === 'cell' &&
      e.target.value.length === 1 &&
      e.target.value === '0'
    )
      return;
    if (e.target.id === 'cell' && isNaN(e.target.value)) return;
    setFormData(prevState => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };
  const onChangeCell = e => {
    // do not allow more than 10 cell phone digits or letters
    if (e.target.value.length > 10) return;
    // no 0 or 1 or letter as first number
    if (e.target.value.length === 1 && e.target.value === '1') return;
    if (e.target.value.length === 1 && e.target.value === '0') return;
    if (isNaN(e.target.value)) return;
    setFormData(prevState => ({
      ...prevState,
      cell: e.target.value,
    }));
  };

  const onSubmit = async e => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // first check if cell is ok
      const check = await checkCell(cell);
      // console.log(check);

      if (check.reject) {
        setIsSubmitting(false);
        toast({
          title:
            t('thereIsAnErrorWithTheCellProvided') + check?.rejectionReason,
          status: 'warning',
          isClosable: true,
        });
        if (!isDev) {
          // end in prod if cell phone does not pass
          return;
        } else {
          window.alert(
            'Cell phone error but can continue in dev only. ' +
              check?.rejectionReason
          );
        }
      }

      const auth = getAuth();

      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      ).catch(e => {
        // console.log(e)
        if (e.code === 'auth/email-already-in-use') {
          signOut(auth);
          setIsSubmitting(false);
          toast({
            title: t('emailAlreadyInUse'),
            status: 'error',
            isClosable: true,
          });
          return;
        }
      });
      // console.log(userCredential);
      const user = userCredential?.user;

      if (!user) {
        signOut(auth).catch(error => {
          console.error(error);
          // An error happened.
        });
        setIsSubmitting(false);
        toast({
          title: t('signupErrorTryAgain'),
          status: 'error',
          isClosable: true,
        });
        return;
      }
      // updateProfile(auth.currentUser, {
      //   displayName: firstName + ' ' + lastName,
      // });

      const ipLocation = await getIPDetails();

      let favListings = [];
      if (queryParameters.get('uid')) {
        const uid = queryParameters.get('uid');
        favListings.push(uid);
      }

      const userDataToSave = { ...formData };
      delete userDataToSave.password;
      // redundant
      userDataToSave.firstName = firstName;
      userDataToSave.firstNameOnSignup = firstName;
      userDataToSave.lastName = lastName;
      userDataToSave.lastNameOnSignup = lastName;
      userDataToSave.cell = cell;
      userDataToSave.cellData = check;
      userDataToSave.email = email;
      userDataToSave.photoURL = '';
      userDataToSave.address = '';
      userDataToSave.language = i18n.language.toUpperCase();

      userDataToSave.createdAt = new Date().toString();
      userDataToSave.lastLogin = new Date().toString();
      userDataToSave.loginWith = 'Email';
      userDataToSave.signupIP = {
        ...ipLocation,
        date: new Date().toString(),
      };
      userDataToSave.stageOneVerified = false;
      userDataToSave.stageTwoVerified = false;
      userDataToSave.stageTwoWarnings = '';
      userDataToSave.favoriteListings = favListings;

      userDataToSave.emailIsVerified = false;
      userDataToSave.emailIsVerifiedAt = '';
      userDataToSave.phoneIsSmsVerified = false;
      userDataToSave.phoneIsSmsVerifiedAt = '';
      userDataToSave.phoneIsVoiceVerified = false;
      userDataToSave.phoneIsVoiceVerifiedAt = '';
      userDataToSave.licenseSelfieVerifiedURL = '';
      userDataToSave.licenseSelfieVerified = false;
      userDataToSave.licenseSelfieVerifiedAt = '';
      userDataToSave.licenseSelfieError = '';
      userDataToSave.addressConfirmed = false;
      userDataToSave.bankAccountIframe = '';
      userDataToSave.bankAccountAdded = '';
      userDataToSave.bankAccountAddedAt = '';
      userDataToSave.bankAccountError = '';
      userDataToSave.walletBalance = 0;
      userDataToSave.uid = user.uid;

      if (selectedPartner) {
        if (selectedPartner.type === 'garage') {
          userDataToSave.garageRef = selectedPartner.refSlug;
        } else {
          userDataToSave.affiliateRef = selectedPartner.refSlug;
        }
      }

      if (props.warrantyRedirect || props.safetyPromotionRedirect) {
        // on warranty or promotion rediect page
        // the signup flow does not redirect to hompage
        // like /signup or /login or /oauth
        // so handle ref save logic here
        // if there's a ref in the params, add it user
        // if also a uid in params, add refs to that listing (unlikely so skip)
        if (ref) {
          userDataToSave.garageRef = ref;
        }

        if (aref) {
          userDataToSave.affiliateRef = aref;
        }

        if (iref) {
          userDataToSave.influencerRef = iref;
        }

        await setDoc(doc(db, 'users', user.uid), userDataToSave);

        // now create private vehicle listing and redirect to edit
        const docRef = await addDoc(collection(db, 'listings'), {
          createdAt: new Date().toString(),
          affiliateRef: aref ? aref : '',
          garageRef: ref ? ref : selectedPartner ? selectedPartner.refSlug : '',
          influencerRef: iref ? iref : '',
          sellerUid: user.uid,
          language: i18n.language.toUpperCase(),
          sellerName: firstName + ' ' + lastName.substring(0, 1) + '.',
          sellerFullName: firstName + ' ' + lastName,
          sellerEmail: email,
          sellerCell: cell,
          // sellerLocation: '',
          // sellerPhotoURL: userData?.photoURL,
          status: LISTING.STATUS.PRIVATE,
          type: LISTING.TYPE.VEHICLE,
          province: '',
          //   ? ''
          //   : userData?.address?.province.toLowerCase(),
          warrantyName: '',
          // props.safetyPromotionRedirect
          //   ? 'Powertrain 90-Days'
          //   : null,
          warrantySafetyPromo: '',
          // props.safetyPromotionRedirect ? true : null,
          year: '',
          make: '',
          model: '',
          style: '',
          vin: '',
          odometer: '',
          odometerType: 'km',
          title: '',
          currency: 'CAD',
        });

        navigate('/edit/' + docRef.id);
      } else {
        // save and send to homepage normal
        await setDoc(doc(db, 'users', user.uid), userDataToSave);
        navigate('/' + existingParams);
      }
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
      toast({
        title: t('signupErrorTryAgain'),
        status: 'error',
        isClosable: true,
      });
    }
  };

  const renderSignup = () => {
    return (
      <Flex
        direction="column"
        w="100%"
        background="transparent"
        px="28px"
        mt={5}
      >
        {props.backToStart ? (
          <Stack direction="row" justify="space-between">
            {/* <Button
            onClick={() => navigate('/start' + existingParams)}
              variant="link"
              ml={-5}
            >

            <BsChevronLeft/>
            </Button> */}
            <IconButton
              aria-label="Back"
              icon={<BsChevronLeft />}
              ml={-5}
              variant="link"
              onClick={() => navigate('/start' + existingParams)}
            />
            <SwitchLanguage />
          </Stack>
        ) : (
          <SwitchLanguage />
        )}

        {refPartner?.businessName && (
          <Fade in={isOpen}>
            <Center>
              <Heading fontSize="sm" align="center">
                {refPartner.businessName}
              </Heading>
            </Center>
            <Center>
              {refPartner?.photoURL && (
                <>
                  <Box>
                    <Image
                      src={refPartner.photoURL}
                      h="60px"
                      maxW="200px"
                      objectFit="contain"
                      alt="Company Logo"
                    />
                  </Box>
                </>
              )}
            </Center>
            <Center mt={2}>
              <Text>{t('presents')}</Text>
            </Center>
            <Divider borderColor="primary.800" my={2} />
          </Fade>
        )}
        {props.warrantyRedirect ? (
          <>
            <Center>
              <Image
                src={fullLogo}
                alt="Logo"
                // boxSize="60px"
                maxH="60px"
                objectFit="cover"
                onClick={() => navigate('/warranty-signup?desk=y')}
              />
            </Center>
            <Center>
              <Heading
                color="primary.500"
                align="center"
                fontWeight="bold"
                size="md"
              >
                Signup to Add Your Vehicle
                <br />
                For an EEZE Warranty
                {/* {t('getAWarranty')} */}
              </Heading>
            </Center>
          </>
        ) : (
          <>
            {props.safetyPromotionRedirect ? (
              <>
                <Center>
                  <Image
                    src={fullLogo}
                    alt="Logo"
                    // boxSize="60px"
                    maxH="60px"
                    objectFit="cover"
                    onClick={() => navigate('/safety-promotion-signup?desk=y')}
                  />
                </Center>
                <Center>
                  <Heading
                    color="primary.500"
                    align="center"
                    fontWeight="bold"
                    size="md"
                  >
                    Signup to Add Your Vehicle
                    <br />
                    For an EEZE Warranty
                    {/* {t('getAWarranty')} */}
                  </Heading>
                </Center>

                <Center mt={2}>
                  <Heading
                    // color="primary.500"
                    align="center"
                    fontWeight="bold"
                    size="sm"
                  >
                    An EEZE-Authorized Partner must upload the safety inspection
                    for your FREE 90-day Warranty
                    {/* {t('getAWarranty')} */}
                  </Heading>
                </Center>
              </>
            ) : (
              <>
                <Center>
                  <Image
                    src={fullLogo}
                    alt="Logo"
                    // boxSize="60px"
                    maxH="60px"
                    objectFit="cover"
                    onClick={() => navigate('/signup?desk=y')}
                  />
                </Center>
                <Center>
                  <Text fontSize="lg" fontWeight="bold">
                    {t('secureAndEasy')}
                  </Text>
                </Center>
                <Center>
                  <Text fontSize="lg" fontWeight="bold">
                    {t('peerToPeerAutoSales')}
                  </Text>
                </Center>
                <Center>
                  <Text fontSize="lg" fontWeight="bold">
                    {t('warrantyAndFinancing')}
                  </Text>
                </Center>
              </>
            )}
          </>
        )}

        <Divider borderColor="primary.800" mt={2} mb={4} />

        <Center>
          <Text mb="16px" mt="10px">
            {t('enterInfoToBegin')}
          </Text>
        </Center>
        <form onSubmit={onSubmit}>
          <FormControl>
            <HStack>
              <InputGroup>
                <Input
                  borderRadius="10px"
                  fontSize="sm"
                  placeholder={t('firstName')}
                  size="lg"
                  id="firstName"
                  value={firstName}
                  onChange={onChange}
                />
                {!firstName && (
                  <Text color="red" ml={1}>
                    *
                  </Text>
                )}
              </InputGroup>
              <InputGroup>
                <Input
                  borderRadius="10px"
                  fontSize="sm"
                  placeholder={t('lastName')}
                  size="lg"
                  id="lastName"
                  value={lastName}
                  onChange={onChange}
                />
                {!lastName && (
                  <Text color="red" ml={1}>
                    *
                  </Text>
                )}
              </InputGroup>
            </HStack>

            <InputGroup mt="14px" mb="24px" borderRadius="10px">
              <InputLeftAddon children="+1" />
              <Input
                fontSize="sm"
                type="tel"
                placeholder={t('mobilePhone')}
                id="cell"
                value={cell}
                onChange={onChange}
              />
              {cell.length !== 10 && (
                <Text color="red" ml={1}>
                  *
                </Text>
              )}
            </InputGroup>
            {!refPartner && allPartners && (
              <Box mb={4}>
                <Select
                  styles={{
                    control: baseStyles => ({
                      ...baseStyles,
                      borderColor: `${
                        props.safetyPromotionRedirect ? 'purple' : 'inherit'
                      }`,
                      // borderColor: {props.safetyPromotionRedirect ? 'purple' : 'inherit'}, // 'inherit',
                      borderRadius: '10px',
                      height: '3rem',
                    }),
                  }}
                  value={selectedPartner}
                  placeholder={t('selectAnEEZEPartner')}
                  onChange={value => setSelectedPartner(value)}
                  options={allPartners}
                  isClearable={true}
                  isSearchable={true}
                />
              </Box>
            )}

            {/* <OAuth cell={cell} firstName={firstName} lastName={lastName}/> */}
            <Input
              borderRadius="10px"
              mb="14px"
              mt="14px"
              fontSize="sm"
              type="email"
              placeholder={t('emailAddress')}
              size="lg"
              id="email"
              value={email}
              onChange={onChange}
            />
            <InputGroup>
              <Input
                borderRadius="10px"
                mb="14px"
                fontSize="sm"
                type={showPassword ? 'text' : 'password'}
                placeholder={t('password')}
                size="lg"
                id="password"
                value={password}
                onChange={onChange}
              />
              <InputRightElement h={'full'}>
                <IconButton
                  size={'lg'}
                  mb="14px"
                  variant="ghost"
                  icon={
                    showPassword ? (
                      <ViewOffIcon color={'secondary.500'} boxSize={6} />
                    ) : (
                      <ViewIcon color={'secondary.500'} boxSize={6} />
                    )
                  }
                  aria-label={'Toggle'}
                  onClick={togglePasswordVisibility}
                />
              </InputRightElement>
            </InputGroup>

            <Center>
              <ReCAPTCHA sitekey={recaptchaKey} onChange={onChangeCaptcha} />
            </Center>
            {captchaIsDone &&
            firstName &&
            lastName &&
            email &&
            password &&
            cell.length === 10 ? (
              <Button
                type="submit"
                colorScheme="primary"
                w="100%"
                mb="5px"
                mt="14px"
                isLoading={isSubmitting ? true : false}
                disabled={isSubmitting ? true : false}
              >
                {t('createAccount')}
              </Button>
            ) : (
              <Button
                // type="submit"
                colorScheme="primary"
                w="100%"
                mb="5px"
                mt="14px"
                disabled
              >
                {t('createAccount')}
              </Button>
            )}
          </FormControl>
        </form>
        <Center>
          <Text fontSize="sm" align="center">
            {t('byCreatingAnAccount')}
              {' '}
            <a href={LINK.termsUrl} target="_blank" rel="noreferrer">
              {t('termsOfUse')},
            </a>
            {' '}
            <a href={LINK.privacyUrl} target="_blank" rel="noreferrer">
              {t('privacyPolicy')}
            </a>
            {' and '}
            <a href={LINK.amlUrl} target="_blank" rel="noreferrer">
              {t('amlPolicy')}
            </a>
          </Text>
        </Center>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          maxW="100%"
          mt={10}
        >
          <Text fontWeight="medium">
            {t('alreadyHaveAnAccount')}
            <Link as="span" ms="5px" to={'/login' + existingParams}>
              {t('login')}
            </Link>
          </Text>
        </Flex>
      </Flex>
    );
  };

  if (continueAnyways) {
    return (
      <Center>
        <Flex position="relative" mb="40px">
          <Flex maxW="700px" w="100%" style={{ userSelect: 'none' }}>
            {renderSignup()}
          </Flex>
        </Flex>
      </Center>
    );
  }
  return (
    <Flex position="relative" mb="40px">
      <Flex
        alignItems="center"
        w="100%"
        justifyContent="start"
        style={{ userSelect: 'none' }}
        display={{ base: '100%', md: 'none', lg: 'none' }}
      >
        {renderSignup()}
      </Flex>

      <Box display={{ base: 'none', md: 'block' }} mx="25%" alignItems="center">
        <Center mt={10}>
          <Image src={logoImage} alt="Logo" boxSize="60px" objectFit="cover" />
        </Center>
        <Center my={5}>
          <Text
            fontSize="xl"
            fontWeight="extrabold"
            textAlign="center"
            color="primary.500"
            lineHeight={1.2}
            bgGradient="linear(to-l, primary.800,primary.300)"
            bgClip="text"
          >
            {t('youMayBeViewingThisPageFromADesktop')}
          </Text>
        </Center>

        <Center
          mx={'20%'}
          p={2}
          // borderWidth="1px"
          borderRadius="20px"
          // borderColor="primary.800"
          mb={5}
        >
          <Stack direction="column" align="center">
            <Text fontSize="lg" color="primary.500">
              {t('scanHereToGoTo')}
            </Text>
            <Center>
              <Image boxSize="50%" maxW="300px" alt="qrCode" src={qrUrl} />
            </Center>
            <Text fontSize="sm" color="primary.500" align="center">
              {currentUrl}
            </Text>
          </Stack>
        </Center>
        {!sendLinkSuccess ? (
          <>
            <Center>
              <Text color="primary.500" textAlign="center">
                {t('orEnterYourCellPhoneAndWeWillTextYou')}
              </Text>
            </Center>

            <Center>
              <Box w="300px">
                <InputGroup my={2} borderRadius="10px">
                  <InputLeftAddon children="+1" />
                  <Input
                    fontSize="sm"
                    type="tel"
                    placeholder={t('mobilePhone')}
                    id="cell2"
                    value={cell}
                    onChange={onChangeCell}
                  />
                </InputGroup>
                {cell && cell.length === 10 ? (
                  <Button
                    mt={2}
                    colorScheme="primary"
                    w="full"
                    isLoading={sendingLink}
                    onClick={sendLinkToPhone}
                  >
                    {t('sendLinkToPhone')}
                  </Button>
                ) : (
                  <Button
                    mt={2}
                    colorScheme="primary"
                    w="full"
                    isLoading={sendingLink}
                    onClick={sendLinkToPhone}
                    disabled
                  >
                    {t('sendLinkToPhone')}
                  </Button>
                )}
              </Box>
            </Center>
          </>
        ) : (
          <>
            <Center mt={10}>
              <Image
                src={checkMark}
                alt="Check"
                p={0}
                boxSize="15%"
                maxWidth="100px"
                objectFit="cover"
              />
            </Center>
            <Center>
              <Text textAlign="center">
                {t('linkSentTo')}
                {cell}
              </Text>
            </Center>
          </>
        )}

        <Button
          mt="10%"
          w="full"
          variant="link"
          size="sm"
          onClick={() => setContinueAnyways(true)}
          rightIcon={<BsChevronRight />}
        >
          {t('continueOnThisComputerAnyways')}
        </Button>
      </Box>
    </Flex>
  );
}

export default SignUp;
