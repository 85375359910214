import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { logrocket_id } from './constants';
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'

import global_en from './locales/en/global.json'
import global_fr from './locales/fr/global.json'

LogRocket.init(logrocket_id);
setupLogRocketReact(LogRocket);

i18next.init({
  interpolation: { escapeValue: false },
   lng: 'auto',
   fallbackLng: 'en',
  resources: {
   en: {
    global: global_en,
   },
   fr: {
    global: global_fr,
   },
  },
 })

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <>
  <StrictMode>
  <I18nextProvider i18n={i18next}>
    <App />
    </I18nextProvider>
  </StrictMode>
  </>
);
