import { useState } from 'react';
import {
  Box,
  IconButton,
  useBreakpointValue,
  Stack,
  Heading,
  Text,
  Container,
} from '@chakra-ui/react';
// Here we have used react-icons package for the icons
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi';
// And react-slick as our Carousel Lib
import Slider from 'react-slick';
import signInImage from '../assets/signInImage.png';

// Settings for the slider
const settings = {
  dots: true,
  arrows: false,
  fade: true,
  infinite: true,
  //   autoplay: true,
  speed: 500,
  autoplaySpeed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export default function HelpCarousel() {
  // As we have used custom buttons, we need a reference variable to
  // change the state
  const [slider, setSlider] = useState(null);

  // These are the breakpoints which changes the position of the
  // buttons as the screen size changes
  const top = useBreakpointValue({ base: '90%', md: '50%' });
  const side = useBreakpointValue({ base: '30%', md: '40px' });

  // This list contains all the data for carousels
  // This can be static or loaded from a server
  const cards = [
    {
      heading: 'This is EEZE',
      title: 'Welcome',
      text: 'The safest and easiest way for buyers and seller to transact online',
      image: 'https://images.unsplash.com/photo-1428908728789-d2de25dbd4e2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2670&q=80',
    },
    {
      title: 'Step 1 of 5',
      text: 'First a seller completes a simple but thorough verification process. This allows them to create a vehicle or product listing they can share with the public',
      image: signInImage,
    },
    {
      title: 'Step 2 of 5',
      text: 'After a potential buyer completes the same verification process, they can connect with the seller',
      // image: signUpImage,
      image: 'https://images.unsplash.com/photo-1606922699403-9a40186fcb9b?q=80&w=2874&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    {
      title: 'Step 3 of 5',
      text: 'Buyer and seller agree on a final price on the transaction',
      image:
        'https://images.unsplash.com/photo-1438183972690-6d4658e3290e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2274&q=80',
    },
    {
      title: 'Step 4 of 5',
      text: 'The buyer must have enough verified funds in their EEZE wallet to complete the transaction',
      image:
      'https://images.unsplash.com/photo-1591017923379-2eab617d96d9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1771&q=80',
    },
    {
      title: 'Step 5 of 5',
      text: 'After BOTH parties e-sign the contract in the app, funds are immediately transferred to the seller. Congratulations, you have transacted with EEZE!!',
      image:
      'https://images.unsplash.com/photo-1533090161767-e6ffed986c88?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1769&q=80',
    },
  ];

  return (
    <Box
      position={'relative'}
      //   height={'600px'}
      height={'80vh'}
      width={'full'}
      overflow={'hidden'}
    >
      {/* CSS files for react-slick */}
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      {/* Left Icon */}
      <IconButton
        aria-label="left-arrow"
        variant="outline"
        // p={5}
        colorScheme="primary"
        position="absolute"
        left={side}
        // left={30}
        top={top}
        transform={'translate(0%, -50%)'}
        zIndex={2}
        onClick={() => slider?.slickPrev()}
      >
        <BiLeftArrowAlt size="40px" />
      </IconButton>
      {/* Right Icon */}
      <IconButton
        aria-label="right-arrow"
        variant="outline"
        colorScheme="primary"
        position="absolute"
        right={side}
        top={top}
        transform={'translate(0%, -50%)'}
        zIndex={2}
        onClick={() => slider?.slickNext()}
      >
        <BiRightArrowAlt size="40px" />
      </IconButton>
      {/* Slider */}
      <Slider {...settings} ref={slider => setSlider(slider)}>
        {cards.map((card, index) => (
          <Box
            key={index}
            // height={'6xl'}
            height={'60vh'}
            position="relative"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            backgroundImage={`url(${card.image})`}
          >
            {/* This is the block you need to change, to customize the caption */}
            <Container size="container.lg" height="200px" position="relative">
              <Stack
                spacing={6}
                w={'full'}
                maxW={'lg'}
                position="absolute"
                top="25%"
                // transform="translate(0, -50%)"
              >
                {card.heading && (
                  <Box>

                  <Heading
                    as="i"
                    letterSpacing={3}
                    fontWeight="bold"
                    fontSize={{ base: '4xl', md: '5xl', lg: '6xl' }}
                    size="lg"
                    bgGradient="linear(to-l, #7928CA, #FF0080)"
                    // bgGradient="linear(to-r, white, primary.300)"
                    bgClip="text"
                    >
                    {card.heading}
                  </Heading>
                    </Box>
                )}
                <Heading mb={30} fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}>
                  {card.title}
                </Heading>
                <Box bg='whiteAlpha.900' borderWidth={1} borderColor='primary.500' py={5} px={10} borderRadius={10}>

                <Text pr={7} fontSize={{ base: 'lg', lg: 'xl' }}>
                  {card.text}
                </Text>
                {card.div && card.div}
                </Box>
              </Stack>
            </Container>
          </Box>
        ))}
      </Slider>
    </Box>
  );
}
