import { useTranslation } from 'react-i18next';
import { Button, Stack } from '@chakra-ui/react';

import { enableMultiLanguage } from '../constants';

export default function SwitchLanguage() {
  const { i18n } = useTranslation('global');
  // console.log(i18n.language) is 'auto' by default, or 'en' or 'fr'

  if (!enableMultiLanguage) {
    return <></>;
  }
  return (
    <Stack direction="row" justify="flex-end">
      {i18n.language === 'fr' ? (
        <Button
          size="sm"
          mr={-5}
          variant="outline"
          onClick={() => i18n.changeLanguage('en')}
          color='gray'
        >
          EN
        </Button>
      ) : (
        <Button
          size="sm"
          mr={-5}
          variant="outline"
          color='gray'
          onClick={() => i18n.changeLanguage('fr')}
        >
          FR
        </Button>
      )}
    </Stack>
  );
}
