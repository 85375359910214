import { Center, Image } from '@chakra-ui/react';

import stripeImage from '../assets/stripe.png';
import { ReactComponent as Affirm } from '../assets/svg/affirm.svg';
import { ReactComponent as Afterpay } from '../assets/svg/afterpay.svg';
import { ReactComponent as Klarna } from '../assets/svg/klarna.svg';

function CreditLogos() {
  // to display under stripe Pay with Credit Card buttons
  return (
    <Center>
      <Image
        src={stripeImage}
        alt="Logo"
        // boxSize="40px"
        width="50%"
        maxWidth="400px"
        objectFit="cover"
      />
      <Affirm
        width="15%"
      />
      <Afterpay
        width="15%"
      />
      <Klarna
        width="15%"
      />
    </Center>
  );
}

export default CreditLogos;
