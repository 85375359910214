const getRandomUserPhoto = () => {
    // try {
        // const response = await fetch(
        //     'https://xsgames.co/randomusers/avatar.php?g=male', {
        //     method: 'GET',
        //     // withCredentials: true,
        //     // credentials: 'include',
        //     mode: 'no-cors',
        //     // headers: {
        //     //     'Authorization': 'Bearer ' + token,
        //     // }
        // }
        // );
        // console.log('response', response);
        // const { url } = response;
        // not working, for some reason returns ok: false


        const min = 20;
        const max = 70;
        const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
        const url = `https://xsgames.co/randomusers/assets/avatars/male/${randomNumber}.jpg`

        return url;
    // } catch (error) {
    //     // console.error('Error fetching avatar URL:', error);
    //     return 'https://api.dicebear.com/6.x/personas/svg?seed=Whiskers';
    // }
}

export default getRandomUserPhoto