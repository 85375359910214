// import { useEffect } from 'react';
import {
  Text,
  Divider,
  Center,
} from '@chakra-ui/react';

import { LINK } from '../constants';

function TransactionError({
  buyerUserData,
  sellerUserData,
  userData,
  listing,
}) {

  return (
    <div>
      <Center>
        <Text as="b" fontSize="xl">
          EEZE Transaction Error
        </Text>
      </Center>

      <Divider borderColor="primary.500" borderWidth={1} my={15} />
      <Center my={2} align="center">
        <Text as="i">
          There is a problem with this transaction. Please{' '}
          <a
            href={LINK.contactUrl}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'underline' }}
          >
            Contact us
          </a>
          {' to resolve'}
        </Text>
      </Center>

      {/* <Button colorScheme="blue" onClick={onClickNextStatus}>
        Back to Accepted Status
      </Button>
      <Button colorScheme="blue">Back to Homepage</Button> */}
    </div>
  );
}

export default TransactionError;
