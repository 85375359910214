import { Button } from '@chakra-ui/react';
import { doc, updateDoc } from 'firebase/firestore';

import { db, LISTING } from '../constants';

function TransactionCancelled({
  buyerUserData,
  sellerUserData,
  userData,
  listing,
}) {
  const onClickNextStatus = async () => {
    const thisListingRef = doc(db, 'listings/', listing.uid);
    await updateDoc(thisListingRef, {
      status: LISTING.STATUS.ACCEPTED,
    });
  };

  return (
    <>
      <h1>{listing.status}</h1>
      <p>notified buyer and seller of cancellation</p>
      <br />
      <br />
      <Button colorScheme="blue" onClick={onClickNextStatus}>
        Back to Accepted Status
      </Button>
      <Button colorScheme="blue">Back to Homepage</Button>
    </>
  );
}

export default TransactionCancelled;
