import { useState, useContext } from 'react';
import {
  Box,
  Card,
  CardBody,
  Divider,
  Input,
  InputLeftAddon,
  InputGroup,
  FormControl,
  Button,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  useToast,
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';

import DataContext from '../context/DataContext';
import sendEmailLink from '../services/sendEmailLink';
import sendSMSLink from '../services/sendSMSLink';

const InviteSellerModal = () => {
  const [email, setEmail] = useState('');
  const [emailMessageSent, setEmailMessageSent] = useState(false);
  const [cell, setCell] = useState('');
  const [marketplaceLink, setMarketplaceLink] = useState('');
  const [SMSMessageSent, setSMSMessageSent] = useState(false);
  const [sending, setSending] = useState(false);
  const toast = useToast();
  const { userData } = useContext(DataContext);

  const sender =
    userData?.firstName + ' ' + userData?.lastName.substring(0, 1) + '.';
  const linkText = !marketplaceLink ? `` : `\n\n\nThis is concerning listing at ${marketplaceLink}.`  
  const message = `${sender} has invited you to finalize a transaction on EEZE, a free platform for you to protect yourself from fraud when buying and selling online. 
  Please visit https://eeze.co
  ${linkText}`;

  const onChangeCell = e => {
    // do not allow more than 10 cell phone digits or letters
    if (e.target.value.length > 10) return;
    if (isNaN(e.target.value)) return;

    setCell(e.target.value);
  };

  const onChangeEmail = e => {
    setEmail(e.target.value);
  };

  const onChangeLink = e => {
    setMarketplaceLink(e.target.value);
  };

  const smsIsEnabled = cell.length === 10;
  const emailIsEnabled = email.includes('@');

  const onClickSendEmail = async () => {
    setSending(true);

    const response = await sendEmailLink(sender, email, encodeURIComponent(message), 'invitation');

    if (response.success) {
      setEmailMessageSent(true);
      setSending(false);
    } else {
      setEmailMessageSent(false);
      setSending(false);
      toast({
        title: 'Could not send email',
        description:
          'Please try again later. ' + JSON.stringify(response?.error),
        status: 'error',
        isClosable: true,
      });
    }
  };
  const onClickSendSMS = async () => {
    setSending(true);

    const response = await sendSMSLink(sender, cell, message);

    if (response.success) {
      setSMSMessageSent(true);
      setSending(false);
    } else {
      setSMSMessageSent(false);
      setSending(false);
      toast({
        title: 'Could not send SMS',
        description:
          'Please try again later. ' + JSON.stringify(response?.error),
        status: 'error',
        isClosable: true,
      });
    }
  };

  return (
    <>
      <ModalHeader textAlign='center'>Invitation</ModalHeader>
      <ModalCloseButton />
      <ModalBody p={0}>
        {/* <Center>
          img
        </Center>
        <br /> */}

        {userData && (
          <>
            <Text textAlign='center'>Message to the seller</Text>
            <Divider borderColor="primary.500" borderWidth={1} />
            <Card>
              <CardBody>
                <Text color="primary.900">{message}</Text>
              </CardBody>
            </Card>
            <br />
            <Text>
                What online marketplace did you see the vehicle listed on? 
                </Text>
                <small>

                Copy/paste the entire URL (ex: https://www.facebook.com/marketplace/item/123)
                </small>
                <Input
                  placeholder="https://"
                  type="text"
                  value={marketplaceLink}
                  onChange={onChangeLink}
                  mb={3}
                />
            {!emailMessageSent ? (
              <FormControl>
               
                <Text>Enter seller information</Text>
                <Input
                  placeholder="seller@email.com"
                  type="email"
                  value={email}
                  onChange={onChangeEmail}
                />
                <Button
                  w="full"
                  mt={1}
                  colorScheme="primary"
                  disabled={!emailIsEnabled}
                  isLoading={sending}
                  onClick={onClickSendEmail}
                >
                  Send by email
                </Button>
              </FormControl>
            ) : (
              <Box textAlign="center">
                <CheckCircleIcon boxSize={'35px'} color={'blue.500'} />
                <Text mt={3}>Email sent to {email}</Text>
              </Box>
            )}

            {!SMSMessageSent ? (
              <FormControl mt={4}>
                <InputGroup>
                  <InputLeftAddon children="+1" />
                  <Input
                    placeholder="555 555 1212"
                    type="tel"
                    onChange={onChangeCell}
                    value={cell}
                  />
                </InputGroup>
                <Button
                  w="full"
                  mt={1}
                  colorScheme="primary"
                  disabled={!smsIsEnabled}
                  isLoading={sending}
                  onClick={onClickSendSMS}
                >
                  Send by SMS
                </Button>
              </FormControl>
            ) : (
              <Box textAlign="center">
                <CheckCircleIcon boxSize={'35px'} color={'blue.500'} mt={5} />
                <Text mt={3}>SMS sent to {cell}</Text>
              </Box>
            )}
          </>
        )}
      </ModalBody>
    </>
  );
};

export default InviteSellerModal;
