import { useState, useEffect, useContext } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Text,
  Avatar,
  Badge,
  CardBody,
  Card,
  Center,
  Stack,
} from '@chakra-ui/react';
import { BsChevronLeft } from 'react-icons/bs';
import { ChevronRightIcon } from '@chakra-ui/icons';

import { db } from '../constants';
import DataContext from '../context/DataContext';
import SpinnerPage from '../components/SpinnerPage';
import AppBody from '../components/AppBody';
import currencyFormat from '../services/currencyFormat';

function ViewBuyers() {
  const [listing, setListing] = useState(null);
  const [buyers, setBuyers] = useState(null);

  const { userData } = useContext(DataContext);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    const fetchListing = async () => {
      const docRef = doc(db, 'listings', params.listingUid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const thisListing = docSnap.data();
        if (thisListing.sellerUid === userData.uid) {
          setListing(thisListing);

          const buyerArray = [];

          // map object to an array
          for (const uid in thisListing.potentialBuyers) {
            buyerArray.push(thisListing.potentialBuyers[uid]);
          }

          // Sort the array by buyerName alphabetically
          buyerArray.sort((a, b) => {
            // Convert to uppercase to make the comparison case-insensitive
            const nameA = a.buyerName.toUpperCase();
            const nameB = b.buyerName.toUpperCase();

            if (nameA < nameB) {
              return -1; // a comes before b
            }
            if (nameA > nameB) {
              return 1; // a comes after b
            }
            return 0; // a and b are equal
          });

          setBuyers(buyerArray);
        }
      } else {
        navigate('/');
      }
    };

    fetchListing();
  }, [navigate, params, userData]);

  if (!listing) {
    return <SpinnerPage />;
  }
  const goToBuyerConnect = item => {
    navigate('/connect/' + listing.uid + '/' + item.buyerUid);
  };
  const renderBuyers = () => {
    if (!buyers || buyers.length === 0) {
      return (
        <Center>
          <Text mt={20}>No buyers for this listing yet</Text>
        </Center>
      );
    }

    const display = buyers.map((item, i) => {
      return (
        <Card
          key={i}
          borderColor={item.isFinalBuyer ? 'primary.500' : ''}
          borderWidth={1}
          p={2}
          variant="filled"
          shadow={'md'}
        >
          <Stack
            direction="horizontal"
            align="center"
            onClick={() => goToBuyerConnect(item)}
          >
            <div>
              <Avatar
                src={item.buyerPhotoURL}
                borderWidth="2px"
                borderColor={'blue.500'}
              />
            </div>
            <CardBody p={2}>
              <Text fontSize="xl" fontWeight="bold">
                {item.buyerName}
                {item.isFinalBuyer && (
                  <Badge variant="solid" colorScheme="primary" ml={5}>
                    Final Buyer
                  </Badge>
                )}
              </Text>
              {item.buyerLocation && <Text>{item.buyerLocation}</Text>}
              <Text>{item.buyerCell}</Text>
              <Text>{item.buyerEmail}</Text>
            </CardBody>
            <ChevronRightIcon boxSize={10} />
          </Stack>
        </Card>
      );
    });

    return display;
  };
  return (
    <>
      <AppBody>
        <Stack direction="column">
          <Button
            onClick={() => navigate(-1)}
            w={'full'}
            justifyContent="flex-start"
            pl={0}
            variant="ghost"
            // colorScheme="blue"
            leftIcon={<BsChevronLeft />}
          >
            <Text
              fontSize="md"
              m={0}
              borderBottom="2px"
              borderBottomColor="primary.500"
            >
              BACK
            </Text>
          </Button>
          <div>
            <Center>
              <Text fontSize="xl">{listing.title}</Text>
            </Center>
            <Center>
              <Text fontSize="sm">
                (Asking: {currencyFormat(listing.askingPrice)})
              </Text>
            </Center>
          </div>
          <br />
          {renderBuyers()}
        </Stack>
      </AppBody>
    </>
  );
}

export default ViewBuyers;
