import { useEffect, useState, useRef } from 'react';
import {
  collection,
  getDocs,
  // query,
  // where,
  // orderBy,
  // limit,
  // startAfter,
} from 'firebase/firestore';
import {
  SimpleGrid,
  Input,
  Button,
  Center,
  TabList,
  TabPanels,
  TabPanel,
  Heading,
  Text,
  Tabs,
  InputRightElement,
  InputLeftElement,
  InputGroup,
  Tab,
  useToast,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';

import { db, LISTING } from '../constants';
import AppBody from '../components/AppBody';
import Header from '../components/Header';
import ListingCard from '../components/ListingCard';

import SpinnerPage from '../components/SpinnerPage';

function Listings() {
  const toast = useToast();
  const inputRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [vehicleListings, setVehicleListings] = useState(null);
  const [filteredVehicleListings, setFilteredVehicleListings] = useState(null);
  const [productListings, setProductListings] = useState(null);
  const [filteredProductListings, setFilteredProductListings] = useState(null);

  const sortArray = array => {
    // descending order of postedAt
    const sortedArray = array.sort((a, b) => {
      return new Date(b.postedAt) - new Date(a.postedAt);
    });
    return sortedArray;
  }
  
  useEffect(() => {
    const fetchListings = async () => {
      try {
        const vehicleListings = [];
        const productListings = [];

        const colRef = collection(db, 'listings');
        const docsSnap = await getDocs(colRef);
        if (docsSnap.docs.length > 0) {
          docsSnap.forEach(doc => {
            const listing = doc.data();
            if (!listing.status) return;

            if (listing.status === LISTING.STATUS.POSTED) {
              if (listing.type === LISTING.TYPE.VEHICLE) {
                vehicleListings.push(listing);
              }
              if (listing.type === LISTING.TYPE.PRODUCT) {
                productListings.push(listing);
              }
            }
          });
        }

        setVehicleListings(sortArray(vehicleListings));
        setFilteredVehicleListings(sortArray(vehicleListings));
        setProductListings(sortArray(productListings));
        setFilteredProductListings(sortArray(productListings));
        setLoading(false);
        return;

        // // Get reference
        // const listingsRef = collection(db, 'listings');

        // // Create a query
        // const q = query(
        //   listingsRef,
        //   // where('type', '==', 'vehicle'),
        //   // where('status', '!=', 'DRAFT'),
        //   // where('status', '!=', 'CANCELLED'),
        //   orderBy('createdAt', 'desc'),
        //   limit(50)
        // );

        // // Execute query
        // const querySnap = await getDocs(q);

        // const lastVisible = querySnap.docs[querySnap.docs.length - 1];
        // setLastFetchedListing(lastVisible);

        // const listings = [];

        // querySnap.forEach(doc => {
        //   return listings.push(doc.data());
        // });
        // // querySnap.forEach(doc => {
        // //   return listings.push({
        // //     id: doc.id,
        // //     data: doc.data(),
        // //   });
        // // });

        // setListings(listings);
        // setLoading(false);
      } catch (error) {
        console.log(error);
        toast({
          description: 'Listing fetch error',
          status: 'error',
        });
      }
    };

    fetchListings();
  }, [toast]);

  // Pagination / Load More
  // const onFetchMoreListings = async () => {
  //   try {
  //     // Get reference
  //     const listingsRef = collection(db, 'listings');

  //     // Create a query
  //     const q = query(
  //       listingsRef,
  //       // where('offer', '==', true),
  //       orderBy('createdAt', 'desc'),
  //       startAfter(lastFetchedListing),
  //       limit(10)
  //     );

  //     // Execute query
  //     const querySnap = await getDocs(q);

  //     const lastVisible = querySnap.docs[querySnap.docs.length - 1];
  //     setLastFetchedListing(lastVisible);

  //     const listings = [];

  //     querySnap.forEach(doc => {
  //       return listings.push(doc.data());
  //     });
  //     // querySnap.forEach(doc => {
  //     //   return listings.push({
  //     //     id: doc.id,
  //     //     data: doc.data(),
  //     //   });
  //     // });

  //     setListings(prevState => [...prevState, ...listings]);
  //     setLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //     toast({
  //       description: 'Listing fetch error',
  //       status: 'error',
  //     });
  //   }
  // };

  // console.log('vehicleListings', vehicleListings);
  // console.log('productListings', productListings);

  //   fetch ALL
  //   async getMarker() {
  //     const snapshot = await firebase.firestore().collection('events').get()
  //     return snapshot.docs.map(doc => doc.data());
  // }
  // console.log('listings', listings);
  
  const filterArray = (array, filterValue) => {
    const filteredArray = array.filter(
      item =>
        item.title.toLowerCase().includes(filterValue.toLowerCase()) ||
        item.eezeID.toLowerCase().includes(filterValue.toLowerCase()) ||
        item.sellerLocation.toLowerCase().includes(filterValue.toLowerCase()) ||
        item.sellerName.toLowerCase().includes(filterValue.toLowerCase())
    );
    return sortArray(filteredArray);
  };

  const handleFilter = e => {
    let inputValue = '';
    if (e?.target) {
      inputValue = e.target.value;
    }
    const filteredVehicles = filterArray(vehicleListings, inputValue);
    setFilteredVehicleListings(filteredVehicles);

    const filteredProducts = filterArray(productListings, inputValue);
    setFilteredProductListings(filteredProducts);
  };

  const handleClear = () => {
    inputRef.current.value = '';
    handleFilter()

  };

  const renderVehicles = () => {
    if (loading) {
      return <SpinnerPage />;
    }
    if (!filteredVehicleListings) {
      return <SpinnerPage />;
    }

    return (
      <>
        {filteredVehicleListings.length > 0 ? (
          <>
            <SimpleGrid
              columns={[1, 1, 2]}
            >
              {filteredVehicleListings.map(listing => (
                <ListingCard
                  listing={listing}
                  id={listing.uid}
                  key={listing.uid}
                />
              ))}
            </SimpleGrid>
            <br />
            <br />
          </>
        ) : (
          <Center mt={20}>

          <Text>No Listings</Text>
          </Center>
        )}
      </>
    );
  };

  const renderProducts = () => {
    if (loading) {
      return <SpinnerPage />;
    }
    if (!filteredProductListings) {
      return <SpinnerPage />;
    }

    return (
      <>
        {filteredProductListings.length > 0 ? (
          <>
            <SimpleGrid
              columns={[1, 1, 2]}
            >
              {filteredProductListings.map(listing => (
                <ListingCard
                  listing={listing}
                  id={listing.uid}
                  key={listing.uid}
                />
              ))}
            </SimpleGrid>
            <br />
            <br />
          </>
        ) : (
          <Center mt={20}>

          <Text>No Listings</Text>
          </Center>
        )}
      </>
    );
  };

  return (
    <>
      <Header />
      <AppBody>
        <Heading size="lg">EEZE Listings</Heading>
        <InputGroup>
          <InputLeftElement children={<SearchIcon color="grey" />} />
          <Input
            onChange={handleFilter}
            ref={inputRef}
            placeholder="Search by Title, Seller, Location or ID"
          />
          <InputRightElement>
            <Button
              variant="ghost"
              onClick={handleClear}
            >
              X
            </Button>
          </InputRightElement>
        </InputGroup>
        <Tabs isFitted>
          <TabList mb="1em">
            <Tab px={35}>Vehicles</Tab>
            <Tab px={35}>Products</Tab>
          </TabList>
          <TabPanels>
            <TabPanel p={0} m={0}>
              {renderVehicles()}
            </TabPanel>
            <TabPanel>{renderProducts()}</TabPanel>
          </TabPanels>
        </Tabs>
      </AppBody>
    </>
  );
}

export default Listings;
