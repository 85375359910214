import {
    CHECK_CELL,
} from '../constants';

const checkCell = async (cell) => {

    try {
        // console.log('CHECK_CELL')
        // console.log(CHECK_CELL + '?cell=' + cell)

        const data = await fetch(
            CHECK_CELL + '?cell=' + cell,
            {
                method: 'GET',
                // mode: 'no-cors',
                // withCredentials: true,
                // credentials: 'include',
                headers: {},
            }
        )
            .then((res) => {
                // console.log('res', res)
                return res.json();
            });
        return data;
    }
    catch (error) {
        return { error };
    }

};

export default checkCell;